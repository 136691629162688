import React, { useState, useContext, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { AppContext } from '../../../../hooks/context'
import {
  Input,
  InputLabel,
  TextField,
  FormControlLabel,
  Switch,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  LinearProgress,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material'
import { format } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Cancel, DeleteForever as DeleteIcon, Undo } from '@mui/icons-material'
import { dataInvoice, printInvoice } from '../../../../library/pdf/invoice'
import colors from '../../../../library/styled-components/colors'
import DoubleBounce from '../../../loaders/double-bounce'
import { Print, Email, Edit as EditIcon } from '@mui/icons-material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { createInvoice, deleteInvoice, updateInvoice } from 'actions/payments'
import { ADMIN_NS } from 'config'
import ListCharges from './ListCharges'
import { reSendInvoice } from 'containers/admin/home/calendar/actions'
import TreatmentListFlat from 'containers/admin/home/patient/components/treaments/TreatmentListFlat'
import CreatePayment from './CreatePayment'
import { Alert } from '@mui/lab'
import uuidv4 from 'uuid/v4'
import ConfirmModal from 'components/editor/more-popover/modal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: calc(100vh - 13rem);
`

const LeftPanel = styled.div`
  display: grid;
  grid-template-rows: 11rem auto 5rem;
  flex: 1;
  border: 1px solid ${colors.rgbGreyLight};
  border-radius: 0 0 2px 2px;
`

const DateWrap = styled.div`
  margin: 1rem 1rem 1rem 0rem;
  display: grid;
  background: white;
`

const ListOverFlow = styled.div`
  overflow: auto;
  height: 100%;
`

const TotalsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.rgbGreyLight};
`

const InputContainer = styled.div`
  margin: 1em;
  display: grid;
  position: relative !important;
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
  }
  ${props => css`
    input {
      color: ${colors[props.color]};
    }
  `}
`

const ListWrapper = styled.div`
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  border-top: 1px solid ${colors.rgbGreyLight};
  border-bottom: 1px solid ${colors.rgbGreyLight};
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin-left: 1rem;
  }
`

const CompleteWrap = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin-left: 1rem;
  }
  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${colors.softGreen};
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.softGreen};
  }
`

const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
)

const AppointmentCharge = ({
  show,
  isAppointment,
  invoiceData,
  selectedUser,
  selectedPractice,
  close
}) => {
  const { state, dispatch } = useContext(AppContext)
  const [updating, setUpdating] = useState(null)
  const [reSend, setReSend] = useState(false)
  const [printing, setPrinting] = useState(false)
  const { patient, treatments } = state
  const [invoiceSent, setInvoiceSent] = useState(false)
  const [invoiceDelete, setInvoiceDelete] = useState(false)
  const [invoiceFailed, setInvoiceFailed] = useState(false)
  const [invoiceCreate, setInvoiceCreate] = useState(null)
  const [editInvoice, setEditInvoice] = useState(false)
  const practitioners = state.list.data.filter(p => p.data.Profile === 'active')

  const defaultFormData = {
    code: null,
    note: '',
    patientId: null,
    Meta: { completed: true },
    proceedures: [],
    payments: [],
    FromDate: new Date(),
    ToDate: new Date(),
    Email: selectedUser?.Email || ''
  }

  const [form, setForm] = useState({
    code: `${format(new Date(), 'yyMdHms')}-${(invoiceData?.id || 0) + 1}`,
    note: '',
    patientId: null,
    Meta: { completed: true },
    proceedures: [],
    payments: [],
    FromDate: new Date(invoiceData?.Invoice?.FromDate) || new Date(),
    ToDate: new Date(invoiceData?.Invoice?.ToDate) || new Date(),
    Email: selectedUser?.Email || ''
  })

  const [invoice, setInvoice] = React.useState()
  const [checkedCharges, setCheckedCharges] = React.useState([])
  const [checkedPayments, setCheckedPayments] = React.useState([])

  const handelEditInvoice = () => {
    setEditInvoice(true)
    setForm({
      ...form,
      FromDate: new Date(invoice?.FromDate),
      ToDate: new Date(invoice?.ToDate),
      proceedures: invoice?.Charges?.map(c => ({
        ...c,
        Price: c.Amount
      })),
      payments: invoice?.Payments.map(p => ({
        ...p,
        Price: p.Amount,
        isPay: true
      }))
    })
  }

  const handleChargesToggle = value => () => {
    const currentIndex = checkedCharges.indexOf(value)
    const newChecked = [...checkedCharges]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedCharges(newChecked)
  }

  const handlePaymentsToggle = value => () => {
    const currentIndex = checkedPayments.indexOf(value)
    const newChecked = [...checkedPayments]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setCheckedPayments(newChecked)
  }

  useLayoutEffect(() => {
    if (invoiceData?.Invoice) {
      setInvoice(invoiceData?.Invoice)
      setForm({
        ...form,
        FromDate: new Date(invoiceData?.Invoice?.FromDate) || new Date(),
        ToDate: new Date(invoiceData?.Invoice?.ToDate) || new Date(),
        Email: selectedUser?.Email || ''
      })
    } else {
      setForm({
        code: `${format(new Date(), 'yyMdHms')}-${(invoiceData?.id || 0) + 1}`,
        note: '',
        patientId: null,
        Meta: { completed: true },
        proceedures: invoiceData?.Proceedures || [],
        payments: invoiceData?.Payments || [],
        FromDate: new Date(),
        ToDate: new Date(),
        Email: selectedUser?.Email || ''
      })
    }
  }, [invoiceData?.Invoice, show, selectedUser?.Email])

  return (
    <Dialog
      onClose={() => {
        setInvoice()
        setEditInvoice(false)
        setInvoiceDelete(false)
        setCheckedCharges([])
        setCheckedPayments([])
        setForm(defaultFormData)
        !updating && close()
      }}
      onClick={e => {
        setCheckedCharges([])
        setCheckedPayments([])
        e.preventDefault()
        e.stopPropagation()
      }}
      aria-labelledby='customized-dialog-title'
      open={show}
      color='primary'
      fullScreen
      maxWidth='lg'
    >
      <div>
        <div>
          <TitleRow>
            <p>
              {!!invoice
                ? `Invoice: ${invoice.Code}`
                : isAppointment
                ? 'Complete and Pay'
                : 'Create a new Invoice'}
            </p>
            {!!invoice && !editInvoice ? (
              <IconButton
                onClick={() => {
                  handelEditInvoice()
                }}
                size='large'
              >
                <EditIcon />
              </IconButton>
            ) : null}{' '}
            {!!invoice && !!editInvoice ? (
              <IconButton
                onClick={() => {
                  setEditInvoice(false)
                  setForm(defaultFormData)
                }}
                size='large'
              >
                <Undo />
              </IconButton>
            ) : null}{' '}
          </TitleRow>
        </div>
        <DialogContent dividers>
          {updating && (
            <div style={{ position: 'absolute', margin: '50%', zIndex: '100' }}>
              <DoubleBounce color={colors.primary} />
            </div>
          )}
          <Wrapper>
            <LeftPanel>
              <div style={{ borderBottom: `1px solid ${colors.rgbGreyLight}` }}>
                <FlexRow>
                  <div
                    style={{
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        marginTop: '1rem',
                        display: 'grid',
                        gridAutoFlow: 'column'
                      }}
                    >
                      <div
                        style={{
                          marginLeft: '1rem'
                        }}
                      >
                        <InputLabel shrink>Patient</InputLabel>
                        <Input
                          id='patient'
                          disabled
                          value={selectedUser?.Name}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '1rem'
                        }}
                      >
                        <InputLabel shrink>Email</InputLabel>
                        <Input
                          id='patient'
                          style={{ width: '100%' }}
                          value={form?.Email || ''}
                          onChange={e =>
                            setForm({ ...form, Email: e.target.value })
                          }
                          error={!form?.Email?.match(emailRegex)}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: '1rem' }}>
                    <InputLabel shrink>Invoice No</InputLabel>
                    <Input
                      id='invoice-no'
                      disabled
                      value={invoice?.Code || form.code || ''}
                    />
                  </div>
                </FlexRow>
                <FlexRow>
                  <div style={{ margin: '1rem' }}>
                    <textarea
                      minrows={3}
                      maxrows={12}
                      rows={3}
                      cols='30'
                      aria-label='textarea'
                      placeholder='Invoice notes ...'
                      onChange={e => setForm({ ...form, note: e.target.value })}
                      disabled={!!invoice}
                      value={invoice?.Note || undefined}
                    />
                  </div>

                  <div>
                    <div style={{ display: 'flex' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateWrap>
                          <DatePicker
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            label='Invoice date'
                            disabled={!!invoice && !editInvoice}
                            value={form.FromDate}
                            onChange={date =>
                              setForm({ ...form, FromDate: date })
                            }
                          />
                        </DateWrap>
                        <DateWrap>
                          <DatePicker
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            label='Due Date'
                            disabled={!!invoice && !editInvoice}
                            value={form.ToDate}
                            onChange={date =>
                              setForm({ ...form, ToDate: date })
                            }
                          />
                        </DateWrap>
                      </LocalizationProvider>
                    </div>
                  </div>
                </FlexRow>
              </div>
              <ListOverFlow>
                <ListCharges
                  show={show}
                  editing={editInvoice}
                  form={form}
                  invoice={invoice}
                  checkedCharges={checkedCharges}
                  checkedPayments={checkedPayments}
                  handleChargesToggle={handleChargesToggle}
                  handlePaymentsToggle={handlePaymentsToggle}
                  setForm={setForm}
                />
              </ListOverFlow>

              {(!invoice || editInvoice) && (
                <TotalsWrapper>
                  <InputContainer>
                    <CreatePayment
                      selectedPractitionerId={invoiceData?.PractitionerId}
                      selectedPatient={patient?.data}
                      createPayment={async data => {
                        setForm({
                          ...form,
                          payments: [
                            ...(form?.payments || []),
                            {
                              id: uuidv4(),
                              isPay: true,
                              Price: parseFloat(data.amount),
                              createdAt: new Date(),
                              Method: data.method,
                              Currency: 'EUR',
                              PatientId: form.patientId,
                              PractitionerId: data.practitionerId
                            }
                          ]
                        })
                      }}
                    />
                  </InputContainer>
                  <FlexRow>
                    <InputContainer>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          form?.proceedures
                            ?.map(p => p.Price)
                            .reduce((sum, i) => sum + i, 0)
                        )}
                        label='Total Cost'
                        variant='outlined'
                      />
                    </InputContainer>
                    <InputContainer color='green'>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          form?.payments
                            .map(p => p.Price)
                            .reduce((sum, i) => sum + i, 0)
                        )}
                        label='Total Paid'
                        variant='outlined'
                      />
                    </InputContainer>
                    <InputContainer color='red'>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          form?.proceedures
                            ?.map(p => p.Price)
                            .reduce((sum, i) => sum + i, 0) -
                            form?.payments
                              .map(p => p.Price)
                              .reduce((sum, i) => sum + i, 0)
                        )}
                        label='Total Owed'
                        variant='outlined'
                      />
                    </InputContainer>
                  </FlexRow>
                </TotalsWrapper>
              )}
              {!!invoice && invoice.Charges.length > 0 && !editInvoice && (
                <TotalsWrapper>
                  <InputContainer></InputContainer>
                  <FlexRow>
                    <InputContainer>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          invoice.Charges.map(p => p.Amount).reduce(
                            (sum, i) => sum + i,
                            0
                          )
                        )}
                        label='Total Cost'
                        variant='outlined'
                      />
                    </InputContainer>
                    <InputContainer color='green'>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          invoice.Payments.map(p => p.Amount).reduce(
                            (sum, i) => sum + i,
                            0
                          )
                        )}
                        label='Total Paid'
                        variant='outlined'
                      />
                    </InputContainer>
                    <InputContainer color='red'>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          invoice.Charges.map(p => p.Amount).reduce(
                            (sum, i) => sum + i,
                            0
                          ) -
                            invoice.Payments.map(p => p.Amount).reduce(
                              (sum, i) => sum + i,
                              0
                            )
                        )}
                        label='Total Owed'
                        variant='outlined'
                      />
                    </InputContainer>
                  </FlexRow>
                </TotalsWrapper>
              )}
            </LeftPanel>
            {(!invoice || !!editInvoice) && (
              <ListWrapper>
                <TreatmentListFlat
                  status={2}
                  list={treatments.data}
                  singleSelect
                  addProceedure={async proceedure => {
                    const id = uuidv4()
                    setForm({
                      ...form,
                      proceedures: [
                        ...(form.proceedures || []),
                        {
                          ...proceedure,
                          id,
                          Treatment: {
                            Name: proceedure.Name,
                            Code: proceedure.Code
                          },
                          PractitionerId: practitioners.find(
                            p =>
                              p.data.PractitionerId ===
                              invoiceData.PractitionerId
                          )?.data?.PractitionerId,
                          createdAt: new Date(),
                          Data: {
                            val: {
                              quantity: 1,
                              status: 2
                            }
                          }
                        }
                      ]
                    })
                    handleChargesToggle(id)()
                  }}
                />
              </ListWrapper>
            )}
          </Wrapper>
        </DialogContent>
      </div>
      <DialogActions>
        {!!invoice && (!!form.payments.length || editInvoice) && (
          <Button
            variant='contained'
            color='secondary'
            size='small'
            startIcon={<DeleteIcon />}
            onClick={() => setInvoiceDelete(true)}
          >
            Delete
          </Button>
        )}
        {isAppointment && (
          <CompleteWrap>
            <FormControlLabel
              onClick={event => event.stopPropagation()}
              control={
                <Switch
                  color='primary'
                  checked={form.Meta.completed}
                  onChange={event => {
                    setForm({
                      ...form,
                      Meta: { completed: event.target.checked }
                    })
                  }}
                />
              }
              label={'Mark Appointment Complete'}
            />
          </CompleteWrap>
        )}
        {close && (
          <Button
            size='small'
            disabled={updating}
            startIcon={<Cancel />}
            onClick={() => {
              setInvoice()
              setEditInvoice(false)
              setCheckedCharges([])
              setCheckedPayments([])
              setForm(defaultFormData)
              close()
            }}
          >
            Close
          </Button>
        )}
        {!!invoice && (!!form.payments.length || editInvoice) && (
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<AccountBalanceIcon />}
            onClick={async () => {
              setUpdating(true)

              const Payments = form.payments.map(p => ({
                ...p,
                Amount: p.Price,
                PatientId: p.PatientId || patient?.data?.PatientId
              }))

              const Charges = form.proceedures.map(p => ({
                ...p,
                Amount: p.Price,
                PatientId: p.PatientId || patient?.data?.PatientId
              }))

              const response = await updateInvoice(
                ADMIN_NS,
                {
                  ...invoice,
                  FromDate: form.FromDate,
                  ToDate: form.ToDate,
                  Payments,
                  Charges
                },
                invoice.InvoiceId
              )

              patient &&
                response &&
                response.code === 'SUCCESS' &&
                dispatch({
                  type: 'LOAD_ONE',
                  context: 'patient',
                  data: {
                    ...patient.data,
                    FromDate: response.data.FromDate,
                    ToDate: response.data.ToDate,
                    Invoices: patient.data?.Invoices.map(i =>
                      i.InvoiceId === response.data.InvoiceId
                        ? response.data
                        : i
                    ),
                    Appointments: patient.data.Appointments?.map(a =>
                      a.AppointmentId === invoiceData?.AppointmentId
                        ? {
                            ...a,
                            Invoices: a?.Invoices.Invoices.map(i =>
                              i.InvoiceId === response.data.InvoiceId
                                ? response.data
                                : i
                            )
                          }
                        : a
                    )
                  },
                  updating: false
                })

              //setForm(defaultFormData);
              setInvoice(response.data)
              setCheckedCharges([])
              setCheckedPayments([])
              setUpdating(false)
              setInvoiceDelete(false)
              setEditInvoice(false)
            }}
          >
            Update
          </Button>
        )}
        {!invoice && (
          <Tooltip
            title={
              <React.Fragment>
                <Typography color='inherit'>
                  {form?.Email?.length === 0 || !form?.Email ? (
                    <p>
                      Missing Email
                      <br />
                      Patient will not receive a copy of the invoice
                    </p>
                  ) : !form?.Email?.match(emailRegex) ? (
                    <p>
                      Invalid Email
                      <br />
                      Patient will not receive a copy of the invoices
                    </p>
                  ) : (
                    <p>
                      Email ok! <br />
                      Patient will receive a copy of the invoice
                    </p>
                  )}
                </Typography>
              </React.Fragment>
            }
          >
            <Button
              variant='contained'
              color='primary'
              size='small'
              disabled={!form.proceedures?.length}
              startIcon={<AccountBalanceIcon />}
              onClick={async () => {
                setInvoiceCreate({
                  message: 'Please wait. Creating Invoice...',
                  progress: 10
                })
                const Charges = form.proceedures
                  .filter(p => (show.length > 0 ? show.includes(p.id) : true))
                  .map(p => ({
                    ChargeId: p.ChargeId,
                    PractitionerId:
                      p?.PractitionerId ?? invoiceData?.PractitionerId,
                    TreatmentId: p.TreatmentId,
                    PatientId: invoiceData.PatientId,
                    AppointmentId: p.AppointmentId,
                    Data: p.Data,
                    Date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                    Method: p.Method,
                    Amount: p.Price,
                    Currency: 'EUR'
                  }))

                const Payments = form.payments.map(p => ({
                  PaymentId: p.PaymentId,
                  PractitionerId:
                    p?.PractitionerId ?? invoiceData?.PractitionerId,
                  ProceedureId: p.ProceedureId,
                  PatientId: invoiceData.PatientId,
                  AppointmentId: p.AppointmentId,
                  Method: p.Method,
                  Amount: p.Price,
                  Currency: 'EUR'
                }))

                const response = await createInvoice(ADMIN_NS, {
                  PracticeId: selectedUser.PracticeId,
                  PractitionerId: invoiceData?.PractitionerId,
                  AppointmentId: invoiceData?.AppointmentId,
                  PatientId: invoiceData.PatientId,
                  Code: form.code,
                  Note: form.note,
                  Meta: form.Meta.completed && form.Meta,
                  FromDate: form.FromDate,
                  ToDate: form.ToDate,
                  Charges,
                  Payments
                })

                if (response?.code === 'SUCCESS') {
                  setInvoiceCreate({
                    message: 'Invoice has been created successfully...',
                    progress: 50
                  })
                  const pdfContent = await dataInvoice(
                    response.data,
                    selectedUser,
                    selectedPractice
                  )

                  if (
                    form?.Email?.length > 0 &&
                    form?.Email?.match(emailRegex)
                  ) {
                    setInvoiceCreate({
                      message: 'Sending invoice receipt copy to Patient...',
                      progress: 70
                    })
                    const response = await reSendInvoice({
                      practice: selectedPractice,
                      user: { ...selectedUser, FormEmail: form.Email },
                      content: pdfContent
                    })
                    if (response?.code === 'SUCCESS') {
                      setInvoiceCreate({
                        message: 'Invoice receipt sent to Patient!',
                        progress: 100
                      })
                      setInvoiceSent(true)
                      setTimeout(() => {
                        setInvoiceCreate(null)
                        setInvoiceSent(false)
                      }, 3000)
                    } else {
                      setInvoiceCreate({
                        message: 'Invoice receipt failed to send!',
                        progress: 100
                      })
                      setInvoiceFailed(true)
                      setTimeout(() => {
                        setInvoiceCreate(null)
                        setInvoiceFailed(false)
                      }, 3000)
                    }
                  } else {
                    setInvoiceCreate({
                      message: 'Invoice was not sent to Patient!',
                      progress: 100
                    })
                    setInvoiceCreate(null)
                  }
                } else {
                  setInvoiceCreate({
                    message: 'Invoice Failed to send',
                    progress: 70
                  })
                  setInvoiceFailed(true)
                  setTimeout(() => {
                    setInvoiceCreate(null)
                    setInvoiceFailed(false)
                  }, 3000)
                }

                setForm({
                  ...defaultFormData,
                  Email: selectedUser?.Email || ''
                })
                response && setInvoice(response.data)
                setCheckedCharges([])
                setCheckedPayments([])
                //close(false);
              }}
            >
              Confirm
            </Button>
          </Tooltip>
        )}
        {!!invoice && !editInvoice && (
          <Button
            variant='contained'
            color='primary'
            size='small'
            disabled={
              !selectedPractice ||
              form?.Email?.length === 0 ||
              !form?.Email?.match(emailRegex)
            }
            startIcon={
              reSend ? (
                <DoubleBounce color={colors.white} />
              ) : (
                <Email size='small' />
              )
            }
            onClick={async () => {
              setReSend(true)

              const content = await dataInvoice(
                invoice,
                selectedUser,
                selectedPractice
              )
              const response = await reSendInvoice({
                practice: selectedPractice,
                user: { ...selectedUser, FormEmail: form.Email },
                content
              })
              if (response?.code === 'SUCCESS') {
                setReSend(false)
                setInvoiceSent(true)
                setTimeout(() => {
                  setInvoiceSent(false)
                  setInvoiceFailed(false)
                }, 3000)
              } else {
                setReSend(false)
                setInvoiceFailed(true)
                setTimeout(() => {
                  setInvoiceSent(false)
                  setInvoiceFailed(false)
                }, 3000)
              }
            }}
          >
            {reSend ? 'Sending ...' : 'Resend Receipt'}
          </Button>
        )}
        {!!invoice && !editInvoice && (
          <Button
            variant='contained'
            size='small'
            disabled={updating}
            startIcon={
              printing ? (
                <DoubleBounce color={colors.white} />
              ) : (
                <Print size='small' />
              )
            }
            onClick={() => {
              setPrinting(true)
              printInvoice(invoice, selectedUser, selectedPractice)
              setTimeout(() => {
                setPrinting(false)
              }, 3000)
            }}
          >
            Print
          </Button>
        )}
        {(editInvoice || !invoice) && (
          <Button
            variant='outlined'
            size='small'
            disabled={updating}
            startIcon={<Print size='small' />}
            onClick={() => {
              const Payments =
                form?.payments?.map(p => ({
                  ...p,
                  PaymentId: p.PaymentId || uuidv4(),
                  Amount: p.Price,
                  PatientId: p.PatientId || patient?.data?.PatientId
                })) || []

              const Charges =
                form?.proceedures?.map(p => ({
                  ...p,
                  Amount: p.Price,
                  PatientId: p.PatientId || patient?.data?.PatientId
                })) || []

              const invoicePreview = {
                ...invoice,
                ...form,
                FromDate: format(form.FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                ToDate: format(form.ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                Payments,
                Charges
              }

              printInvoice(invoicePreview, selectedUser, selectedPractice)
            }}
          >
            Print Preview
          </Button>
        )}
      </DialogActions>
      {invoiceSent && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20000,
            bottom: '1rem',
            right: '1rem'
          }}
        >
          <Alert
            onClose={() => setInvoiceSent(false)}
            elevation={6}
            variant='filled'
            severity='success'
          >
            Invoice has been sent successfully!
          </Alert>{' '}
        </div>
      )}
      {invoiceFailed && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20001,
            bottom: '1rem',
            right: '1rem'
          }}
        >
          <Alert
            onClose={() => setInvoiceFailed(false)}
            elevation={6}
            variant='filled'
            severity='error'
          >
            There was an error sending the invoice!
          </Alert>{' '}
        </div>
      )}
      {invoiceCreate ? (
        <Dialog open={true} color='primary' maxWidth='lg'>
          <LinearProgress
            variant='buffer'
            value={invoiceCreate.progress}
            valueBuffer={invoiceCreate.progress + 10}
          />
          <Alert severity='info'>{invoiceCreate.message}</Alert>
        </Dialog>
      ) : null}

      <ConfirmModal
        showModal={invoiceDelete}
        hideModal={() => setInvoiceDelete(false)}
        continueClick={async () => {
          setUpdating(true)
          const response = await deleteInvoice(ADMIN_NS, invoice.InvoiceId)
          patient &&
            response &&
            response.code === 'SUCCESS' &&
            dispatch({
              type: 'LOAD_ONE',
              context: 'patient',
              data: {
                ...patient.data,
                Invoices: patient.data?.Invoices.filter(
                  i => i.InvoiceId !== invoice.InvoiceId
                ),

                Appointments: patient.data.Appointments?.map(a =>
                  a.AppointmentId === invoiceData?.AppointmentId
                    ? {
                        ...a,
                        Invoices: a?.Invoices.Invoices.filter(
                          i => i.InvoiceId !== invoice.InvoiceId
                        )
                      }
                    : a
                )
              },
              updating: false
            })

          setForm(defaultFormData)
          setInvoice()
          setCheckedCharges([])
          setCheckedPayments([])
          setUpdating(false)
          setEditInvoice(false)
          setInvoiceDelete(false)
          close()
        }}
      />
    </Dialog>
  )
}

export default AppointmentCharge
