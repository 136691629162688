import React, { useContext, useMemo } from 'react'
import { PopToSide } from '../../../../../components/common'
import Editor from '../../../../../components/editor'
import { createPatientData } from '../actions'
import AppointmentForm from '../../../../../components/calendar/components/AppointmentForm'
import { patientFormSchema, createButtons } from '../formSettings'
import { AppContext } from '../../../../../hooks/context'
import { Dialog, DialogContent, IconButton, Slide } from '@mui/material'
import { Close } from '@mui/icons-material'
import colors from 'library/styled-components/colors'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const AppointmentPreview = ({
  user,
  show,
  appointmentData,
  prePop,
  openPatient,
  setOpenPatient,
  setSelectedDate,
  removeItem,
  editorDataUpdate,
  cancelMethod,
  close
}) => {
  const { dispatch } = useContext(AppContext)
  const backgroundColor = useMemo(() => {
    if (!!prePop?.Meta?.Recall || !!appointmentData?.Meta?.Recall) {
      return colors.veryLightOrange
    }
    return colors.vertLightPrimary
  }, [!!prePop?.Meta, !!appointmentData?.Meta])

  if (!appointmentData && !prePop) return null

  return (
    <Dialog
      open={show}
      onClose={close}
      fullScreen={true}
      maxWidth={'lg'}
      TransitionComponent={Transition}
    >
      <DialogContent
        style={{
          background: backgroundColor
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0
          }}
        >
          <IconButton aria-label='close' onClick={close} size='large'>
            <Close />
          </IconButton>
        </div>
        {(appointmentData || prePop) && (
          <AppointmentForm
            user={user}
            id={appointmentData ? appointmentData.AppointmentId : null}
            appointmentData={appointmentData}
            prePop={prePop}
            setSelectedDate={setSelectedDate}
            removeItem={removeItem}
            updateAppointment={editorDataUpdate}
            cancelMethod={cancelMethod}
          />
        )}

        {openPatient && (
          <PopToSide left='8rem' top='7.5rem'>
            <Editor
              metaData={patients && patients.meta}
              formSchema={patientFormSchema}
              sendData={async data => {
                setUpdating(true)
                const mutate = {
                  ...data,
                  SendMail: {
                    val: true
                  },
                  SendSms: {
                    val: true
                  }
                }

                delete mutate.Communicate
                const response = await createPatientData(mutate)(
                  dispatch,
                  navigateTo
                )

                if (response && response.code === 'SUCCESS') {
                  dispatch({
                    type: 'UPDATE',
                    context: 'patients',
                    meta: { updating: false, warning: null }
                  })

                  setUpdating(false)
                  setOpenPatient(false)
                }
              }}
              cancelMethod={() => setOpenPatient(false)}
              buttons={createButtons}
              submitError={
                (patients && patients.meta.error) ||
                (patients && patients.meta.warning)
              }
              settings={{
                side: true,
                width: { mobile: '100%', desktop: '100%' }
              }}
            />
          </PopToSide>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AppointmentPreview
