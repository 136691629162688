import React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import PersonIcon from '@mui/icons-material/Person'
import EventIcon from '@mui/icons-material/Event'
import CloseIcon from '@mui/icons-material/Close'
import CommentBlockItem from './TimeLineBlock/CommentBlockItem'
import { format } from 'date-fns'
import { IconButton, Popover } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import NoteIcon from '@mui/icons-material/Note'
import MessageIcon from '@mui/icons-material/Message'
import FeedbackIcon from '@mui/icons-material/Feedback'
import ReceiptIcon from '@mui/icons-material/Receipt'
import colors from 'library/styled-components/colors'
import { InputContainer } from 'components/common'
import { Button, TextField, Typography } from '@mui/material'
import { Message, Send } from '@mui/icons-material'
import ConfirmModal from 'components/editor/more-popover/modal'
import SmsMessageBlock from 'components/sms-client/SmsMessageBlock'

function highlightReminderDifferences (fromObj, toObj) {
  const updates = []
  // Find differences

  let differences =
    toObj?.filter(
      toEvent => !fromObj?.some(fromEvent => fromEvent?.id === toEvent?.id)
    ) || []

  // Format the output and add to array
  differences?.forEach(function (reminderEvent) {
    let { deleted, reminder } = reminderEvent
    updates.push(
      ` ${
        deleted ? 'Reminder Removed' : 'Reminder Changed'
      }, Reminder: ${reminder}`
    )
  }) || []
  return updates
}

function describeObjectChanges (
  obj1,
  obj2,
  treatments,
  practitioners,
  cancelation,
  path = ''
) {
  const changes = []

  for (const key in obj1) {
    const newPath = path ? `${path}.${key}` : key

    if (
      typeof obj1[key] === 'object' &&
      obj1[key] !== null &&
      !Array.isArray(obj1[key]) &&
      typeof obj2[key] === 'object' &&
      obj2[key] !== null &&
      !Array.isArray(obj2[key])
    ) {
      changes.push(
        ...describeObjectChanges(
          obj1[key],
          obj2[key],
          treatments,
          practitioners,
          cancelation,
          newPath
        )
      )
    } else if (obj1[key] !== obj2[key]) {
      switch (newPath) {
        case 'Meta':
        case 'Meta.Comments':
        case 'Meta.Notes':
        case 'Meta.UpdateNotes':
        case 'Meta.Reminders':
        case 'Meta.RemindersSent':
        case 'updatedAt':
        case 'CategoryId':
        case 'Meta.ReminderEvents':
          break
        case 'SendSms':
          if (!obj1[key] && obj2[key] === true) {
            changes.push('Send SMS settings enabled')
          } else if (obj1[key] === true && obj2[key] === false) {
            changes.push('Send SMS settings disabled')
          }
          break
        case 'Meta.inTreatment':
          if (!obj1[key] && obj2[key] === true) {
            changes.push('Treatment started')
          } else if (obj1[key] === true && obj2[key] === false) {
            changes.push('Treatment reset')
          }
          break
        case 'Meta.arrived':
          if (!obj1[key] && obj2[key] === true) {
            changes.push('Patient arrived')
          } else if (obj1[key] === true && obj2[key] === false) {
            changes.push('Patient reset')
          }
          break
        case 'CancelationId':
          if (obj1[key] === null) {
            changes.push(
              `Appointment Cancelled - ${cancelation?.Reason || ''}.`
            )
          } else if (obj2[key] === null) {
            changes.push(
              `Appointment Uncancelled - "${cancelation?.Reason || ''}.`
            )
          } else {
            changes.push(
              `Cancelation changed from ${cancelation?.Reason || ''}" to "${
                cancelation?.Reason || ''
              }".`
            )
          }
          break
        case 'TreatmentId':
          if (obj1[key] === null) {
            changes.push(
              `Treatment added - ${
                treatments.data.find(t => t.data.TreatmentId === obj2[key])
                  ?.data?.Name
              }.`
            )
          } else if (obj2[key] === null) {
            changes.push(
              `Treatment removed - "${
                treatments.data.find(t => t.data.TreatmentId === obj1[key])
                  ?.data?.Name
              }.`
            )
          } else {
            changes.push(
              `Treatment changed from ${
                treatments.data.find(t => t.data.TreatmentId === obj1[key])
                  ?.data?.Name
              }" to "${
                treatments.data.find(t => t.data.TreatmentId === obj2[key])
                  ?.data?.Name
              }".`
            )
          }
          break
        case 'PatientId':
          if (obj1[key] === null) {
            changes.push('Patient added.')
          } else if (obj2[key] === null) {
            changes.push('Patient removed.')
          } else {
            changes.push('Patient changed.')
          }
          break
        case 'PractitionerId':
          if (obj1[key] === null) {
            changes.push(
              `Practitioner added - ${
                practitioners.data.find(
                  t => t.data.PractitionerId === obj2[key]
                )?.data?.Name
              }.`
            )
          } else if (obj2[key] === null) {
            changes.push(
              `Practitioner removed - "${
                practitioners.data.find(
                  t => t.data.PractitionerId === obj1[key]
                )?.data?.Name
              }".`
            )
          } else {
            changes.push(
              `Practitioner changed from "${
                practitioners.data.find(
                  t => t.data.PractitionerId === obj1[key]
                )?.data?.Name
              }" to "${
                practitioners.data.find(
                  t => t.data.PractitionerId === obj2[key]
                )?.data?.Name
              }".`
            )
          }
          break
        case 'Start':
          changes.push(
            `Start time changed from "${format(
              new Date(obj1[key]),
              'dd/MM/yyyy HH:mm'
            )}" to "${format(new Date(obj2[key]), 'dd/MM/yyyy HH:mm')}".`
          )
          break
        case 'End':
          changes.push(
            `End time changed from "${format(
              new Date(obj1[key]),
              'dd/MM/yyyy HH:mm'
            )}" to "${format(new Date(obj2[key]), 'dd/MM/yyyy HH:mm')}".`
          )
          break
        default:
          changes.push(
            `${newPath} changed from "${JSON.stringify()}" to "${JSON.stringify(
              obj2[key]
            )}".`
          )
          break
      }
    }
  }

  return changes
}

function describeChanges (
  old_data,
  new_data,
  treatments,
  practitioners,
  cancelation
) {
  const changes = []

  if (old_data === null) {
    changes.push('New appointment created.')
  } else if (new_data === null) {
    changes.push('Appointment deleted.')
  } else {
    const descriptions = describeObjectChanges(
      old_data,
      new_data,
      treatments,
      practitioners,
      cancelation
    )
    descriptions.length && changes.push(...descriptions)
  }

  return changes.join(' ')
}

function CommentPopover ({
  disableInput = false,
  user,
  form,
  setForm,
  appointmentData,
  setAppointmentData,
  updateAppointment
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [comment, setComment] = React.useState('')

  const handleClick = event => {
    setAnchorEl(event?.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 'inherit', width: '9.25rem' }}>
        <Typography variant='body2' color='textSecondary'>
          &nbsp;
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color='primary'>
          <IconButton onClick={handleClick} size='large'>
            <AddIcon size='small' style={{ color: 'white' }} />
          </IconButton>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent></TimelineContent>

      <Popover
        id={id}
        open={open || false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: '1rem' }}>
          <InputContainer>
            <TextField
              disabled={disableInput}
              value={comment}
              style={{ background: 'white' }}
              onChange={e => setComment(e.target.value)}
              id='outlined-multiline-static'
              label='Comment'
              multiline
              rows={3}
              placeholder='Add any Notes here'
              variant='outlined'
            />
          </InputContainer>
          <InputContainer>
            <Button
              onClick={e => {
                const meta = {
                  ...form?.meta,
                  Comments: [
                    ...(form?.meta?.Comments || []),
                    {
                      id: (form?.meta?.Comments?.length || 0) + 1,
                      text: comment,
                      user: user?.data?.Name,
                      createdAt: new Date()
                    }
                  ]
                }
                setComment('')
                setForm({
                  ...form,
                  meta
                })
                if (
                  !!appointmentData?.AppointmentId ||
                  !!appointmentData?.EventId
                ) {
                  setAppointmentData(prevData => ({
                    ...prevData,
                    Meta: {
                      ...prevData.Meta,
                      Comments: [
                        ...(prevData.Meta?.Comments || []),
                        {
                          id: (prevData.Meta?.Comments?.length || 0) + 1,
                          text: comment,
                          user: user?.data?.Name,
                          createdAt: new Date()
                        }
                      ]
                    }
                  }))
                  updateAppointment(
                    {
                      ...form,
                      meta
                    },
                    false
                  )
                }
                setAnchorEl(null)
              }}
              variant='contained'
              style={{ color: colors.accentColor }}
              startIcon={<Send />}
            >
              Add Comment
            </Button>
          </InputContainer>
        </div>
      </Popover>
    </TimelineItem>
  )
}

export default function TimelineBlock ({
  user,
  form,
  setForm,
  updateAppointment,
  disableInput = false,
  appointmentDataProps,
  treatments,
  practitioners
}) {
  const [deleteItem, setDeleteItem] = React.useState(false)
  const [appointmentData, setAppointmentData] =
    React.useState(appointmentDataProps)

  React.useEffect(() => {
    setAppointmentData(appointmentDataProps)
  }, [appointmentDataProps])

  const appointmentEvents = [
    ...(appointmentData?.Cancelation
      ? [
          {
            ...appointmentData?.Cancelation,
            type: 'cancelation'
          }
        ]
      : []),
    ...(appointmentData?.Invoices?.map(c => ({
      ...c,
      type: 'invoice'
    })) || []),
    ...(appointmentData?.AppointmentsLogs?.map(c => ({
      ...c,
      createdAt: c?.timestamp,
      action: describeChanges(
        c?.old_data,
        c?.new_data,
        treatments,
        practitioners,
        appointmentData.Cancelation
      ),
      type: 'logs'
    }))?.filter(c => c.action.trim() !== '') || []),
    ...(appointmentData?.Meta?.Comments?.map(c => ({
      ...c,
      type: 'comment'
    })) || []),
    ...(appointmentData?.Meta?.ReminderEvents?.map(c => ({
      data: c,
      updatedAt: c?.updatedAt,
      createdAt: c?.createdAt,
      created: c?.created,
      type: 'reminders'
    })) || []),
    ...(appointmentData?.SmsMessages?.map(c => ({ ...c, type: 'sms' })) || [])
  ].sort(
    (b, a) =>
      new Date(a?.updatedAt || a?.createdAt || a?.created) -
      new Date(b?.updatedAt || b?.createdAt || b?.created)
  )

  const renderNewComments = form?.meta?.Comments?.map(comment => {
    return (
      <TimelineItem key={comment.id}>
        <TimelineOppositeContent style={{ flex: 'inherit', width: '10rem' }}>
          <Typography variant='body2' color='textSecondary'>
            {format(
              new Date(
                comment?.updatedAt || comment?.createdAt || comment?.created
              ),
              'do MMMM yyyy, h:mm a'
            )}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <NoteIcon size='small' style={{ color: colors.white }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <CommentBlockItem
            key={comment.id}
            comment={comment}
            user={user}
            id={comment.id}
            form={form}
            setForm={setForm}
            updateAppointment={updateAppointment}
            setDeleteItem={setDeleteItem}
          />
        </TimelineContent>
      </TimelineItem>
    )
  })

  const renderEvents = appointmentEvents?.map(event => {
    switch (event.type) {
      case 'invoice':
        return (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent
              style={{ flex: 'inherit', width: '10rem' }}
            >
              <Typography variant='body2' color='textSecondary'>
                {format(new Date(event?.createdAt), 'do MMMM yyyy, h:mm a')}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ background: colors.accentColor }}>
                <ReceiptIcon size='small' style={{ color: colors.white }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant='body2' color='textSecondary'>
                Invoice created - {event?.Code}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )
      case 'cancelation':
        return (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent
              style={{ flex: 'inherit', width: '10rem' }}
            >
              <Typography variant='body2' color='textSecondary'>
                {format(new Date(event?.updatedAt), 'do MMMM yyyy, h:mm a')}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='secondary'>
                <CloseIcon size='small' style={{ color: colors.white }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent></TimelineContent>
          </TimelineItem>
        )
      case 'logs':
        return (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent
              style={{ flex: 'inherit', width: '10rem' }}
            >
              <Typography variant='body2' color='textSecondary'>
                {format(new Date(event?.createdAt), 'do MMMM yyyy, h:mm a')}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant='outlined'>
                <PersonIcon size='small' style={{ color: colors.primary }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant='body2' color='textSecondary'>
                {event?.action}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )

      case 'reminders':
        return (
          <TimelineItem key={event.data.id}>
            <TimelineOppositeContent
              style={{ flex: 'inherit', width: '10rem' }}
            >
              <Typography variant='body2' color='textSecondary'>
                {event.data?.created
                  ? format(
                      new Date(event.data?.created),
                      'do MMMM yyyy, h:mm a'
                    )
                  : ''}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                color={
                  event.data?.deleted || event.data?.type === 'SMS_FAILED'
                    ? 'error'
                    : event.data?.type === 'SMS_SENT'
                    ? 'success'
                    : 'primary'
                }
              >
                {event.data?.type === 'SMS_FAILED' ? (
                  <MessageIcon size='small' style={{ color: colors.white }} />
                ) : (
                  ''
                )}
                {event.data?.type === 'SMS_SENT' ? (
                  <Message size='small' style={{ color: colors.white }} />
                ) : (
                  ''
                )}
                {event.data?.type === 'SMS_QUEUE' || !event.data?.type ? (
                  <FeedbackIcon size='small' style={{ color: colors.white }} />
                ) : (
                  ''
                )}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant='body2' color='textSecondary'>
                Reminder {event.data?.reminder}
              </Typography>

              <Typography variant='body2' color='textSecondary'>
                {event.data?.type === 'SMS_FAILED'
                  ? 'SMS Reminder Failed to Send'
                  : ''}
                {event.data?.type === 'SMS_SENT'
                  ? 'SMS Reminder Sent Successfully'
                  : ''}
                {event.data?.type === 'SMS_QUEUE' || !event.data?.type
                  ? 'SMS Reminder Queued'
                  : ''}
                {event.data?.deleted ? 'Reminder Removed from Appointment' : ''}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )
      case 'comment':
        return (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent
              style={{ flex: 'inherit', width: '10rem' }}
            >
              <Typography variant='body2' color='textSecondary'>
                {format(
                  new Date(
                    event?.updatedAt || event?.createdAt || event?.created
                  ),
                  'do MMMM yyyy, h:mm a'
                )}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <NoteIcon size='small' style={{ color: colors.white }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <CommentBlockItem
                key={event.id}
                comment={event}
                user={user}
                id={appointmentData?.AppointmentId || appointmentData?.EventId}
                form={form}
                setForm={setForm}
                updateAppointment={(data, save = true) => {
                  updateAppointment(data, save)
                  setAppointmentData(prevData => ({
                    ...prevData,
                    Meta: {
                      ...prevData.Meta,
                      Comments: prevData.Meta?.Comments?.map(c =>
                        c.id === event.id
                          ? {
                              ...c,
                              text: data?.meta?.Comments?.find(
                                n => n.id === event.id
                              )?.text,
                              user: data?.meta?.Comments?.find(
                                n => n.id === event.id
                              )?.user,
                              updatedAt: new Date()
                            }
                          : c
                      )
                    }
                  }))
                }}
                setDeleteItem={setDeleteItem}
              />
            </TimelineContent>
          </TimelineItem>
        )
      case 'sms':
        return (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent
              style={{ flex: 'inherit', width: '10rem' }}
            >
              <Typography variant='body2' color='textSecondary'>
                {format(new Date(event?.updatedAt), 'do MMMM yyyy, h:mm a')}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='primary'>
                <Message size='small' style={{ color: colors.white }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <SmsMessageBlock message={event} hideTime={true} />
            </TimelineContent>
          </TimelineItem>
        )
      default:
        return []
    }
  })

  return (
    <Timeline>
      <CommentPopover
        user={user}
        form={form}
        setForm={setForm}
        updateAppointment={updateAppointment}
        setAppointmentData={setAppointmentData}
        appointmentData={appointmentData}
        disableInput={disableInput}
      />
      {appointmentData ? renderEvents : renderNewComments}

      {!appointmentData && !renderNewComments ? (
        <TimelineItem>
          <TimelineOppositeContent style={{ flex: 'inherit', width: '10rem' }}>
            <Typography variant='body2' color='textSecondary'>
              &nbsp;
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color='primary'>
              <NoteIcon size='small' style={{ color: colors.white }} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>Add comments here</TimelineContent>
        </TimelineItem>
      ) : (
        ''
      )}
      <ConfirmModal
        showModal={deleteItem}
        hideModal={() => setDeleteItem(false)}
        continueClick={async () => {
          const meta = {
            ...form?.meta,
            Comments: form?.meta?.Comments?.filter(n => n.id !== deleteItem)
          }
          setForm({
            ...form,
            meta
          })
          setDeleteItem(null)
          if (!!appointmentData?.AppointmentId || !!appointmentData?.EventId) {
            setAppointmentData(prevData => ({
              ...prevData,
              Meta: {
                ...prevData.Meta,
                Comments: prevData.Meta?.Comments?.filter(
                  n => n.id !== deleteItem
                )
              }
            }))
            updateAppointment(
              {
                ...form,
                meta
              },
              false
            )
          }
        }}
      />

      {appointmentData ? (
        <TimelineItem>
          <TimelineOppositeContent style={{ flex: 'inherit', width: '10rem' }}>
            <Typography variant='body2' color='textSecondary'>
              {format(
                new Date(appointmentData?.createdAt),
                'do MMMM yyyy, h:mm a'
              )}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              variant='outlined'
              style={{ background: colors.white }}
              color='primary'
            >
              <EventIcon size='small' style={{ color: colors.secondary }} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>
      ) : (
        ''
      )}
    </Timeline>
  )
}
