import { format } from 'date-fns'
import localforage from 'localforage'
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth
} from 'date-fns'
import { authHeader, handleResponse } from './handler'
import { ADMIN_NS } from '../config'

const getAppointmentsPaginate = async (state, from, to) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/paginate/${from}/${to}`,
    {
      method: 'get',
      headers: await authHeader(state)
    }
  )
    .then(response => handleResponse(response))
    .catch(err => console.log('Fetch Error :-S', err))
}

// Utility function to compute start and end dates based on the range
function computeDateRange (date, range) {
  const selectedDateObj = new Date(date)
  let startDate, endDate

  switch (range) {
    case 'day':
      startDate = startOfDay(selectedDateObj)
      endDate = endOfDay(selectedDateObj)
      break
    case 'week':
      startDate = startOfWeek(selectedDateObj)
      endDate = endOfWeek(selectedDateObj)
      break
    case 'month':
      startDate = startOfMonth(selectedDateObj)
      endDate = endOfMonth(selectedDateObj)
      break
    default:
      throw new Error('Invalid range')
  }

  return { startDate, endDate }
}

// Utility function to check if the date range has been fetched
async function checkIfRangeFetched (startDate, endDate) {
  let fetchedRanges =
    (await localforage.getItem('fetchedAppointmentRanges')) || []

  const isFetched = fetchedRanges.some(fetchedRange => {
    return (
      new Date(fetchedRange.startDate) <= startDate &&
      new Date(fetchedRange.endDate) >= endDate
    )
  })

  return { isFetched, fetchedRanges }
}

// Utility function to update fetched ranges
async function updateFetchedRanges (fetchedRanges, startDate, endDate) {
  fetchedRanges.push({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  })
  await localforage.setItem('fetchedAppointmentRanges', fetchedRanges)
}

// Reusable function to fetch appointments for a given date and range
export async function fetchAppointmentsForDate (date, range = 'day') {
  // Store the selected date in localforage
  await localforage.setItem('selectedDate', date)

  // Compute start and end dates
  const { startDate, endDate } = computeDateRange(date, range)

  // Check if the date range has already been fetched
  const { isFetched, fetchedRanges } = await checkIfRangeFetched(
    startDate,
    endDate
  )

  let data = null
  if (!isFetched) {
    // Fetch data for the specified date range
    data = await getAppointmentsPaginate(
      ADMIN_NS,
      format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    )

    // Update fetched ranges
    await updateFetchedRanges(fetchedRanges, startDate, endDate)
  } else {
    console.log('Data for this date range already exists')
  }

  return { date, data }
}
