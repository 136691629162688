import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import ConfirmModal from 'components/editor/more-popover/modal'
import BlockIcon from '@mui/icons-material/Block'
import Typography from '@mui/material/Typography'
import {
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch
} from '@mui/material'
import Alert from '@mui/lab/Alert'
import {
  differenceInHours,
  differenceInMinutes,
  startOfMinute,
  addMinutes
} from 'date-fns'

import colors from '../../../library/styled-components/colors'
import AppointmentPreviewCalendar from './AppointmentPreviewCalendar'
import { AppContext } from '../../../hooks/context'
import { useHistory } from 'react-router-dom'
import DateTimeRangePicker from './DentizDatePickers/DateTimeRangePicker'
import DatePickerDuration from './DentizDatePickers/DatePickerDuration'
import TimelineBlock from './TimelineBlock'

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  font-size: 0.9em;
  text-align: left;
`

const InputContainer = styled.div`
  margin-bottom: 1rem;
`

const BlockWrap = styled.div`
  display: grid;
`

const PickerContainer = styled.div`
  position: relative !important;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 25rem auto 40rem;
  grid-gap: 1rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`

const RowThree = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1rem;
`

const AppointmentCommentForm = ({
  user,
  id,
  appointmentData,
  prePop,
  updateAppointment,
  cancelMethod,
  setSelectedDate,
  removeItem,
  error,
  disableAppointment,
  updatePreviewEvent
}) => {
  const { state } = useContext(AppContext)
  const { list, appointments, treatments } = state
  const practitioners = list
  const [showModal, setShowModal] = useState(false)
  const [rangePicker, setRangePicker] = useState(false)
  let history = useHistory()

  React.useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        // Return true to allow transition, false to block
        cancelMethod()
        return false
      }
    })

    return () => {
      unblock()
    }
  }, [])

  const startTime = prePop
    ? startOfMinute(prePop.Start)
    : appointmentData && new Date(appointmentData.Start)

  const endTime = prePop
    ? prePop.End
    : appointmentData && new Date(appointmentData.End)

  const start = startTime
  const end = endTime

  const practitionerId = prePop
    ? prePop.PractitionerId
    : appointmentData && appointmentData.PractitionerId

  const meta = prePop ? prePop.Meta : appointmentData && appointmentData.Meta

  const [form, setForm] = useState(null)

  useEffect(() => {
    if (practitionerId) {
      setForm({
        start,
        end,
        startTime,
        hours: differenceInHours(endTime, startTime) || 0,
        minutes: id ? differenceInMinutes(endTime, startTime) % 60 : 30,
        practitionerId,
        meta: {
          ...meta,
          Comments: meta?.Comments
        },

        ...prePop
      })
    }
  }, [practitionerId, appointmentData, meta, prePop])

  return (
    form && (
      <FormContainer>
        <Grid>
          <div>
            <BlockWrap>
              <ConfirmModal
                showModal={showModal}
                hideModal={() => setShowModal(false)}
                continueClick={() => {
                  cancelMethod()
                  removeItem()
                }}
              />

              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <BlockIcon />
                &nbsp;
                <Typography variant='h6' component='h6'>
                  Event Block
                </Typography>
              </div>
              {error && (
                <InputContainer>
                  <Alert severity='error'>{error}</Alert>
                </InputContainer>
              )}

              {form.start && form.end && rangePicker ? (
                <DateTimeRangePicker
                  disabled={disableAppointment}
                  start={form.start}
                  end={form.end}
                  onChange={(start, end) => {
                    setForm({ ...form, start, end })
                    setSelectedDate && setSelectedDate(start)
                  }}
                />
              ) : (
                ''
              )}

              {form.start && form.end && !rangePicker ? (
                <DatePickerDuration form={form} setForm={setForm} />
              ) : (
                ''
              )}

              <FormControl>
                <InputContainer>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rangePicker}
                        onChange={() => {
                          setRangePicker(!rangePicker)
                          setForm({
                            ...form,
                            start: form.start,
                            end: addMinutes(form.start, 30)
                          })
                        }}
                      />
                    }
                    label='Date Range Picker'
                  />
                </InputContainer>
              </FormControl>

              <FormControl variant='outlined'>
                <InputContainer>
                  <InputLabel>Practitioner</InputLabel>
                  <Select
                    style={{ background: 'white', width: '100%' }}
                    disabled={disableAppointment}
                    value={form.practitionerId}
                    onChange={e => {
                      setForm({ ...form, practitionerId: e.target.value })
                      updatePreviewEvent({ PractitionerId: e.target.value })
                    }}
                    label='Practitioner'
                  >
                    {practitioners.data.map(p => (
                      <MenuItem
                        key={p.data.PractitionerId}
                        value={p.data.PractitionerId}
                      >
                        {p.data.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </InputContainer>
              </FormControl>

              <FormControl variant='outlined'>
                <InputContainer>
                  <TextField
                    label='Title'
                    variant='outlined'
                    style={{ background: 'white', width: '100%' }}
                    disabled={disableAppointment}
                    value={form.meta?.Title ?? ''}
                    onChange={e => {
                      setForm({
                        ...form,
                        meta: { ...form.meta, Title: e.target.value }
                      })
                    }}
                  />
                </InputContainer>
              </FormControl>

              {id ? (
                <RowThree>
                  <FormControl>
                    <Button
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        updateAppointment(form, true)
                      }}
                      disabled={disableAppointment}
                      variant={id ? 'contained' : 'outlined'}
                    >
                      Update
                    </Button>
                  </FormControl>
                  <FormControl>
                    <Button onClick={cancelMethod} variant='outlined'>
                      Cancel
                    </Button>
                  </FormControl>

                  <FormControl>
                    <Button
                      onClick={() => {
                        setShowModal(true)
                      }}
                      variant='contained'
                      color='error'
                    >
                      Delete
                    </Button>
                  </FormControl>
                </RowThree>
              ) : (
                <Row>
                  <FormControl>
                    <Button
                      onClick={() => updateAppointment(form, true)}
                      variant='contained'
                      disabled={disableAppointment}
                    >
                      Create
                    </Button>
                  </FormControl>
                  <FormControl>
                    <Button onClick={cancelMethod} variant='outlined'>
                      Cancel
                    </Button>
                  </FormControl>
                </Row>
              )}
            </BlockWrap>
          </div>

          {form && (
            <AppointmentPreviewCalendar
              eventId={id}
              practitionerName={
                practitioners.data.find(
                  p => p.data.PractitionerId === form.practitionerId
                )?.data.Name
              }
              color={
                colors.themebyId[
                  practitioners.data.findIndex(
                    p => p.data.PractitionerId === form.practitionerId
                  ) % 6 || 0
                ]
              }
              schedules={
                practitioners.data.find(
                  p => p.data.PractitionerId === form.practitionerId
                )?.data.Schedules || []
              }
              appointments={appointments}
              form={form}
              updateDate={start => {
                setForm({ ...form, start, startTime: start })
                setSelectedDate && setSelectedDate(start)
              }}
              updateTime={({ start, end }) => {
                setForm({ ...form, start, end })
              }}
            />
          )}

          {form && (
            <div style={{ overflowX: 'auto', height: '94vh' }}>
              <TimelineBlock
                user={user}
                appointmentDataProps={appointmentData}
                treatments={treatments}
                practitioners={practitioners}
                form={form}
                setForm={setForm}
                updateAppointment={updateAppointment}
                disableInput={disableAppointment}
              />
            </div>
          )}
        </Grid>
      </FormContainer>
    )
  )
}

export default AppointmentCommentForm
