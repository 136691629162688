import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import ConfirmModal from 'components/editor/more-popover/modal'
import CloseIcon from '@mui/icons-material/Close'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import uuidv4 from 'uuid/v4'
import { ADMIN_NS } from 'config'
import {
  FormControl,
  Button,
  Switch,
  Select,
  InputLabel,
  MenuItem,
  Slider,
  TextField,
  TextareaAutosize as TextArea,
  FormControlLabel,
  Checkbox,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  Divider,
  Tooltip,
  FormLabel,
  LinearProgress
} from '@mui/material'
import DoubleBounce from 'components/loaders/double-bounce'
import Alert from '@mui/lab/Alert'
import { addDays, addMinutes, addMonths, getYear, startOfHour } from 'date-fns'
import DropdownFilter from '../../../components/dropdown-filter'
import colors from '../../../library/styled-components/colors'
import AppointmentPreviewCalendar from './AppointmentPreviewCalendar'
import { AppContext } from '../../../hooks/context'
import {
  AddAlert,
  Event as EventIcon,
  Textsms,
  TextsmsOutlined
} from '@mui/icons-material'
import { Autocomplete } from '@mui/lab'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import TimelineBlock from './TimelineBlock'
import DateTimeRangePicker from './DentizDatePickers/DateTimeRangePicker'
import DatePickerDuration from './DentizDatePickers/DatePickerDuration'
import { calculatePublicHolidays } from 'library/calendar'
import { checkAppointmentAvailability } from 'actions/appointments'

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  font-size: 0.9em;
  text-align: left;
`

const InputContainer = styled.div`
  margin-bottom: 1rem;
`

const BlockWrap = styled.div`
  display: grid;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 25rem auto 40rem;
  grid-gap: 1rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`

const RowThree = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1rem;
`

const AddReminderController = ({ form, setForm }) => {

  return (
    <FormControl style={{ width: '100%' }}>
      {form?.meta?.ReminderEvents?.filter(
        event => !event.deleted && event?.type === 'SMS_QUEUE'
      ).map(event => (
        <InputContainer key={event.id}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography gutterBottom>
              Reminder ({event.reminder}) day
            </Typography>

            <CloseIcon
              onClick={() => {
                setForm({
                  ...form,
                  meta: {
                    ...form.meta,
                    // Optionally update Reminders and RemindersSent if they are still used elsewhere
                    Reminders: form?.meta?.Reminders?.filter(
                      r => r !== event.reminder
                    ),
                    RemindersSent: form?.meta?.RemindersSent?.filter(
                      r => r !== event.reminder
                    ),
                    // Mark the event as deleted using its id
                    ReminderEvents: form.meta?.ReminderEvents?.map(r =>
                      r.id === event.id ? { ...r, deleted: true } : r
                    )
                  }
                })
              }}
              color='secondary'
              style={{
                cursor: 'pointer'
              }}
            />
          </div>
          <Slider
            disabled={form.meta?.RemindersSent?.includes(event.reminder)}
            onChange={(e, number) => {
              setForm({
                ...form,
                meta: {
                  ...form.meta,
                  // Optionally update Reminders if needed
                  Reminders: form.meta?.Reminders?.map(r =>
                    r === event.reminder ? number : r
                  ),
                  // Update the correct ReminderEvent using its id
                  ReminderEvents: form.meta?.ReminderEvents?.map(r =>
                    r.id === event.id
                      ? {
                          ...r,
                          reminder: number,
                          dateToSend: addDays(new Date(form.startTime), number)
                        }
                      : r
                  )
                }
              })
            }}
            value={event.reminder}
            getAriaValueText={value => {
              return value
            }}
            aria-labelledby='discrete-slider-small-steps'
            step={1}
            marks
            min={1}
            max={7}
            valueLabelDisplay='auto'
          />
        </InputContainer>
      ))}

      <InputContainer>
        <Button
          sx={{ width: '100%' }}
          onClick={() => {
            // Step 1: Get existing reminders excluding deleted ones
            const existingReminders =
              form.meta?.ReminderEvents?.filter(event => !event.deleted)?.map(
                event => event.reminder
              ) ?? []

            // Step 2: Calculate defInt based on existing reminders
            const defInt =
              [1, 2, 3, 4, 5, 6, 7].find(i => !existingReminders.includes(i)) ??
              1

            // Step 3: Update the form state
            setForm({
              ...form,
              meta: {
                ...form.meta,
                Reminders: existingReminders.length
                  ? [...existingReminders, defInt]
                  : [defInt],
                ReminderEvents: [
                  ...(form.meta?.ReminderEvents ?? []),
                  {
                    id: uuidv4(),
                    created: new Date(),
                    reminder: defInt,
                    sent: false,
                    type: 'SMS_QUEUE',
                    dateToSend: addDays(new Date(form.startTime), defInt)
                  }
                ]
              }
            })
          }}
          variant='outlined'
          color='secondary'
          startIcon={<AddAlert />}
          disabled={form.meta?.Reminders?.length > 2}
        >
          Add Reminder
        </Button>
      </InputContainer>
    </FormControl>
  )
}

const AppointmentForm = ({
  user,
  id,
  appointmentData,
  prePop,
  updateAppointment,
  cancelMethod,
  setSelectedDate,
  removeItem,
  disableAppointment = false
}) => {
  const { state } = useContext(AppContext)
  const { patients, list, treatments } = state
  const [updating, setUpdating] = useState(false)
  const [checkingVailability, setCheckingVailability] = useState(false)
  const [error, setError] = useState(null)
  const [rangePicker, setRangePicker] = useState(false)

  const practitioners = list
  const [showModal, setShowModal] = useState(false)
  const [openSmsCofirm, setOpenSmsCofirm] = useState(false)
  const [openPatientList, setOpenPatientList] = useState(false)
  const [overlappingAppointments, setOverlappingAppointments] = useState([])

  const patientId = prePop
    ? prePop.PatientId
    : appointmentData && appointmentData.PatientId

  const practitionerId = prePop
    ? prePop.PractitionerId
    : appointmentData && appointmentData.PractitionerId

  const defaultAppointmentLength =
    practitioners.data.find(p => p.data.PractitionerId === practitionerId)?.data
      ?.Meta?.DefaultAppointmentLength || 30

  const startTime = prePop
    ? startOfHour(new Date(prePop.Start))
    : appointmentData && new Date(appointmentData.Start)

  const endTime = prePop
    ? addMinutes(startOfHour(new Date(prePop.End)), defaultAppointmentLength)
    : appointmentData && new Date(appointmentData.End)

  const start = startTime
  const end = endTime

  const [form, setForm] = useState({ sendSms: false, start })

  const categoryId = null

  const treatmentId = prePop
    ? prePop.TreatmentId
    : appointmentData && appointmentData.TreatmentId

  const reminder = prePop
    ? prePop.Reminder
    : appointmentData && appointmentData.Reminder

  const sendSms = prePop
    ? prePop.SendSms
    : appointmentData && appointmentData.SendSms

  const cancelation = prePop
    ? prePop.SendSms
    : appointmentData && appointmentData.Cancelation

  const meta = prePop ? prePop.Meta : appointmentData && appointmentData.Meta

  useEffect(() => {
    if (practitionerId) {
      setForm({
        start,
        end,
        startTime,
        patientId,
        practitionerId,
        categoryId,
        treatmentId,
        reminder,
        sendSms,
        cancelation,
        meta,
        ...prePop
      })
    }
  }, [practitionerId, patientId, appointmentData, meta, prePop])

  const selectedUser =
    form &&
    form.patientId &&
    patients &&
    patients.data &&
    patients.data.find(p => p.data.PatientId === form.patientId)?.data

  const title = useMemo(() => {
    if (form?.meta?.Recall) {
      return 'Recall Appointment'
    }
    return 'Appointment Details'
  }, [form?.meta])

  function validateAppointment (rawData) {
    setError(null)

    if (!rawData?.patientId) {
      setError('you must select a patient')
      return false
    }

    if (!rawData?.practitionerId) {
      setError('you must select a practitioner')
      return false
    }
    return true
  }

  let history = useHistory()
  React.useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        // Return true to allow transition, false to block
        cancelMethod()
        return false
      }
    })

    return () => {
      unblock()
    }
  }, [])

  const createAppointmentConfirmation = async form => {
    if (validateAppointment(form)) setOpenSmsCofirm(true)
    setCheckingVailability(true)
    const response = await checkAppointmentAvailability(ADMIN_NS, {
      ...form,
      appointmentId: id
    })

    setOverlappingAppointments(response.data)
    setCheckingVailability(false)
  }

  return (
    form && (
      <FormContainer>
        <Grid>
          <div>
            <BlockWrap>
              <ConfirmModal
                showModal={showModal}
                hideModal={() => setShowModal(false)}
                continueClick={() => {
                  cancelMethod()
                  removeItem()
                }}
              />
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {!form.meta?.Recall ? <EventIcon /> : <AllInclusiveIcon />}
                &nbsp;
                <Typography variant='h6' component='h6'>
                  {title}
                </Typography>
              </div>
              {error && (
                <InputContainer>
                  <Alert severity='error'>{error}</Alert>
                </InputContainer>
              )}
              {form.meta?.Recall && (
                <InputContainer>
                  <Typography gutterBottom>
                    Recall ({form.meta?.Recall}) month{' '}
                  </Typography>
                  <Slider
                    style={{
                      color: colors.warning
                    }}
                    onChange={(e, Recall) => {
                      const newStart = addMonths(new Date(), Recall)
                      setForm({
                        ...form,
                        meta: { ...form.meta, Recall },
                        start: newStart
                      })
                    }}
                    value={form.meta?.Recall}
                    getAriaValueText={value => {
                      return value
                    }}
                    aria-labelledby='discrete-slider-small-steps'
                    step={1}
                    marks
                    min={1}
                    max={12}
                    valueLabelDisplay='auto'
                  />
                </InputContainer>
              )}

              {form.start && form.end && rangePicker ? (
                <DateTimeRangePicker
                  disabled={disableAppointment}
                  start={form.start}
                  end={form.end}
                  onChange={(start, end) => {
                    setForm(prevForm => {
                      if (
                        appointmentData?.Start &&
                        new Date(start).toISOString() !==
                          new Date(appointmentData?.Start).toISOString()
                      ) {
                        return {
                          ...prevForm,
                          start,
                          end,
                          meta: {
                            ...prevForm.meta,
                            RemindersSent: []
                          }
                        }
                      } else {
                        return {
                          ...prevForm,
                          start,
                          end
                        }
                      }
                    })
                    setSelectedDate && setSelectedDate(start)
                  }}
                />
              ) : (
                ''
              )}

              {form.start && form.end && !rangePicker ? (
                <DatePickerDuration form={form} setForm={setForm} />
              ) : (
                ''
              )}

              <FormControl>
                <InputContainer>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rangePicker}
                        onChange={() => {
                          setRangePicker(!rangePicker)
                          setForm({
                            ...form,
                            start: form.start,
                            end: addMinutes(form.start, 30)
                          })
                        }}
                      />
                    }
                    label='Date Range Picker'
                  />
                </InputContainer>
              </FormControl>

              <FormControl variant='outlined'>
                <InputContainer>
                  <InputLabel>Practitioner</InputLabel>
                  <Select
                    style={{ background: 'white', width: '100%' }}
                    disabled={disableAppointment}
                    value={form.practitionerId ?? 0}
                    onChange={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      setForm({ ...form, practitionerId: e.target.value })
                    }}
                    label='Practitioner'
                  >
                    {practitioners.data.map(p => (
                      <MenuItem
                        key={p.data.PractitionerId}
                        value={p.data.PractitionerId}
                      >
                        {p.data.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </InputContainer>
              </FormControl>

              <FormControl variant='outlined'>
                <InputContainer>
                  <Autocomplete
                    variant='outlined'
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    freeSolo
                    options={treatments.data.map(t => t.data)}
                    value={
                      treatments.data.find(
                        t => t.data.TreatmentId === treatmentId
                      )?.data
                    }
                    getOptionLabel={option => `${option.Code} | ${option.Name}`}
                    onChange={(_, treatment) =>
                      setForm({ ...form, treatmentId: treatment?.TreatmentId })
                    }
                    renderOption={props => {
                      return (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {props.key}
                        </Box>
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        style={{ background: 'white', width: '100%' }}
                        variant='outlined'
                        label='Choose a Treatment'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password' // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />{' '}
                </InputContainer>
              </FormControl>

              <AddReminderController form={form} setForm={setForm} />

              <FormControl variant='outlined'>
                <InputContainer>
                  {!selectedUser && (
                    <DropdownFilter
                      onFocus={() => setOpenPatientList(true)}
                      open={openPatientList}
                      placeholder={'Search by Patient or Phone number'}
                      options={patients ? patients.data.map(p => p.data) : []}
                      selectedUser={selectedUser}
                      setSelectedUser={item => {
                        setForm({ ...form, patientId: item.PatientId })
                        setOpenPatientList(false)
                      }}
                      loading={false}
                    />
                  )}
                  {selectedUser && (
                    <ListItem
                      component='nav'
                      button
                      style={{ background: 'white', width: '100%' }}
                      alignitems='flex-start'
                      onClick={() => setForm({ ...form, patientId: null })}
                    >
                      <ListItemAvatar>
                        <Avatar alt={selectedUser && selectedUser.Name}>
                          {selectedUser &&
                            selectedUser.Name.split(' ')
                              .map(i => i.charAt(0).toUpperCase())
                              .slice(0, 2)}
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        component='div'
                        primary={
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography>
                                {selectedUser && selectedUser.Name}
                              </Typography>

                              <Tooltip
                                title={
                                  <React.Fragment>
                                    <Typography
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                      }}
                                      color='inherit'
                                    >
                                      <span>Total Charges: &nbsp;</span>
                                      <span style={{ alignSelf: 'flex-end' }}>
                                        {new Intl.NumberFormat('en-IE', {
                                          style: 'currency',
                                          currency: 'EUR'
                                        }).format(
                                          !isNaN(
                                            selectedUser?.Outstanding
                                              ?.TotalCharges
                                          )
                                            ? selectedUser?.Outstanding
                                                ?.TotalCharges
                                            : 0
                                        )}
                                      </span>
                                    </Typography>

                                    <Typography
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                      }}
                                      color='inherit'
                                    >
                                      <span>Total Paid: &nbsp;</span>
                                      <span style={{ alignSelf: 'flex-end' }}>
                                        {new Intl.NumberFormat('en-IE', {
                                          style: 'currency',
                                          currency: 'EUR'
                                        }).format(
                                          !isNaN(
                                            selectedUser?.Outstanding?.TotalPaid
                                          )
                                            ? selectedUser?.Outstanding
                                                ?.TotalPaid
                                            : 0
                                        )}
                                      </span>
                                    </Typography>

                                    <Typography
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                      }}
                                      color='inherit'
                                    >
                                      <span>Total Owed: &nbsp;</span>
                                      {new Intl.NumberFormat('en-IE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                      }).format(
                                        !isNaN(
                                          selectedUser?.Outstanding?.TotalOwed
                                        )
                                          ? selectedUser?.Outstanding?.TotalOwed
                                          : 0
                                      )}
                                    </Typography>
                                  </React.Fragment>
                                }
                              >
                                <CreditCardIcon
                                  style={{
                                    cursor: 'pointer',
                                    color:
                                      selectedUser?.Outstanding?.TotalOwed > 0
                                        ? 'red'
                                        : 'green'
                                  }}
                                />
                              </Tooltip>
                            </div>

                            <div
                              style={{
                                display: 'grid',
                                justifyContent: 'space-between'
                              }}
                            >
                              {selectedUser?.Phone ? (
                                <div>Phone: {selectedUser.Phone}</div>
                              ) : (
                                ''
                              )}
                              {selectedUser?.Email ? (
                                <div>Email: {selectedUser.Email}</div>
                              ) : (
                                ''
                              )}
                              {selectedUser?.DateOfBirth ? (
                                <Typography>
                                  DoB:{' '}
                                  {new Date(
                                    selectedUser.DateOfBirth
                                  ).toLocaleDateString('en-GB')}
                                </Typography>
                              ) : (
                                ''
                              )}
                            </div>
                          </>
                        }
                      />
                    </ListItem>
                  )}
                </InputContainer>
              </FormControl>
              {form.cancelation ? (
                <FormControl variant='outlined'>
                  <InputContainer>
                    <InputLabel>Reason</InputLabel>
                    <Select
                      style={{ background: 'white', width: '100%' }}
                      disabled={disableAppointment}
                      value={form.cancelation.Reason}
                      onChange={e => {
                        setForm({
                          ...form,
                          cancelation: {
                            ...form.cancelation,
                            Reason: e.target.value
                          }
                        })
                      }}
                      label='Reason'
                    >
                      <MenuItem value={'FORGOT'}>{'Forgot'}</MenuItem>
                      <MenuItem value={'EMERGENCY'}>{'Emergency'}</MenuItem>
                      <MenuItem value={'FAILED'}>{'Did not Attend'}</MenuItem>
                      <MenuItem value={'SICK'}>{'Illness'}</MenuItem>
                      <MenuItem value={'WORK'}>{'Work'}</MenuItem>
                      <MenuItem value={'PERSONAL'}>
                        {'Personal Reasons'}
                      </MenuItem>
                    </Select>
                  </InputContainer>
                </FormControl>
              ) : (
                ''
              )}
              {form.cancelation ? (
                <FormControl>
                  <FormLabel>Cancelation Comments</FormLabel>
                  <InputContainer>
                    <TextArea
                      disabled={disableAppointment}
                      style={{
                        background: 'white',
                        padding: '10px',
                        width: '90%'
                      }}
                      minRows={3}
                      value={form.cancelation.Information}
                      onChange={e =>
                        setForm({
                          ...form,
                          cancelation: {
                            ...form.cancelation,
                            Information: e.target.value
                          }
                        })
                      }
                    />
                  </InputContainer>
                </FormControl>
              ) : (
                ''
              )}
              <FormControl>
                <InputContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form?.sendSms ?? false}
                        onChange={() => {
                          setForm({ ...form, sendSms: !form.sendSms })
                        }}
                        icon={<TextsmsOutlined />}
                        checkedIcon={<Textsms />}
                        name='checked'
                        color='primary'
                      />
                    }
                    label='Send Sms to Patient'
                  />
                </InputContainer>
              </FormControl>
              {id ? (
                <RowThree>
                  <FormControl>
                    <Button
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        createAppointmentConfirmation(form)
                      }}
                      variant='contained'
                      disabled={disableAppointment}
                      color={id ? 'primary' : 'default'}
                    >
                      Update
                    </Button>
                  </FormControl>
                  <FormControl>
                    <Button onClick={cancelMethod} variant='outlined'>
                      Close
                    </Button>
                  </FormControl>

                  <FormControl>
                    <Button
                      onClick={() => {
                        setShowModal(true)
                      }}
                      variant='contained'
                      color='secondary'
                    >
                      Delete
                    </Button>
                  </FormControl>
                </RowThree>
              ) : (
                <Row>
                  <FormControl>
                    <Button
                      onClick={() => {
                        createAppointmentConfirmation(form)
                      }}
                      variant='contained'
                      disabled={disableAppointment}
                      color={'primary'}
                    >
                      Create
                    </Button>
                  </FormControl>
                  <FormControl>
                    <Button onClick={cancelMethod} variant='outlined'>
                      Close
                    </Button>
                  </FormControl>
                </Row>
              )}
            </BlockWrap>
          </div>

          <Dialog
            open={openSmsCofirm}
            maxWidth='sm'
            fullWidth
            alignitems='center'
            onClose={() => setOpenSmsCofirm(false)}
          >
            {checkingVailability ? (
              <>
                <LinearProgress color='primary' />
                <DialogTitle>Checking Appointment Availability ...</DialogTitle>
              </>
            ) : overlappingAppointments.length ? (
              <>
                <DialogTitle>
                  This appointment overlaps with the following appointments
                </DialogTitle>
                <DialogContent>
                  {overlappingAppointments.map(a => (
                    <DialogContentText key={a.AppointmentId}>
                      {a.Practitioner && a.Practitioner.Name} -{' '}
                      {a.Patient && a.Patient.User.Name} -{' '}
                      {new Date(a.Start).toLocaleString()}
                    </DialogContentText>
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenSmsCofirm(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ background: colors.primary, color: 'white' }}
                    onClick={() => {
                      setOverlappingAppointments([])
                    }}
                  >
                    Continue &nbsp;
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                {form.sendSms ? (
                  <DialogTitle>
                    You have chosen to send an SMS update
                  </DialogTitle>
                ) : (
                  <DialogTitle>
                    You have chosen not to send an SMS update
                  </DialogTitle>
                )}
                <DialogContent>
                  {form.sendSms ? (
                    <DialogContentText>
                      Uncheck the box if you do not want to send an SMS update
                      to <strong>{selectedUser?.Name}</strong> on{' '}
                      <strong>{selectedUser?.Phone}</strong>
                      or click continue below.
                    </DialogContentText>
                  ) : (
                    <DialogContentText>
                      Check the box if you want to send an SMS update to{' '}
                      <strong>{selectedUser?.Name}</strong> on{' '}
                      <strong>{selectedUser?.Phone}</strong>
                      or click continue below.
                    </DialogContentText>
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!form.sendSms}
                          onChange={() =>
                            setForm({ ...form, sendSms: !form.sendSms })
                          }
                          name='Sms'
                        />
                      }
                      label='Send SMS to Patient'
                    />
                  </FormGroup>
                </DialogContent>

                <Divider />

                <DialogContent>
                  {form.meta?.Reminders?.filter(
                    reminder => !form.meta?.RemindersSent?.includes(reminder)
                  ).length ? (
                    <DialogContentText>
                      This appointment has{' '}
                      <strong>
                        {
                          form.meta?.Reminders?.filter(
                            reminder =>
                              !form.meta?.RemindersSent?.includes(reminder)
                          ).length
                        }
                      </strong>{' '}
                      reminders that will be sent
                    </DialogContentText>
                  ) : (
                    ''
                  )}

                  {form.meta?.ReminderEvents?.length ? (
                    <DialogContentText>
                      This appointment has{' '}
                      <strong>{form.meta?.ReminderEvents?.length}</strong>{' '}
                      reminders that have already been sent
                    </DialogContentText>
                  ) : (
                    ''
                  )}

                  {!form.meta?.Reminders?.length ? (
                    <DialogContentText>
                      This Appointment has no Reminders set
                    </DialogContentText>
                  ) : (
                    ''
                  )}

                  {!form.meta?.Reminders?.length ? (
                    <DialogContentText>
                      If you wish to send reminders, please add them here. You
                      can add up to 3 reminders.
                    </DialogContentText>
                  ) : (
                    ''
                  )}

                  <AddReminderController form={form} setForm={setForm} />
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenSmsCofirm(false)
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{ background: colors.primary, color: 'white' }}
                    onClick={() => {
                      setUpdating(true)
                      if (!updating) updateAppointment(form, true)
                    }}
                    disabled={updating}
                  >
                    Continue &nbsp;
                    {updating && <DoubleBounce size={20} color='white' />}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>

          {form && (
            <AppointmentPreviewCalendar
              appointmentId={id}
              practitionerName={
                practitioners.data.find(
                  p => p.data.PractitionerId === form.practitionerId
                )?.data.Name
              }
              publicHolidays={
                form?.start && form?.practitionerId
                  ? calculatePublicHolidays(
                      getYear(form?.start),
                      form?.practitionerId
                    )
                  : []
              }
              defaultAppointmentLength={defaultAppointmentLength}
              color={
                colors.themebyId[
                  practitioners.data.findIndex(
                    p => p.data.PractitionerId === form.practitionerId
                  ) % 6 || 0
                ]
              }
              schedules={
                practitioners.data.find(
                  p => p.data.PractitionerId === form.practitionerId
                )?.data.Schedules || []
              }
              form={form}
              updateDate={start => {
                setForm({ ...form, start, startTime: start })
                setSelectedDate && setSelectedDate(start)
              }}
              updateTime={({ start, end }) => {
                setForm(prevForm => {
                  if (
                    appointmentData?.Start &&
                    new Date(start).toISOString() !==
                      new Date(appointmentData?.Start).toISOString()
                  ) {
                    return {
                      ...prevForm,
                      start,
                      end,
                      meta: {
                        ...prevForm.meta,
                        RemindersSent: []
                      }
                    }
                  } else {
                    return {
                      ...prevForm,
                      start,
                      end
                    }
                  }
                })
              }}
            />
          )}

          {form && (
            <div style={{ overflowX: 'auto', height: '94vh' }}>
              <TimelineBlock
                user={user}
                appointmentDataProps={appointmentData}
                treatments={treatments}
                practitioners={practitioners}
                form={form}
                setForm={setForm}
                updateAppointment={updateAppointment}
                disableInput={disableAppointment}
              />
            </div>
          )}
        </Grid>
      </FormContainer>
    )
  )
}

export default AppointmentForm
