import React, { useState } from 'react'
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Button,
  Popover,
  Box,
  TextField
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'

const MultiSelectPopover = ({
  items,
  selectedItems,
  setSelectedItems,
  buttonLabel = `${selectedItems.length} selected`,
  title = 'Select Items'
} = {}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [tempSelectedItems, setTempSelectedItems] = useState([...selectedItems])
  const [searchQuery, setSearchQuery] = useState('')

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setTempSelectedItems([...selectedItems])
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggle = value => () => {
    const currentIndex = tempSelectedItems.indexOf(value)
    const newChecked = [...tempSelectedItems]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempSelectedItems(newChecked)
  }

  const handleAllToggle = () => {
    if (tempSelectedItems.length === items.length) {
      setTempSelectedItems([])
    } else {
      setTempSelectedItems(items.map(item => item.value))
    }
  }

  const handleOk = () => {
    setSelectedItems(tempSelectedItems)
    handleClose()
  }

  const handleCancel = () => {
    handleClose()
  }

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  const filteredItems = items
    .filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => {
      const aSelected = tempSelectedItems.includes(a.value)
      const bSelected = tempSelectedItems.includes(b.value)
      if (aSelected === bSelected) {
        return a.name.localeCompare(b.name)
      }
      return aSelected ? -1 : 1
    })
    
  return (
    <div>
      <Button
        sx={{ height: '56px' }}
        variant='outlined'
        onClick={handleClick}
        startIcon={<FilterListIcon />}
      >
        {buttonLabel}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box
          sx={{
            p: 2,
            maxHeight: '400px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <TextField
            placeholder={`Search ${title} ...`}
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            margin='dense'
          />
          <Box sx={{ overflowY: 'auto', flexGrow: 1 }}>
            <MenuItem key='all' onClick={handleAllToggle}>
              <Checkbox checked={tempSelectedItems.length === items.length} />
              <ListItemText
                primary={`${
                  items.length === tempSelectedItems.length
                    ? 'Unselect'
                    : 'Select'
                } All`}
              />
            </MenuItem>
            {filteredItems.map(item => (
              <MenuItem key={item.key} onClick={handleToggle(item.value)}>
                <Checkbox
                  checked={tempSelectedItems.indexOf(item.value) > -1}
                />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleCancel} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleOk} color='primary'>
              Ok
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

export default MultiSelectPopover
