import React from "react";
import { eventBlockType } from "library/calendar";
import AppointmentEventBlock from "./event-blocks/appointment-event/AppointmentEventBlock";
import CommentEventBlock from "./event-blocks/CommentEventBlock";
import BlockEventBlock from "./event-blocks/BlockEventBlock";
import OnlineBlock from "./event-blocks/OnlineBlock";
import HolidayBlock from "./event-blocks/HolidayBlock";
import DoubleBounce from "components/loaders/double-bounce";
import colors from "library/styled-components/colors";

const CustomEvent = (props) => {
  const { info } = props;
  if (!info) return <div />;
  
  const viewType = info.view.type;

  if (info.event.extendedProps?.Meta?.updating)
    return <DoubleBounce color={colors.primary} margin="4px" />;

  switch (eventBlockType(info?.event?.extendedProps)) {
    case "Appointment":
      return <AppointmentEventBlock {...props} viewType={viewType} />;
    case "Comment":
      return <CommentEventBlock {...props} />;
    case "Block":
      return <BlockEventBlock {...props} />;
    case "Online":
      return <OnlineBlock {...props} />;
    case "Holiday":
      return <HolidayBlock {...props} />;
  }
};

export default CustomEvent;
