import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import styled from 'styled-components'
import {
  mapBusinessHours,
  mapSimpleAppointments,
  mapSimpleEvents
} from '../../../library/calendar'
import { PreviewCalendarStyles } from '../../common'
import { addDays, addMinutes } from 'date-fns'
import c, { openingHours } from 'config'
import { fetchAppointmentsForDate } from 'library/PaginatedLoader'
import { LinearProgress } from '@mui/material'
import { updateList } from 'library/resources'
import { AppContext } from 'hooks/context'

const StyledEventContent = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-style: ${props => (props.isNewEventChanged ? 'dashed' : 'solid')};
    border-radius: 3px;
    pointer-events: none; // Prevents the pseudo-element from interfering with mouse events
  }
`
function renderEventContent (eventInfo) {
  const { event } = eventInfo
  const isNewEventChanged = event.extendedProps.isNewEventChanged // Adjust as needed

  return (
    <StyledEventContent
      key={event?.id}
      isNewEventChanged={isNewEventChanged}
      style={{
        backgroundColor: event.backgroundColor,
        borderColor: event.borderColor,
        borderStyle: event.borderStyle,
        color: event.textColor
      }}
    />
  )
}

const Container = styled(PreviewCalendarStyles)`
  display: grid;
  background: inherit;
  position: relative !important;
  height: fit-content;
  code {
    font-family: 'Nunito', sans-serif;
  }
`

const AppointmentPreviewCalendar = ({
  appointmentId,
  eventId,
  practitionerName,
  publicHolidays,
  defaultAppointmentLength,
  color,
  form,
  schedules,
  updateTime
}) => {
  const { state, dispatch } = useContext(AppContext)
  const { appointments } = state
  const previewComponentRef = createRef()
  const [loading, setLoading] = useState(false)
  const businessHours = mapBusinessHours(schedules)

  const events = useMemo(
    () =>
      eventId
        ? mapSimpleEvents(appointments, { ...form, eventId })
        : mapSimpleAppointments(appointments, { ...form, appointmentId }),
    [appointments, form, eventId, appointmentId]
  )

  async function initialiseCalendar(date) {
    setLoading(true);
    const { data } = await fetchAppointmentsForDate(date, 'week');
    if (data) {
      await updateList('appointments', () => data)(dispatch);
    }
    setLoading(false);
  }

  useEffect(() => {
    console.log('form', form);
    if (!form?.start) return;

    initialiseCalendar(form.start); // Call the function to initialize the calendar
    let calendarApi = previewComponentRef.current.getApi();
    calendarApi.gotoDate(form.start);
  }, []);

  return (
    <Container color={color}>
      <div
        className='preview'
        style={{
          backgroundColor: 'white'
        }}
      >
        {loading ? (
          <LinearProgress
            style={{ position: 'absolute', width: '100%', zIndex: '1' }}
          />
        ) : null}
        <FullCalendar
          slotMinTime={openingHours.slotMinTime}
          slotMaxTime={openingHours.slotMaxTime}
          ref={previewComponentRef}
          plugins={[interactionPlugin, timeGridPlugin]}
          timeZone={'local'}
          themeSystem='bootstrap'
          initialView={
            appointmentId || eventId ? 'timeGridDay' : 'timeGridWeek'
          }
          dayHeaderFormat={{ weekday: 'long' }}
          height='48rem'
          weekends={true}
          editable={true}
          selectable={true}
          slotEventOverlap={true}
          eventOverlap={(stillEvent, movingEvent) => {
            return stillEvent.allDay && movingEvent.allDay
          }}
          validRange={{
            start: ((!appointmentId || !eventId) && new Date()) || undefined
          }}
          allDaySlot={true}
          headerToolbar={{
            left: 'prev,name',
            center: 'title',
            right: 'next'
          }}
          businessHours={businessHours}
          eventConstraint='businessHours'
          selectConstraint='businessHours'
          customButtons={{
            next: {
              text: 'next',
              click: async function () {  

                // add 7 days
                
                const date = addDays(form.start, 7) // add 7 days
                updateTime({ 
                  start: date,
                  end: addMinutes(date, defaultAppointmentLength || 30)
                })
 
                previewComponentRef?.current?.getApi().gotoDate(date)
                
                setLoading(true)

                const { data } = await fetchAppointmentsForDate(date, 'week')
                if (data) {
                  await updateList('appointments', () => data)(dispatch)
                }

                setLoading(false)
              }
            },
            prev: {
              text: 'prev',
              click: async function () {
                
                // subtract 7 days
                const date = addDays(form.start, -7) // subtract 7 days
                updateTime({ 
                  start: date,
                  end: addMinutes(date, defaultAppointmentLength || 30)
                })
 
                previewComponentRef?.current?.getApi().gotoDate(date)
                
                setLoading(true)

                const { data } = await fetchAppointmentsForDate(date, 'week')
                if (data) {
                  await updateList('appointments', () => data)(dispatch)
                }

                setLoading(false)
              }
            },
            name: {
              text: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '4px'
                  }}
                >
                  <div> {practitionerName} </div>
                </div>
              )
            }
          }}
          hiddenDays={schedules.filter(s => s.TypeId === 4).map(s => s.Day)}
          events={[...events, ...(publicHolidays || [])]}
          eventContent={renderEventContent} // custom render function
          dateClick={e => {
            updateTime({
              start: e.date,
              end: addMinutes(e.date, defaultAppointmentLength || 30)
            })
          }}
          eventClick={e => {
            updateTime({
              start: e.event.start,
              end: e.event.end
            })
          }}
          eventChange={calEvent => {
            const {
              event: { start, end }
            } = calEvent
            updateTime({
              start,
              end
            })
          }}
        />
      </div>
    </Container>
  )
}

export default AppointmentPreviewCalendar
