import React from 'react'
import styled from 'styled-components'
import { Comment, Event, Block, AllInclusive } from '@mui/icons-material'
import colors from 'library/styled-components/colors'
import { Button, Modal, Box } from '@mui/material'

const Container = styled.div`
  display: grid;
  font-weight: normal;
  padding: 1rem;
`

const BlockWrap = styled.div`
  display: grid;
  grid-gap: 0.5rem;
`

const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  outline: none;
`

const DateClickModal = React.memo(function DateClickModal ({
  open,
  close,
  setComment,
  setAppointment,
  setRecall,
  setBlock
}) {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <StyledModalBox>
        <Container>
          <BlockWrap>
            <Button
              onClick={setAppointment}
              variant='contained'
              color='primary'
              startIcon={<Event />}
              style={{ background: colors.primColor }}
            >
              Create Appointment
            </Button>
            <Button
              variant='contained'
              style={{
                background: colors.warning,
                color: colors.white,
                borderColor: colors.warning
              }}
              startIcon={<AllInclusive />}
              onClick={setRecall}
            >
              Create Recall
            </Button>
            <Button
              onClick={setBlock}
              variant='contained'
              color='primary'
              startIcon={<Block />}
              style={{ background: colors.rgbGreyLight, color: colors.dark }}
            >
              Block Out
            </Button>
            <Button
              onClick={setComment}
              variant='contained'
              color='primary'
              startIcon={<Comment />}
              style={{ background: colors.yellow, color: colors.dark }}
            >
              Create Event
            </Button>
          </BlockWrap>
        </Container>
      </StyledModalBox>
    </Modal>
  )
})

export default DateClickModal
