import React, { useEffect, useState, useMemo } from 'react'
import StandardLayout from '../../../../layouts/standard'
import { chargePatient } from './actions'
import {
  fetchIncomeReport,
  fetchPatientIncomeReport,
  fetchPatientTreatmentReport
} from '../../../../actions/reports'
import { Button, MenuItem, Select } from '@mui/material'
import Paper from '@mui/material/Paper'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  FileDownload as FileDownloadIcon
} from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { PopBottom, BlockWrap } from '../../../../components/common'
import colors from '../../../../library/styled-components/colors'
import ChargeForm from './components/ChargeForm'
import DoubleBounce from '../../../../components/loaders/double-bounce'
import { AppContext } from 'hooks/context'
import { useContext } from 'react'
import { ADMIN_NS } from 'config'
import MultiSelectPopover from './components/MultiSelectPopover'
import {
  endOfMonth,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears
} from 'date-fns'
import Accounts from 'components/accounts'

const TableWrapper = styled.div`
  height: calc(100vh - 16rem);
  overflow-y: auto;
  thead th {
    background-color: ${colors.dark};
    color: ${colors.white};
  }
`
const DateWrap = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  input {
    background: white;
  }
`

const initialDates = {
  start: format(subMonths(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
  end: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
}

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ReportTableRow = styled(TableRow)`
  position: ${props =>
    Object.values(props.row)[0] === null ? 'sticky' : 'inherit'};
  bottom: ${props => (Object.values(props.row)[0] === null ? '0' : 'inherit')};
  font-weight: ${props =>
    Object.values(props.row)[0] === null ? 'bold' : 'normal'};
  background: ${props =>
    props.i === Object.values(props.row).length - 1
      ? Object.values(props.row)[0] > 0
        ? props.colors.softRed
        : props.colors.softGreen
      : 'inherit'};
  border-top: ${props =>
    Object.values(props.row)[0] === null ? '1px solid #e0e0e0' : 'none'};
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  background: ${props =>
    Object.values(props.row)[0] === null ? colors.lightGrey : 'inherit'};
  &:hover {
    background: ${props =>
      Object.values(props.row)[0] === null
        ? colors.lightGrey
        : colors.accentLight};
  }
`
const paymentMethods = [
  { key: 1, name: 'Cash', value: 'CASH' },
  { key: 2, name: 'Credit Card', value: 'CREDIT' },
  { key: 3, name: 'Debit Card', value: 'DEBIT' },
  { key: 4, name: 'Invoice', value: 'INVOICE' },
  { key: 5, name: 'Practice Plan', value: 'PLAN' },
  { key: 6, name: 'Cheque', value: 'CHEQUE' },
  { key: 7, name: 'Refund', value: 'REFUND' }
]

const DownloadExcelButton = ({
  start,
  end,
  practitionerId,
  selectedMethods,
  selectedTreatments,
  reportType = 0
}) => {
  const handleDownload = async () => {
    try {
      if (reportType === 0) {
        // Calling the function to fetch and download the report as an Excel file
        await fetchIncomeReport(ADMIN_NS, start, end, selectedMethods, 'excel')
      } else if (reportType === 1) {
        // Calling the function to fetch and download the report as an Excel file
        await fetchPatientIncomeReport(
          ADMIN_NS,
          start,
          end,
          practitionerId,
          selectedMethods,
          'excel'
        )
      } else if (reportType === 2) {
        // Calling the function to fetch and download the report as an Excel file
        await fetchPatientTreatmentReport(
          ADMIN_NS,
          start,
          end,
          selectedTreatments,
          'excel'
        )
      }
    } catch (error) {
      console.error('Error downloading the Excel file:', error)
      // Optionally, handle the error in the UI, e.g., with a notification or message to the user
    }
  }

  return (
    <Button
      variant='contained'
      color='success'
      startIcon={<FileDownloadIcon />}
      onClick={handleDownload}
      sx={{ mt: 2 }} // Add margin top for spacing, adjust as needed
    >
      Download Excel
    </Button>
  )
}

export default function AdminCashbook ({ user }) {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [reportType, setReportType] = useState(0)
  const [overlay, setOverlay] = useState(false)
  const [practitionerId, setPractitionerId] = useState(null)
  const [lastMonth, setLastMonth] = useState(true)
  const [lastYear, setLastYear] = useState(false)
  const [yearToDate, setYearToDate] = useState(false)
  const [chargeError, setChargeError] = useState(null)
  const [reportData, setReportData] = useState([])
  const [patientId, setPatientId] = useState(false)
  const [open, setOpen] = useState({})
  const [selectedMethods, setSelectedMethods] = useState(
    paymentMethods.map(method => method.value)
  )
  const [selectedTreatments, setSelectedTreatments] = useState([])

  const patient = state?.patients?.data?.find(
    p => p.data?.PatientId === patientId
  )?.data

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    initialDates.start
  )
  const [selectedEndDate, setSelectedEndDate] = React.useState(initialDates.end)
  const { list, treatments, patients } = state

  async function loadData (
    start,
    end,
    practitionerId = null,
    methods = selectedMethods,
    treatments = selectedTreatments
  ) {
    await setLoading(true)
    if (reportType === 0) {
      await fetchPractitionerIncomeData(start, end, methods)
    } else if (reportType === 1) {
      await fetchPatientIncomeData(start, end, practitionerId, methods)
    } else if (reportType === 2) {
      await fetchPatientTreatmentData(start, end, treatments)
    }
    await setLoading(false)
  }

  async function fetchPatientIncomeData (start, end, practitionerId, methods) {
    await setLoading(true)
    setReportData([])

    const reportData = await fetchPatientIncomeReport(
      ADMIN_NS,
      start,
      end,
      practitionerId,
      methods
    )
    setReportData(reportData)

    await setLoading(false)
  }

  async function fetchPractitionerIncomeData (start, end, methods) {
    await setLoading(true)
    setReportData([])
    const reportData = await fetchIncomeReport(ADMIN_NS, start, end, methods)
    setReportData(reportData)
    await setLoading(false)
  }

  async function fetchPatientTreatmentData (start, end, treatments) {
    await setLoading(true)
    setReportData([])
    const reportData = await fetchPatientTreatmentReport(
      ADMIN_NS,
      start,
      end,
      treatments
    )
    setReportData(reportData)
    await setLoading(false)
  }

  async function handleToggle (id) {
    setOpen({ ...open, [id]: !open[id] })
  }

  useEffect(() => {
    const defatultTreatments = [treatments?.data[69]?.data?.TreatmentId]
    setSelectedTreatments(defatultTreatments)
    loadData(
      initialDates.start,
      initialDates.end,
      practitionerId,
      selectedMethods,
      defatultTreatments
    )
  }, [treatments])

  const filteredReport = useMemo(() => reportData?.data, [reportData])

  // Aggregate totals first
  // First, filter and aggregate totals
  const groupedData = filteredReport
    ?.filter(row => row.Period === 'Total')
    .map(total => ({ ...total, months: [] }))

  // Then, add monthly data to the corresponding totals
  filteredReport?.forEach(row => {
    if (row.Period !== 'Total') {
      // Get the first element key dynamically
      const firstElementKey = Object.keys(row)[0]

      const practitionerTotal = groupedData?.find(
        total => total[firstElementKey] === row[firstElementKey]
      )
      if (practitionerTotal) {
        practitionerTotal.months.push(row)
      }
    }
  })

  return (
    <StandardLayout
      menu={true}
      user={user}
      nosync={true}
      meta={{ updating: false }}
      smsActive={false}
    >
      <div
        style={{
          margin: '1rem'
        }}
      >
        <SearchContainer>
          <div>
            <h2>Practice Reports</h2>
            <p>Generate reports for your practice here</p>

            <div
              style={{
                display: 'flex',
                gap: '1rem',
                margin: '1rem 0rem'
              }}
            >
              <div>
                <Select
                  value={reportType}
                  style={{ background: 'white', width: '100%' }}
                  onChange={e => {
                    setReportType(e.target.value)
                    setPractitionerId(list.data[0].data.PractitionerId)
                    if (e.target.value === 0) {
                      fetchPractitionerIncomeData(
                        selectedStartDate,
                        selectedEndDate,
                        selectedMethods
                      )
                    }
                    if (e.target.value === 1) {
                      fetchPatientIncomeData(
                        selectedStartDate,
                        selectedEndDate,
                        list.data[0].data.PractitionerId,
                        selectedMethods
                      )
                    }
                    if (e.target.value === 2) {
                      fetchPatientTreatmentData(
                        selectedStartDate,
                        selectedEndDate,
                        selectedTreatments
                      )
                    }
                  }}
                >
                  <MenuItem value={0}>Practice Fee Income Report</MenuItem>
                  <MenuItem value={1}>Practitioner Fee Income Report</MenuItem>
                  <MenuItem value={2}>Patient Treatment Report</MenuItem>
                </Select>
              </div>
              {reportType === 1 ? (
                <div>
                  <Select
                    style={{ background: 'white', width: '100%' }}
                    value={practitionerId}
                    onChange={e => {
                      setPractitionerId(e.target.value)
                      if (e.target.value !== null) {
                        fetchPatientIncomeData(
                          selectedStartDate,
                          selectedEndDate,
                          e.target.value,
                          selectedMethods
                        )
                      }
                    }}
                  >
                    {list.data.map(p => (
                      <MenuItem
                        key={p.data.PractitionerId}
                        value={p.data.PractitionerId}
                      >
                        {p.data.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : (
                ''
              )}
              {reportType === 1 || reportType === 0 ? (
                <MultiSelectPopover
                  title='Payment Methods'
                  items={paymentMethods}
                  selectedItems={selectedMethods}
                  setSelectedItems={methods => {
                    setSelectedMethods(methods)
                    if (reportType === 0) {
                      fetchPractitionerIncomeData(
                        selectedStartDate,
                        selectedEndDate,
                        methods
                      )
                    }
                    if (reportType === 1) {
                      fetchPatientIncomeData(
                        selectedStartDate,
                        selectedEndDate,
                        practitionerId,
                        methods
                      )
                    }
                  }}
                />
              ) : (
                ''
              )}

              {reportType === 2 ? (
                <MultiSelectPopover
                  title='Treatments'
                  items={treatments?.data?.map(t => ({
                    key: t.data.TreatmentId,
                    name: t.data.Name,
                    value: t.data.TreatmentId
                  }))}
                  selectedItems={selectedTreatments}
                  setSelectedItems={treatments => {
                    setSelectedTreatments(treatments)
                    fetchPatientTreatmentData(
                      selectedStartDate,
                      selectedEndDate,
                      treatments
                    )
                  }}
                />
              ) : (
                ''
              )}

              <DownloadExcelButton
                start={selectedStartDate}
                end={selectedEndDate}
                practitionerId={practitionerId}
                selectedMethods={selectedMethods}
                selectedTreatments={selectedTreatments}
                reportType={reportType}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'flex-end'
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateWrap>
                  <DatePicker
                    autoOk
                    variant='inline'
                    inputVariant='outlined'
                    label='Select from date'
                    format='MM/dd/yyyy'
                    value={new Date(selectedStartDate)}
                    onChange={date => {
                      const newStartDate = format(
                        new Date(date),
                        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                      )
                      setSelectedStartDate(newStartDate)
                      loadData(
                        newStartDate,
                        selectedEndDate,
                        practitionerId,
                        selectedMethods
                      )
                    }}
                  />
                </DateWrap>
                <DateWrap>
                  <DatePicker
                    autoOk
                    variant='inline'
                    inputVariant='outlined'
                    label='Select to date'
                    format='MM/dd/yyyy'
                    value={new Date(selectedEndDate)}
                    onChange={date => {
                      const newEndDate = format(
                        new Date(date),
                        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                      )
                      setSelectedEndDate(newEndDate)
                      loadData(
                        selectedStartDate,
                        newEndDate,
                        practitionerId,
                        selectedMethods
                      )
                    }}
                  />
                </DateWrap>
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'flex-end'
              }}
            >
              <div>
                <Button
                  variant={lastMonth ? 'outlined' : 'contained'}
                  size='small'
                  color='primary'
                  onClick={() => {
                    if (lastMonth) {
                      return
                    }
                    setLastMonth(true)
                    setLastYear(false)
                    setYearToDate(false)

                    const newStartDate = format(
                      startOfMonth(subMonths(new Date(), 1)),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                    setSelectedStartDate(newStartDate)
                    const newEndDate = format(
                      endOfMonth(new Date()),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                    setSelectedEndDate(newEndDate)
                    loadData(
                      newStartDate,
                      newEndDate,
                      practitionerId,
                      selectedMethods
                    )
                  }}
                >
                  last month
                </Button>
              </div>
              <div>
                <Button
                  variant={lastYear ? 'outlined' : 'contained'}
                  size='small'
                  color='primary'
                  onClick={() => {
                    if (lastYear) {
                      return
                    }
                    setLastYear(true)
                    setLastMonth(false)
                    setYearToDate(false)
                    // set the dates from the begining the end of January
                    const newStartDate = format(
                      subYears(endOfMonth(startOfYear(new Date(), 1)), 1),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                    setSelectedStartDate(newStartDate)
                    const newEndDate = format(
                      endOfMonth(startOfYear(new Date(), 1)),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                    setSelectedEndDate(newEndDate)
                    loadData(
                      newStartDate,
                      newEndDate,
                      practitionerId,
                      selectedMethods
                    )
                  }}
                >
                  last financial year
                </Button>
              </div>
              <div>
                <Button
                  variant={yearToDate ? 'outlined' : 'contained'}
                  size='small'
                  color='primary'
                  onClick={() => {
                    if (yearToDate) {
                      return
                    }
                    setYearToDate(true)
                    setLastYear(false)
                    setLastMonth(false)

                    const newStartDate = format(
                      startOfYear(new Date()),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                    setSelectedStartDate(newStartDate)
                    const newEndDate = format(
                      new Date(),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                    setSelectedEndDate(newEndDate)
                    loadData(
                      newStartDate,
                      newEndDate,
                      practitionerId,
                      selectedMethods
                    )
                  }}
                >
                  year to date
                </Button>
              </div>
            </div>
          </div>
        </SearchContainer>

        {loading && <DoubleBounce color={colors.primary} margin='' />}
        {groupedData?.length > 0 ? (
          <TableWrapper>
            <Paper>
              <Table stickyHeader aria-label='spanning table'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.keys(groupedData[0])
                      .slice(1)
                      .filter(key => key !== 'months') // Exclude 'months' from the row cells
                      .map((v, i) => (
                        <TableCell key={i} align='left'>
                          {v}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedData &&
                    groupedData.map((row, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        <ReportTableRow
                          row={row}
                          onClick={() => {
                            if (reportType === 0) {
                              const practitionerId = row.PractitionerId
                              setReportType(1)
                              setPractitionerId(practitionerId)
                              fetchPatientIncomeData(
                                selectedStartDate,
                                selectedEndDate,
                                practitionerId,
                                selectedMethods
                              )
                            } else {
                              const patientId = row.PatientId
                              setPatientId(patientId)
                            }
                          }}
                        >
                          <TableCell>
                            <IconButton
                              size='small'
                              onClick={e => {
                                e.stopPropagation()
                                handleToggle(rowIndex)
                              }}
                            >
                              {open[rowIndex] ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                          </TableCell>
                          {Object.entries(row)
                            .slice(1)
                            .filter(([key]) => key !== 'months') // Exclude 'months' from the row cells
                            .map(([key, v], i) => (
                              <TableCell
                                align='left'
                                key={i}
                                style={{
                                  fontWeight:
                                    row.Month === 'Total' ? 'bold' : 'normal',
                                  background:
                                    i === Object.entries(row).length - 3
                                      ? v > 0
                                        ? colors.softRed
                                        : colors.softGreen
                                      : 'inherit',
                                  borderTop:
                                    row.Month === 'Total'
                                      ? '1px solid #e0e0e0'
                                      : 'none'
                                }}
                              >
                                {v === null
                                  ? 'Total'
                                  : !isNaN(v)
                                  ? new Intl.NumberFormat('en-IE', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    }).format(Math.abs(v))
                                  : v}
                              </TableCell>
                            ))}
                        </ReportTableRow>
                        {row.months &&
                          row.months.length > 0 &&
                          row.months.map((monthRow, monthIndex) => (
                            <ReportTableRow
                              key={monthIndex}
                              row={monthRow}
                              i={monthIndex}
                              colors={colors}
                              sx={{
                                '& > *': {
                                  borderBottom: 'unset'
                                },
                                display: open[rowIndex] ? 'table-row' : 'none'
                              }}
                              onClick={() => {
                                if (reportType === 0) {
                                  const practitionerId = monthRow.PractitionerId
                                  setReportType(1)
                                  setPractitionerId(practitionerId)
                                  fetchPatientIncomeData(
                                    selectedStartDate,
                                    selectedEndDate,
                                    practitionerId,
                                    selectedMethods
                                  )
                                } else {
                                  const patientId = monthRow.PatientId
                                  setPatientId(patientId)
                                }
                              }}
                            >
                              <TableCell></TableCell>
                              {Object.entries(monthRow)
                                .slice(1)
                                .filter(([key]) => key !== 'months') // Exclude 'months' from the row cells
                                .map(([key, v], i) => (
                                  <TableCell
                                    align='left'
                                    key={i}
                                    style={{
                                      fontWeight:
                                        monthRow.Month === 'Total'
                                          ? 'bold'
                                          : 'normal',
                                      background:
                                        i ===
                                        Object.entries(monthRow).length - 2
                                          ? v > 0
                                            ? colors.softRed
                                            : colors.softGreen
                                          : 'inherit',
                                      borderTop:
                                        monthRow.Month === 'Total'
                                          ? '1px solid #e0e0e0'
                                          : 'none'
                                    }}
                                  >
                                    {v === null
                                      ? 'Total'
                                      : !isNaN(v)
                                      ? new Intl.NumberFormat('en-IE', {
                                          style: 'currency',
                                          currency: 'EUR'
                                        }).format(Math.abs(v))
                                      : v}
                                  </TableCell>
                                ))}
                            </ReportTableRow>
                          ))}
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </TableWrapper>
        ) : (
          <div>The report is empty for the selected period</div>
        )}

        {overlay && (
          <PopBottom bottom='6rem' right='2rem'>
            <BlockWrap>
              <ChargeForm
                patients={patients}
                practitioners={list}
                treatments={treatments}
                error={chargeError}
                createCharge={data => {
                  const { amount, practitionerId, proceedure, patientId } = data
                  const check =
                    amount && practitionerId && proceedure && patientId
                  setChargeError(null)
                  if (check) {
                    chargePatient({
                      Amount: { val: amount },
                      PractitionerId: { val: practitionerId },
                      TreatmentId: { val: proceedure.TreatmentId },
                      Price: { val: proceedure.Price },
                      Data: { val: { quantity: 1, status: 2 } },
                      PatientId: { val: patientId },
                      Currency: { val: 'EUR' }
                    })(dispatch)
                    setOverlay(false)
                  } else {
                    setChargeError('Missing data')
                  }
                }}
                cancelMethod={() => {
                  setOverlay(false)
                  setChargeError(null)
                }}
              />
            </BlockWrap>
          </PopBottom>
        )}
      </div>

      {patientId && patient ? (
        <Accounts
          patient={{
            ...patient,
            User: patient
          }}
          show={patientId}
          close={() => {
            setPatientId(false)
          }}
        ></Accounts>
      ) : (
        ''
      )}
    </StandardLayout>
  )
}
