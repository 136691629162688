import React, { useState, useMemo, useEffect } from "react";
import SearchInput from "../../components/search-input";
import DoubleBounce from "../loaders/double-bounce";
import styled, { css } from "styled-components";
import colors from "../../library/styled-components/colors";
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import memoize from "memoize-one";
import { List } from "@mui/material";
import { format, isValid } from "date-fns";

export const Panel = styled.div`
  display: grid;
`;

const ListWrap = styled.div`
  z-index: 101;
  border-left: 1px solid ${colors.grey};
  border-right: 1px solid ${colors.grey};
  border-bottom: 1px solid ${colors.grey};
  overflow: auto;
  max-height: 14rem;
`;

const Row = ({ item, selectedUser, setSelectedUser }) => {
  return (
    <div style={{ listStyle: "none" }}>
      <Divider component="li" />

      <ListItem
        component="nav"
        button
        onClick={() => setSelectedUser(item)}
        selected={selectedUser && selectedUser.UserId === item.UserId}
        alignitems="flex-start"
      >
        <ListItemAvatar>
          <Avatar alt={item.Name}>
            {item.Name?.split(" ")   
              .map((i) => i.charAt(0).toUpperCase())
              .slice(0, 2)}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={
            <Badge
              style={{ width: "100%" }}
              badgeContent={
                item.SmsMessages?.length > 0 &&
                item.SmsMessages.filter((m) => m.TypeId === 4 && !m.ReadUserId)
                  .length > 0
                  ? ""
                  : null
              }
              color="secondary"
            >
              <Typography component="div">{item.Name}</Typography>
            </Badge>
          }
          secondary={
            <React.Fragment>
              Phone: {item?.Phone}
              <br />
              {item?.Email ? (
                <span>
                  Email: {item?.Email} <br />
                </span>
              ) : null}
              {item?.DateOfBirth && isValid(new Date(item?.DateOfBirth))
                ? `DoB: ${format(new Date(item?.DateOfBirth), "dd/MM/yyyy")}`
                : "No DoB"}{" "}
              <br />
            </React.Fragment>
          }
        />
      </ListItem>
    </div>
  );
};

const DropdownFilter = ({
  onFocus,
  open,
  placeholder = "Search ...",
  options,
  selectedUser,
  loading,
  setSelectedUser,
  defaultSearch,
}) => {
  const [search, setSearch] = useState("");
  const list = useMemo(
    () =>
      search &&
      options &&
      options
        .filter((item) => {
          const str = item?.Name?.toLowerCase() + " " + item?.Phone?.toLowerCase();
          return str && str.search(search?.toLowerCase()) >= 0;
        })
        .slice(0, 20)
        .sort(function (a, b) {
          var c =
            a.SmsMessages?.length > 0
              ? new Date(a.SmsMessages[a.SmsMessages?.length - 1]?.updatedAt)
              : new Date(1970, 11, 26, 0, 0, 0, 0);
          var d =
            b.SmsMessages?.length > 0
              ? new Date(b.SmsMessages[b.SmsMessages?.length - 1]?.updatedAt)
              : new Date(1970, 11, 26, 0, 0, 0, 0);
          return d - c;
        }),
    [options, search]
  );

  useEffect(() => setSearch(defaultSearch), [defaultSearch]);

  return (
    <Panel>
      <SearchInput
        autoFocus={!selectedUser}
        search={search}
        placeholder={placeholder}
        setSearch={setSearch}
        onFocus={onFocus}
      />
      {loading && <DoubleBounce color={colors.primary} margin="20%" />}
      {open && list && (
        <ListWrap>
          <List>
            {list.map((item) => (
              <Row
                key={item.PatientId}
                item={item}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            ))}
          </List>
        </ListWrap>
      )}
    </Panel>
  );
};

export default DropdownFilter;
