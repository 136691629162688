import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import colors from 'library/styled-components/colors'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import { ConsultationAction } from 'layouts/lib/common'
import SearchInput from 'components/search-input'
import { Button, Checkbox, Stack, Tooltip, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { format, subMonths } from 'date-fns'
import {
  AllInclusive as AllInclusiveIcon,
  Close as CloseIcon,
  CreditCard as CreditCardIcon,
  Event as EventIcon,
  History as HistoryIcon
} from '@mui/icons-material'
import { DateWrap } from 'components/common'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { Alert } from '@mui/lab'
import RecallWizard from '../RecallWizard'

const Container = styled.div`
  @media (max-width: 481px) {
    width: 70vw;
  }
  max-width: 96vw;
  text-align: left;
  .Mui-selected {
    background: red;
  }
  ${props =>
    props.pointer &&
    css`
      tr {
        cursor: pointer;
      }
    `}
`

const ActionsWrapper = styled.div`
  display: flex;
  ${ConsultationAction} {
    background: ${props => (props.active ? colors.primary : colors.white)};
    color: ${props => (props.active ? colors.white : colors.primary)};
    border: 1px solid ${colors.primary};
  }
`

const rowsPerPageOptions = [10, 50, 100]

const AccentCheckbox = withStyles({
  root: {
    color: colors.altLightColor,
    '&$checked': {
      color: colors.altColor
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />)

const DataTable = ({
  schema,
  actions,
  list,
  uuid,
  data,
  addItem = null,
  clickable = false,
  navigateTo,
  showAccounts,
  setShowRecallHistory
}) => {
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [selected, setSelected] = React.useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [indeterminateSelected, setIndeterminateSelected] = useState(false)
  const [sendShowRecall, setSendShowRecall] = useState(false)
  const [startCampaign, setStartCampaign] = useState(false)

  const [selectedStartDate, setSelectedStartDate] = useState(
    subMonths(new Date(), 7)
  )
  const [selectedEndDate, setSelectedEndDate] = useState(
    subMonths(new Date(), 6)
  )

  const handleSelectAllClick = event => {
    setAllSelected(false)
    setIndeterminateSelected(false)
    let newSelecteds = []
    if (event.target.checked) {
      newSelecteds = filteredList.map(n => n.data.id)
    }
    if (
      filteredList.length > 0 &&
      newSelecteds.length === filteredList.length
    ) {
      setAllSelected(
        filteredList.length > 0 && newSelecteds.length === filteredList.length
      )
    } else if (selected.length > 0 && selected.length < filteredList.length) {
      setIndeterminateSelected(true)
    }
    return setSelected(newSelecteds)
  }

  const selectAllFiltered = (startDate, endDate) => {
    setAllSelected(true)
    setSelected(
      list
        .filter(p =>
          p.data?.Appointment
            ? new Date(p.data.Appointment.Start) > startDate &&
              new Date(p.data.Appointment.Start) < endDate
            : null
        )
        .map(p => p.data.id)
    )
  }

  const handleClick = id => {
    // Use callback form of setState to ensure we have the latest state
    setSelected(prevSelected => {
      const selectedIndex = prevSelected.indexOf(id)
      let newSelecteds = []

      if (selectedIndex === -1) {
        newSelecteds = [...prevSelected, id]
      } else {
        newSelecteds = [
          ...prevSelected.slice(0, selectedIndex),
          ...prevSelected.slice(selectedIndex + 1)
        ]
      }

      // Update other states based on the new selection
      // This code assumes filteredList is available in the scope
      if (
        filteredList.length > 0 &&
        newSelecteds.length === filteredList.length
      ) {
        setAllSelected(true)
      } else if (
        newSelecteds.length > 0 &&
        newSelecteds.length < filteredList.length
      ) {
        setIndeterminateSelected(true)
      } else {
        // Resetting other states if needed
        setAllSelected(false)
        setIndeterminateSelected(false)
      }
      // Return the new selection state
      return newSelecteds
    })
  }

  const isSelected = (name, selected) => selected.indexOf(name) !== -1

  const filteredList = startCampaign
    ? list.filter(p => {
        return p.data?.Appointment
          ? new Date(p.data.Appointment.Start) > selectedStartDate &&
              new Date(p.data.Appointment.Start) < selectedEndDate
          : null
      })
    : list

  return (
    <Container pointer={clickable}>
      <Paper>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {startCampaign ? (
              <Stack spacing={2} direction='row'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateWrap>
                    <DatePicker
                      autoOk
                      variant='inline'
                      inputVariant='outlined'
                      label='Filter start date'
                      defaultValue={new Date()}
                      value={selectedStartDate}
                      inputAdornmentProps={{ position: 'start' }}
                      onChange={date => {
                        setSelectedStartDate(date)
                        selectAllFiltered(date, selectedEndDate)
                      }}
                    />
                  </DateWrap>
                  <DateWrap>
                    <DatePicker
                      autoOk
                      variant='inline'
                      inputVariant='outlined'
                      label='Filter end date'
                      defaultValue={new Date()}
                      value={selectedEndDate}
                      inputAdornmentProps={{ position: 'start' }}
                      onChange={date => {
                        setSelectedEndDate(date)
                        selectAllFiltered(selectedStartDate, date)
                      }}
                    />
                  </DateWrap>
                </LocalizationProvider>
                <Alert
                  style={{
                    padding: '0.5rem'
                  }}
                  severity='info'
                >
                  <strong>{selected.length || 0} </strong> Patients last seen
                  between{' '}
                  <strong>{format(selectedStartDate, 'MMM d, y')} </strong> and{' '}
                  <strong>{format(selectedEndDate, 'MMM d, y')} </strong>
                </Alert>
                <Button
                  size='small'
                  style={{
                    background: colors.accentColor,
                    color: colors.white,
                    borderColor: colors.accentColor,
                    alignSelf: 'center',
                    height: '2.5rem'
                  }}
                  variant='contained'
                  disabled={selected.length === 0}
                  startIcon={<EventIcon />}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setSendShowRecall(true)
                  }}
                >
                  Shedule Recall
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  style={{
                    alignSelf: 'center',
                    height: '2.5rem'
                  }}
                  startIcon={<CloseIcon />}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setStartCampaign(false)
                    setSelected([])
                    setAllSelected(false)
                  }}
                >
                  Clear
                </Button>

                <RecallWizard
                  open={sendShowRecall}
                  close={() => setSendShowRecall(false)}
                  complete={() => {
                    setStartCampaign(false)
                    setSelected([])
                    setAllSelected(false)
                    setSendShowRecall(false)
                    setShowRecallHistory(true)
                  }}
                  filteredList={filteredList.filter(p => {
                    return selected.includes(p.data.id)
                  })}
                />
              </Stack>
            ) : (
              <div
                style={{
                  display: 'flex'
                }}
              >
                <Button
                  variant='outlined'
                  size='small'
                  style={{
                    background: colors.accentColor,
                    color: colors.white,
                    borderColor: colors.accentColor,
                    justifySelf: 'center'
                  }}
                  startIcon={<AllInclusiveIcon />}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    selectAllFiltered(selectedStartDate, selectedEndDate)
                    setStartCampaign(true)
                  }}
                >
                  Create SMS Recall
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  style={{
                    justifySelf: 'center',
                    background: colors.white,
                    color: colors.accentColor,
                    borderColor: colors.accentColor,
                    marginLeft: '1rem'
                  }}
                  startIcon={<HistoryIcon />}
                  onClick={e => {
                    setShowRecallHistory(true)
                  }}
                >
                  Show Recall History
                </Button>
              </div>
            )}
          </Typography>
          <Tooltip title='Filter list'>
            <div
              style={{
                alignSelf: 'end',
                justifySelf: 'end',
                marginBottom: '1rem'
              }}
            >
              <SearchInput
                search={search}
                setSearch={setSearch}
                placeholder={data?.placeholder}
              />
            </div>
          </Tooltip>
        </Toolbar>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {startCampaign ? (
                  <TableCell
                    style={{
                      paddingLeft: '12px',
                      background: colors.lightGrey
                    }}
                  >
                    <AccentCheckbox
                      style={{
                        padding: '0'
                      }}
                      indeterminate={indeterminateSelected}
                      checked={allSelected}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                ) : null}
                {schema.content.map(header => (
                  <TableCell
                    style={{
                      background:
                        ['Last', 'Start'].includes(header.key) &&
                        selected.length > 0
                          ? colors.accentColor
                          : colors.lightGrey
                    }}
                    key={header.key}
                  >
                    {header.label}
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    background: colors.lightGrey
                  }}
                >
                  Balance
                </TableCell>
                {actions && <TableCell></TableCell>}
                <TableCell style={{ background: colors.lightGrey }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList
                .map(patient => ({
                  ...patient,
                  data: {
                    ...patient.data,
                    Last:
                      patient.data.Appointment &&
                      new Date() > new Date(patient.data.Appointment.Start)
                        ? format(
                            new Date(patient.data.Appointment.Start),
                            'MMM d, y'
                          )
                        : null,
                    Start:
                      patient.data.Appointment &&
                      new Date() < new Date(patient.data.Appointment.Start)
                        ? format(
                            new Date(patient.data.Appointment.Start),
                            'MMM d, y'
                          )
                        : null
                  }
                }))
                .filter(row => {
                  const str = schema.content
                    .map(c => row.data[c.key])
                    .join()
                    .toLowerCase()
                  return str && str.search(search.toLowerCase()) >= 0
                })
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map(row => {
                  const isItemSelected = isSelected(row.data.id, selected)
                  return (
                    <TableRow
                      hover
                      style={{
                        background: row.data.disabled ? colors.lightBlue : ''
                      }}
                      selected={uuid === row.data[schema.link.id]}
                      key={row.data[schema.link.id]}
                      onClick={() => clickable && navigateTo(row.data)}
                    >
                      {startCampaign ? (
                        <TableCell padding='checkbox'>
                          <AccentCheckbox
                            checked={isItemSelected}
                            onClick={e => {
                              e.stopPropagation()
                              handleClick(row.data.id)
                            }}
                          />
                        </TableCell>
                      ) : null}

                      {schema.content.map(c => (
                        <TableCell
                          style={{
                            background:
                              ['Last', 'Start'].includes(c.key) &&
                              selected.length > 0
                                ? colors.accentLight
                                : colors.white
                          }}
                          key={c.key}
                        >
                          {row.data[c.key]}
                        </TableCell>
                      ))}
                      {actions && (
                        <TableCell id={row.data[schema.link.id]}>
                          <ActionsWrapper
                            id={row.data[schema.link.id]}
                            active={
                              schema.active &&
                              row.data[schema.active.key] &&
                              row.data[schema.active.key].find(
                                a => a && a[schema.active.find] === true
                              )
                            }
                          >
                            {actions}
                          </ActionsWrapper>
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          background: colors.white
                        }}
                      >
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                                color='inherit'
                              >
                                <span>Total Charges: &nbsp;</span>
                                <span style={{ alignSelf: 'flex-end' }}>
                                  {new Intl.NumberFormat('en-IE', {
                                    style: 'currency',
                                    currency: 'EUR'
                                  }).format(
                                    !isNaN(
                                      row.data['Outstanding']?.TotalCharges
                                    )
                                      ? row.data['Outstanding']?.TotalCharges
                                      : 0
                                  )}
                                </span>
                              </Typography>

                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                                color='inherit'
                              >
                                <span>Total Paid: &nbsp;</span>
                                <span style={{ alignSelf: 'flex-end' }}>
                                  {new Intl.NumberFormat('en-IE', {
                                    style: 'currency',
                                    currency: 'EUR'
                                  }).format(
                                    !isNaN(row.data['Outstanding']?.TotalPaid)
                                      ? row.data['Outstanding']?.TotalPaid
                                      : 0
                                  )}
                                </span>
                              </Typography>

                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                                color='inherit'
                              >
                                <span>Total Owed: &nbsp;</span>
                                {new Intl.NumberFormat('en-IE', {
                                  style: 'currency',
                                  currency: 'EUR'
                                }).format(
                                  !isNaN(row.data['Outstanding']?.TotalOwed)
                                    ? row.data['Outstanding']?.TotalOwed
                                    : 0
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <CreditCardIcon
                            style={{
                              cursor: 'pointer',
                              color:
                                row.data['Outstanding']?.TotalOwed > 0
                                  ? 'red'
                                  : 'green'
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          background: colors.white
                        }}
                      >
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            showAccounts(row.data)
                          }}
                          startIcon={<AccountBalanceIcon size='small' />}
                        >
                          Accounts
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredList.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={e =>
              setRowsPerPage(parseInt(e.target.value, 10))
            }
          />
        )}
      </Paper>
    </Container>
  )
}

export default DataTable
