import React from 'react'
import StandardLayout from 'layouts/standard'
import SplitCalendarComponent from './components/SplitCalendarComponent'
import styled from 'styled-components'

const WrapCal = styled.div`
  display: grid;
  position: relative !important;
`
export default function HomeCalendar ({ user }) {
  return (
    <StandardLayout menu={true} meta={{ updating: false }} user={user}>
      <WrapCal>
        <SplitCalendarComponent />
      </WrapCal>
    </StandardLayout>
  )
}
