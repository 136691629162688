import React, { useState } from "react";
import {
  Switch,
  Route,
  withRouter,
  Redirect,
  useLocation,
} from "react-router-dom";
import store from "store";
// import Home from "./containers/home";
import Chart from "./components/perio-chart";
import Chart2 from "./components/dent-chart";
import Register from "./containers/register";
import AdminLogin from "./containers/admin/login";
import AdminHome from "./containers/admin/home/dashboard";
import AdminPractitioners from "./containers/admin/home/practitioners";
import AdminTreatments from "./containers/admin/home/treatments";
import AdminLogs from "./containers/admin/home/logs";
import AdminPatients from "./containers/admin/home/patients";
import AdminReports from "./containers/admin/home/reports";
import AdminTarget from "./containers/admin/home/target";
import AdminSettings from "./containers/admin/home/settings";
import AdminCalendar from "./containers/admin/home/calendar";
import HomeCalander from "containers/admin/home/calendar/home";
import PublicLogin from "./containers/public/login";
import PublicHome from "./containers/public/home";
import Booking from "./containers/public/booking";
import { ADMIN_NS, PUBLIC_NS } from "./config";
import { AppProvider } from "./hooks/context";
import { initialState, settings } from "./components/perio-chart/settings";

const handleTokenExpiration = (exp) => {
  const now = new Date().getTime();
  const expire = new Date(exp * 1000).getTime();
  const logoutIntervalRef = setInterval(() => {
    location.href = "/#/admin/logout";
  }, expire - now);
  store.set("logoutIntervalRef", logoutIntervalRef);
};

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  const auth = isAuthenticated();
  auth && handleTokenExpiration(auth.data.exp);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth && auth.data ? (
          <Component {...props} user={isAuthenticated()} />
        ) : (
          <Redirect to={rest.redirect} />
        )
      }
    />
  );
}

function DynamicRoute({ component: Component, isAuthenticated, ...rest }) {
  const auth = isAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth && auth.data ? (
          <Component {...props} user={isAuthenticated()} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

function LogoutRoute({ redirect, state }) {
  const location = useLocation();

  // Extract the reason from the query string
  const searchParams = new URLSearchParams(location.search);
  const reason = searchParams.get("reason");

  store.remove(state);
  clearInterval(store.get("logoutIntervalRef"));
  const redirectTo = reason ? `${redirect}/${reason}` : redirect;
  return <Redirect to={redirectTo} />;
}

function isAuthenticated(state) {
  if (!store.get(state)) return false;
  const { user } = store.get(state);
  return user;
}

function NoMatch() {
  let location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const App = () => {
  const auth = isAuthenticated(ADMIN_NS);
  const [teethState, setTeethState] = useState(initialState);
  return (
    <AppProvider practiceId={auth?.data?.PracticeId} token={auth?.data?.token}>
      <Switch>
        <Route
          path="/admin/chart"
          component={() => (
            <div style={{ height: "100vh", overflow: "auto" }}>
              <Chart
                teethState={teethState}
                setTeethState={(data) => {
                  setTimeout(() => setTeethState(data), 0);
                }}
                teethSettings={settings}
              />
            </div>
          )}
        />

        <Route
          path="/admin/chart2"
          component={() => (
            <div style={{ height: "100vh", overflow: "auto" }}>
              <Chart2 />
            </div>
          )}
        />

        <Route exact path="/admin/home">
          <Redirect to="/admin/home/calendar" />
        </Route>

        <Route exact path="/">
          <Redirect to="/admin/home/calendar" />
        </Route>

        <Route path="/admin/access/:step/:id?" component={AdminLogin} />

        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/overview"
          component={AdminHome}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/patients/:id?"
          component={AdminPatients}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/practitioners/:id?"
          component={AdminPractitioners}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/treatments/:id?"
          component={AdminTreatments}
        />

        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/logs"
          component={AdminLogs}
        />

        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/calendarlegacy/:id?"
          component={AdminCalendar}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/calendar/:id?"
          component={HomeCalander}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/reports"
          component={AdminReports}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/target"
          component={AdminTarget}
        />
        <PrivateRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          redirect="/admin/access/login"
          path="/admin/home/settings"
          component={AdminSettings}
        />
        <LogoutRoute
          exact
          state={ADMIN_NS}
          isAuthenticated={() => isAuthenticated(ADMIN_NS)}
          path="/admin/logout/:reason?"
          redirect="/admin/access/login"
        />
        <Route exact path="/admin">
          <Redirect to="/admin/home" />
        </Route>
        <Route path="/admin/*">
          <NoMatch />
        </Route>

        <Route path="/public/access/:step/:id?" component={PublicLogin} />

        <LogoutRoute
          exact
          state={PUBLIC_NS}
          path="/public/logout"
          redirect="/public/access/login"
        />

        <PrivateRoute
          state={PUBLIC_NS}
          isAuthenticated={() => isAuthenticated(PUBLIC_NS)}
          redirect="/public/access/login"
          path="/public/home/:step"
          component={PublicHome}
        />
        <DynamicRoute
          path="/booking/:uuid/:step"
          state={PUBLIC_NS}
          component={Booking}
          isAuthenticated={() => isAuthenticated(PUBLIC_NS)}
        />

        <Route exact path="/public">
          <Redirect to="/public/home/list" />
        </Route>

        <Route exact path="/public/home">
          <Redirect to="/public/home/list" />
        </Route>

        <Route path="/public/*">
          <NoMatch />
        </Route>

        <Route exact path="/register/:uuid" component={Register} />

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </AppProvider>
  );
};

export default withRouter(App);
