import React, { useState } from 'react'
import { Popover, IconButton } from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const StaticDatePickerComponent = ({ selectedDate, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  // Handle click to open or close the Popover
  const handleOpenDatePicker = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  // Close Popover
  const handleCloseDatePicker = () => {
    setAnchorEl(null)
  }

  // Determine if the Popover is open
  const isOpen = Boolean(anchorEl)

  return (
    <div>
      {/* Popover containing the StaticDatePicker */}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseDatePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            value={selectedDate}
            onChange={date => {
              onChange(date)
              handleCloseDatePicker() // Close popover after selecting a date
            }}
          />
        </LocalizationProvider>
      </Popover>

      {/* Icon Button to trigger the calendar popover */}
      <IconButton
        onClick={handleOpenDatePicker}
        sx={{
          position: 'absolute',
          zIndex: 1,  
          color: 'white',
          top: '4px', // Adjust based on your toolbar positioning
          right: '10px' // Adjust based on your toolbar positioning
        }}
      >
        <CalendarTodayIcon />
      </IconButton>
    </div>
  )
}

export default StaticDatePickerComponent
