import React, { useState, useContext, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { AppContext } from '../../../../hooks/context'
import {
  Input,
  InputLabel,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  LinearProgress
} from '@mui/material'
import { format } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Cancel } from '@mui/icons-material'
import { dataStatement, printStatement } from '../../../../library/pdf/invoice'
import colors from '../../../../library/styled-components/colors'
import DoubleBounce from '../../../loaders/double-bounce'
import { Print, Email } from '@mui/icons-material'
import { Alert } from '@mui/lab'
import StatementCharges from './StatementCharges'
import { ADMIN_NS } from 'config'
import axios from 'axios'
import { authFileHeader } from 'library/handler'

export const sendStatementEmail = async data => {
  const form = new FormData()
  form.append('file', data.content)
  return axios
    .post(`${process.env.REACT_APP_APP_API}/payments/statement/send`, form, {
      headers: {
        ...authFileHeader(ADMIN_NS),
        user: JSON.stringify(data.user),
        practice: JSON.stringify(data.practice)
      },
      onUploadProgress: ProgressEvent => {
        console.log((ProgressEvent.loaded / ProgressEvent.total) * 100)
      }
    })
    .then(response => response.data)
    .catch(err => console.log('Fetch Error :-S', err))
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: calc(100vh - 13rem);
`

const LeftPanel = styled.div`
  display: grid;
  grid-template-rows: 11rem auto 5rem;
  flex: 1;
  border: 1px solid ${colors.rgbGreyLight};
  border-radius: 0 0 2px 2px;
`

const DateWrap = styled.div`
  margin: 1rem 1rem 1rem 0rem;
  display: grid;
  background: white;
`

const ListOverFlow = styled.div`
  overflow: auto;
  height: 100%;
`

const TotalsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.rgbGreyLight};
`

const InputContainer = styled.div`
  margin: 1em;
  display: grid;
  position: relative !important;
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
  }
  ${props => css`
    input {
      color: ${colors[props.color]};
    }
  `}
`

const ListWrapper = styled.div`
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  border-top: 1px solid ${colors.rgbGreyLight};
  border-bottom: 1px solid ${colors.rgbGreyLight};
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin-left: 1rem;
  }
`

const CompleteWrap = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin-left: 1rem;
  }
  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${colors.softGreen};
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.softGreen};
  }
`

const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
)

const PatientStatement = ({
  show,
  statementData,
  selectedUser,
  selectedPractice,
  close
}) => {
  const [updating, setUpdating] = useState(null)
  const [reSend, setReSend] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [invoiceSent, setInvoiceSent] = useState(false)
  const [invoiceDelete, setInvoiceDelete] = useState(false)
  const [invoiceFailed, setInvoiceFailed] = useState(false)
  const [invoiceCreate, setInvoiceCreate] = useState(null)

  const defaultFormData = {
    code: null,
    note: '',
    patientId: null,
    Meta: { completed: true },
    proceedures: [],
    payments: [],
    FromDate: new Date(),
    ToDate: new Date(),
    Email: selectedUser?.Email || ''
  }

  const [form, setForm] = useState({
    code: `${format(new Date(), 'yyMdHms')}-${(statementData?.id || 0) + 1}`,
    note: '',
    patientId: null,
    Meta: { completed: true },
    proceedures: [],
    payments: [],
    FromDate: new Date(statementData?.FromDate) || new Date(),
    ToDate: new Date(statementData?.ToDate) || new Date(),
    Email: selectedUser?.Email || ''
  })

  const [invoice, setInvoice] = React.useState()
  const [checkedCharges, setCheckedCharges] = React.useState([])
  const [checkedPayments, setCheckedPayments] = React.useState([])

  useLayoutEffect(() => {
    if (statementData) {
      setInvoice(statementData)
      setForm({
        ...form,
        FromDate: new Date(statementData?.FromDate) || new Date(),
        ToDate: new Date(statementData?.ToDate) || new Date(),
        Email: selectedUser?.Email || ''
      })
    } else {
      setForm({
        code: `${format(new Date(), 'yyMdHms')}-${
          (statementData?.id || 0) + 1
        }`,
        note: '',
        patientId: null,
        Meta: { completed: true },
        proceedures: statementData?.Proceedures || [],
        payments: statementData?.Payments || [],
        FromDate: new Date(),
        ToDate: new Date(),
        Email: selectedUser?.Email || ''
      })
    }
  }, [statementData, show, selectedUser?.Email])

  if (!statementData) return null

  return (
    <Dialog
      onClose={() => {
        setInvoice()
        setInvoiceDelete(false)
        setCheckedCharges([])
        setCheckedPayments([])
        setForm(defaultFormData)
        !updating && close()
      }}
      onClick={e => {
        setCheckedCharges([])
        setCheckedPayments([])
        e.preventDefault()
        e.stopPropagation()
      }}
      aria-labelledby='customized-dialog-title'
      open={show}
      color='primary'
      fullScreen
      maxWidth='lg'
    >
      <div>
        <div>
          <TitleRow>
            <p>Patient Statement</p>
          </TitleRow>
        </div>
        <DialogContent dividers>
          {updating && (
            <div style={{ position: 'absolute', margin: '50%', zIndex: '100' }}>
              <DoubleBounce color={colors.primary} />
            </div>
          )}
          <Wrapper>
            <LeftPanel>
              <div style={{ borderBottom: `1px solid ${colors.rgbGreyLight}` }}>
                <FlexRow>
                  <div
                    style={{
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        marginTop: '1rem',
                        display: 'grid',
                        gridAutoFlow: 'column'
                      }}
                    >
                      <div
                        style={{
                          marginLeft: '1rem'
                        }}
                      >
                        <InputLabel shrink>Patient</InputLabel>
                        <Input
                          id='patient'
                          disabled
                          value={selectedUser?.Name}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '1rem'
                        }}
                      >
                        <InputLabel shrink>Email</InputLabel>
                        <Input
                          id='patient'
                          style={{ width: '100%' }}
                          value={form?.Email || ''}
                          onChange={e =>
                            setForm({ ...form, Email: e.target.value })
                          }
                          error={!form?.Email?.match(emailRegex)}
                        />
                      </div>
                    </div>
                  </div>
                </FlexRow>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end'
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      justifyContent: 'end'
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateWrap>
                        <DatePicker
                          autoOk
                          variant='inline'
                          inputVariant='outlined'
                          label='From date'
                          disabled
                          value={form.FromDate}
                          onChange={date =>
                            setForm({ ...form, FromDate: date })
                          }
                        />
                      </DateWrap>
                      <DateWrap>
                        <DatePicker
                          autoOk
                          variant='inline'
                          inputVariant='outlined'
                          disabled
                          label='To Date'
                          value={form.ToDate}
                          onChange={date => setForm({ ...form, ToDate: date })}
                        />
                      </DateWrap>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              <ListOverFlow>
                {statementData?.invoices?.map((inv, i) => (
                  <StatementCharges invoice={inv} />
                ))}
              </ListOverFlow>

              {statementData?.invoices?.length > 0 && (
                <TotalsWrapper>
                  <InputContainer></InputContainer>
                  <FlexRow>
                    <InputContainer>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          statementData?.invoices &&
                            statementData?.invoices
                              .map(p =>
                                p.Charges.map(c => c.Amount).reduce(
                                  (sum, i) => sum + i,
                                  0
                                )
                              )
                              .reduce((sum, i) => sum + i, 0)
                        )}
                        label='Total Cost'
                        variant='outlined'
                      />
                    </InputContainer>
                    <InputContainer color='green'>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          statementData?.invoices &&
                            statementData?.invoices
                              .map(p =>
                                p.Payments.map(c => c.Amount).reduce(
                                  (sum, i) => sum + i,
                                  0
                                )
                              )
                              .reduce((sum, i) => sum + i, 0)
                        )}
                        label='Total Paid'
                        variant='outlined'
                      />
                    </InputContainer>
                    <InputContainer color='red'>
                      <TextField
                        value={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(
                          statementData?.invoices &&
                            statementData?.invoices
                              .map(p =>
                                p.Charges.map(c => c.Amount).reduce(
                                  (sum, i) => sum + i,
                                  0
                                )
                              )
                              .reduce((sum, i) => sum + i, 0) -
                              statementData?.invoices
                                .map(p =>
                                  p.Payments.map(c => c.Amount).reduce(
                                    (sum, i) => sum + i,
                                    0
                                  )
                                )
                                .reduce((sum, i) => sum + i, 0)
                        )}
                        label='Total Owed'
                        variant='outlined'
                      />
                    </InputContainer>
                  </FlexRow>
                </TotalsWrapper>
              )}
            </LeftPanel>
          </Wrapper>
        </DialogContent>
      </div>
      <DialogActions>
        {close && (
          <Button
            size='small'
            disabled={updating}
            startIcon={<Cancel />}
            onClick={() => {
              setInvoice()
              setCheckedCharges([])
              setCheckedPayments([])
              setForm(defaultFormData)
              close()
            }}
          >
            Close
          </Button>
        )}

        <Button
          variant='contained'
          color='primary'
          size='small'
          disabled={
            !selectedPractice ||
            form?.Email?.length === 0 ||
            !form?.Email?.match(emailRegex)
          }
          startIcon={
            reSend ? (
              <DoubleBounce color={colors.white} />
            ) : (
              <Email size='small' />
            )
          }
          onClick={async () => {
            setReSend(true)

            const content = await dataStatement(
              statementData,
              selectedUser,
              selectedPractice
            )
            const response = await sendStatementEmail({
              practice: selectedPractice,
              user: { ...selectedUser, FormEmail: form.Email },
              content
            })
            if (response?.code === 'SUCCESS') {
              setReSend(false)
              setInvoiceSent(true)
              setTimeout(() => {
                setInvoiceSent(false)
                setInvoiceFailed(false)
              }, 3000)
            } else {
              setReSend(false)
              setInvoiceFailed(true)
              setTimeout(() => {
                setInvoiceSent(false)
                setInvoiceFailed(false)
              }, 3000)
            }
          }}
        >
          {reSend ? 'Sending ...' : 'Send Statement'}
        </Button>

        <Button
          variant='contained'
          size='small'
          disabled={updating}
          startIcon={printing ? <DoubleBounce color={colors.white} /> : <Print />}
          onClick={() => {
            setPrinting(true)
            printStatement(statementData, selectedUser, selectedPractice)
            setTimeout(() => setPrinting(false), 3000)
          }}
        >
          {printing ? 'Printing ...' : 'Print Statement'}
        </Button>
      </DialogActions>
      {invoiceSent && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20000,
            bottom: '1rem',
            right: '1rem'
          }}
        >
          <Alert
            onClose={() => setInvoiceSent(false)}
            elevation={6}
            variant='filled'
            severity='success'
          >
            Statement has been sent successfully!
          </Alert>{' '}
        </div>
      )}
      {invoiceFailed && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20001,
            bottom: '1rem',
            right: '1rem'
          }}
        >
          <Alert
            onClose={() => setInvoiceFailed(false)}
            elevation={6}
            variant='filled'
            severity='error'
          >
            There was an error sending the invoice!
          </Alert>{' '}
        </div>
      )}
      {invoiceCreate ? (
        <Dialog open={true} color='primary' maxWidth='lg'>
          <LinearProgress
            variant='buffer'
            value={invoiceCreate.progress}
            valueBuffer={invoiceCreate.progress + 10}
          />
          <Alert severity='info'>{invoiceCreate.message}</Alert>
        </Dialog>
      ) : null}
    </Dialog>
  )
}

export default PatientStatement
