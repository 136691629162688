import React from 'react'
import { Dialog, DialogContent, IconButton, Slide } from '@mui/material'
import AppointmentCommentForm from 'components/calendar/components/AppointmentCommentForm'
import { Close } from '@mui/icons-material'
import colors from 'library/styled-components/colors'
import { deleteAppointmentEvent } from 'actions/appointments'
import { ADMIN_NS } from 'config'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CommentModal = ({
  user,
  open,
  close,
  prePop,
  appointmentData,
  editEventData
}) => {
  if (!appointmentData && !prePop) return null

  return (
    <Dialog
      open={open || false}
      onClose={close}
      fullScreen={true}
      maxWidth={'lg'}
      TransitionComponent={Transition}
    >
      <DialogContent
        style={{
          background: colors.yellow
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0
          }}
        >
          <IconButton aria-label='close' onClick={close} size='large'>
            <Close />
          </IconButton>
        </div>

        {appointmentData ? (
          <AppointmentCommentForm
            user={user} 
            id={appointmentData.EventId}
            appointmentData={appointmentData}
            removeItem={() => {
              deleteAppointmentEvent(ADMIN_NS, appointmentData.EventId)
              close && close()
            }}
            updateAppointment={editEventData}
            cancelMethod={close}
          />
        ) : (
          <AppointmentCommentForm
            user={user} 
            prePop={prePop}
            updateAppointment={editEventData}
            cancelMethod={close}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CommentModal
