import React, { useState } from 'react'
import {
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button,
  IconButton,
  Tooltip
} from '@mui/material'
import MedicationIcon from '@mui/icons-material/Medication'
import EditIcon from '@mui/icons-material/Edit'

export default function CompactSelectionPopover ({
  compactSelection,
  setCompactSelection
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selection, setSelection] = useState(
    compactSelection || { upperLower: 'upper', leftRight: 'left', number: '1' }
  )

  const handleUpperLower = (event, newSelection) => {
    setSelection({ ...selection, upperLower: newSelection })
  }

  const handleLeftRight = (event, newSelection) => {
    setSelection({ ...selection, leftRight: newSelection })
  }

  const handleNumber = (event, newSelection) => {
    if (newSelection !== null) {
      setSelection({ ...selection, number: newSelection })
    }
  }

  const handleClick = event => {
    setSelection(
      compactSelection || {
        upperLower: 'upper',
        leftRight: 'left',
        number: '1'
      }
    )
    setAnchorEl(event.currentTarget)
  }

  const handleEditClick = event => {
    setSelection(
      compactSelection || {
        upperLower: 'upper',
        leftRight: 'left',
        number: '1'
      }
    )
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClear = () => {
    setCompactSelection(null)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'selection-popover' : undefined

  return (
    <>
      {compactSelection ? (
        <Button
          variant='outlined'
          color='primary'
          onClick={handleEditClick}
          endIcon={<EditIcon />}
        >
          {compactSelection.upperLower} {compactSelection.leftRight}{' '}
          {compactSelection.number}
        </Button>
      ) : (
        <Tooltip title='Composite Selection'>
          <IconButton
            variant='outlined'
            size='small'
            color='primary'
            onClick={handleClick}
          >
            <MedicationIcon />
          </IconButton>
        </Tooltip>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
            >
              <ToggleButtonGroup
                size='small'
                value={selection.upperLower}
                exclusive
                onChange={handleUpperLower}
                aria-label='upper-lower selection'
                color='primary'
              >
                <ToggleButton value='upper'>Upper</ToggleButton>
                <ToggleButton value='lower'>Lower</ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                size='small'
                value={selection.leftRight}
                exclusive
                onChange={handleLeftRight}
                aria-label='left-right selection'
                color='primary'
              >
                <ToggleButton value='left'>Left</ToggleButton>
                <ToggleButton value='right'>Right</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <ToggleButtonGroup
                size='small'
                value={selection.number}
                exclusive
                onChange={handleNumber}
                aria-label='number selection'
                color='primary'
                sx={{
                  width: '100%',
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map(num => (
                  <ToggleButton
                    sx={{
                      width: '100%'
                    }}
                    key={num}
                    value={String(num)}
                  >
                    {num}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='outlined' size='small' onClick={handleClear}>
                Clear
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                sx={{ ml: 1 }}
                onClick={() => {
                  setCompactSelection(selection)
                  handleClose()
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  )
}
