import React, {
  useEffect,
  useState,
  useMemo,
  createRef,
  useContext,
  useCallback
} from 'react'
import { AppContext } from '../../../../hooks/context'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { ModalBox, CalendarStyles } from '../../../../components/common'
import { NavigateBefore, NavigateNext } from 'styled-icons/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Select, MenuItem } from '@mui/material'
import { Alert } from '@mui/lab'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createTheme, LinearProgress } from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {
  ChevronRight,
  ChevronLeft,
  Search,
  Print,
  Block,
  AddCircleOutline
} from '@mui/icons-material'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import {
  addDays,
  format,
  addHours,
  addMonths,
  startOfHour,
  startOfDay,
  endOfDay
} from 'date-fns'
import {
  mapPublicHolidays,
  mapScheduleEvents,
  transformAppointment
} from '../../../../library/calendar'
import styled from 'styled-components'
import StandardLayout from '../../../../layouts/standard'
import ConfirmModal from 'components/editor/more-popover/modal'
import { removeAppointment } from './actions'
import { cancelAppointment } from './actions'
import { unCancelAppointment } from './actions'
import colors from '../../../../library/styled-components/colors'
import CalIcon from '../../../../icon/calendar'
import {
  mapBusinessHours,
  mapEvents,
  inBusinessHours
} from '../../../../library/calendar'
import Accounts from 'components/accounts'
import AppointmentPreview from './components/AppointmentPreview'
import AppointmentCharge from 'components/calendar/components/AppointmentCharge/AppointmentCharge'
import AppointmentSearch from '../../../../components/appointment-search'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import {
  addProceedure,
  createAppointment,
  createAppointmentEvent,
  getAppointmentsPaginate,
  updateAppointment,
  updateAppointmentEvent,
  updatePatientAppointment
} from 'actions/appointments'
import { updateLegacyRow, updateList } from 'library/resources'
import { ADMIN_NS } from 'config'
import TreatmentListDrawer from '../patient/components/treaments/TreatmentListDrawer'
import CustomEvent from './components/CustomEvent'
import DateClickPopover from './components/DateClickPopover'
import PrintTest from 'components/PrintTest'
import BlockModal from './components/BlockModal'
import { openingHours } from 'config'
import CommentModal from './components/CommentModal'
import localforage from 'localforage'
import DoubleBounce, {
  DoubleBounceContainer
} from 'components/loaders/double-bounce'
import PatientDialog from '../patient/dialog'
import uuidv4 from 'uuid/v4'
import SmsSchedulerModal from './components/SmsScheduler'
const moment = extendMoment(Moment)

const MemoizedFullCalendar = React.memo(FullCalendar)

const WrapCal = styled.div`
  display: grid;
  position: relative !important;
`

const DateWrap = styled.div`
  margin: 1rem 1rem 1rem 0rem;
  display: grid;
  background: white;
  position: absolute;
  z-index: 2;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
`

const Body = styled(CalendarStyles)`
  display: grid;
  position: relative !important;
  code {
    font-family: 'Nunito', sans-serif;
  }

  button {
    :focus {
      box-shadow: none !important;
    }
  }
`

const getCurrentWeek = date => {
  var currentDate = date
  var weekStart = currentDate.clone().startOf('isoWeek')
  var week = []
  for (var i = 0; i <= 6; i++) {
    week.push(moment(weekStart).add(i, 'days'))
  }
  return week
}

const MenuContentContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 4em 1.6em 1.6em 3em 4em;
  padding-left: 1em;
  grid-column-gap: 1em;
`

const NavItem = styled.div`
  cursor: pointer;
  border-radius: 50%;
  color: ${colors.txtColor};
  :hover {
    background-color: rgba(256, 256, 256, 0.2);
  }
`

const SplitScreen = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.number || 0},
    ${props => 100 / props.number}%
  );
`

const FormButton = styled.button`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  text-align: center;
  font-size: 1rem;
  border-radius: 4px;
  padding: 4px;
  background-color: ${colors.primary};
  :hover {
    background-color: ${colors.primary};
    opacity: 0.8;
  }
  border: 1px solid ${colors.txtColor};
  color: ${colors.txtColor};
`

const DateLabel = styled.h3`
  color: ${colors.txtColor};
`
const MenuContent = ({ month, year, goBack, goForward, goToday }) => (
  <MenuContentContainer>
    <FormButton onClick={goToday}>Today</FormButton>
    <NavItem onClick={goBack}>
      <NavigateBefore size='24'></NavigateBefore>
    </NavItem>
    <NavItem onClick={goForward}>
      <NavigateNext size='24'></NavigateNext>
    </NavItem>
    <CalIcon size='48' color='#ffffff' />
    <DateLabel>
      {month} {year}
    </DateLabel>
  </MenuContentContainer>
)

const PractitionerList = ({ practitioners, onSelect }) => (
  <List
    style={{
      position: 'absolute',
      backgroundColor: 'white',
      top: '3rem',
      left: '18.25rem',
      width: '12rem',
      zIndex: '2',
      borderBottom: `1px solid ${colors.dark}`,
      borderRight: `1px solid ${colors.dark}`,
      borderLeft: `1px solid ${colors.dark}`
    }}
    aria-label='Practioners'
  >
    {practitioners?.map((p, i) => (
      <ListItem
        button
        onClick={() =>
          onSelect({
            id: p.data.PractitionerId,
            name: p.data.Name,
            color: colors.themebyId[i % 6],
            businessHours: mapBusinessHours(p.data.Schedules)
          })
        }
      >
        <ListItemText primary={p.data.Name} />
      </ListItem>
    ))}
  </List>
)

export default function AdminAppointments ({ match, user }) {
  const [calendarComponentRef, setCalendarComponentRef] = useState(createRef())
  const [treatmentList, setTreatmentList] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [AppointmentId, setAppointmentId] = useState(null)
  const [scrollTime, setScrollTime] = useState('09:00:00')
  const [appointmentExtendedProps, setAppointmentExtendedProps] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [disableAppointment, setDisableAppointment] = useState(false)
  const [appointmentPreview, setAppointmentPreview] = useState(false)
  const [appointmentBlockPreview, setAppointmentBlockPreview] = useState(false)
  const [appointmentEventPreview, setAppointmentEventPreview] = useState(false)
  const [appointmentCharge, setAppointmentCharge] = useState(false)
  const [showAccounts, setShowAccounts] = useState(false)
  const [smsScheduler, setSmsScheduler] = useState(null)
  const [showPatient, setShowPatient] = useState(false)
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek'))
  const [showSelect, setShowSelect] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [splitScreen, setSplitScreen] = useState(true)
  const [searchAppointment, setSearchAppointment] = useState(false)
  const [practionerCal, setPractionerCal] = useState(null)
  const [selectedPractitioner, setSelectedPractitioner] = useState(null)
  const [displayPractitioners, setDisplayPractitioners] = useState([])
  const [overlayData, setOverlayData] = useState(null)
  const [dateOverlay, setDateOverlay] = useState(false)
  const [dateOpenData, setDateOpenData] = useState(null)
  const [prePop, setPrePop] = useState(null)
  const [error, setError] = useState(null)
  const [openPatient, setOpenPatient] = useState(false)
  const [complete, setComplete] = useState(false)
  const [reminderSent, setReminderSent] = useState(false)
  const [splitCalendarComponentRefs, setSplitCalendarComponentRefs] = useState(
    []
  )
  const [printSchedule, setPrintSchedule] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [printSplitSchedule, setPrintSplitSchedule] = useState(false)
  const [loading, setLoading] = useState(false)
  const { appointments, schedules, list, treatments, categories } = state

  const allPractitioners = useMemo(
    () => list?.data?.filter(p => p.data.Profile === 'active'),
    [list]
  )

  const practitioners = useMemo(
    () =>
      displayPractitioners
        .map(({ id, index }) => {
          const practitioner = allPractitioners?.find(
            p => p.data.PractitionerId === id && p.data.Profile === 'active'
          )
          return practitioner ? { ...practitioner, index } : null
        })
        .filter(p => p != null),
    [allPractitioners, displayPractitioners]
  )

  useEffect(() => {
    setDisplayPractitioners(
      list?.data
        ?.filter(p => p.data.Profile === 'active')
        .map((p, index) => ({
          id: p.data.PractitionerId,
          index
        }))
        .slice(0, 2)
    )
  }, [list])

  const defaultMaterialTheme = createTheme({
    palette: {
      primary: { main: '#2c3e50' }
    }
  })

  async function storeSelectedDate (date) {
    await localforage.setItem('selectedDate', date)
    setSelectedDate(date)

    const selectedDateStr = format(new Date(date), 'yyyy-MM-dd')

    // Fetch appointments from local storage
    let appointments = (await localforage.getItem('appointments')) || []

    // Check if appointments for the selected date exist
    const hasAppointmentsForDate = appointments.some(appointment => {
      try {
        const appointmentDate = new Date(appointment?.Start)
        if (isNaN(appointmentDate)) {
          console.warn(`Invalid date in appointment:`, appointment)
          return false // Skip this appointment
        }
        const appointmentDateStr = format(appointmentDate, 'yyyy-MM-dd')
        return appointmentDateStr === selectedDateStr
      } catch (error) {
        console.error('Error processing appointment:', appointment, error)
        return false
      }
    })

    if (!hasAppointmentsForDate) {
      setLoading(true)

      // Fetch data for the specific date
      await updateList('appointments', () =>
        getAppointmentsPaginate(
          ADMIN_NS,
          format(startOfDay(new Date(date)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          format(endOfDay(new Date(date)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        )
      )(dispatch)

      setLoading(false)
    } else {
      console.log('Data for this date already exists')
    }

    return date
  }

  function storeSplitScreen (value) {
    localforage.setItem('splitScreen', value)
    setSplitScreen(value)
  }

  async function renderSetting () {
    const selectedDate = await localforage.getItem('selectedDate')
    const newDate = selectedDate || new Date()

    if (!selectedDate) return
    storeSelectedDate(newDate)
    if (calendarComponentRef?.current?.getApi())
      calendarComponentRef?.current?.getApi().gotoDate(selectedDate)
    splitCalendarComponentRefs.forEach(pcal => {
      if (!pcal?.current?.getApi()) return
      pcal?.current?.getApi().gotoDate(selectedDate)
    })
  }

  useEffect(() => {
    renderSetting()
  }, [])

  useEffect(() => {
    if (!selectedPractitioner) {
      practitioners &&
        practitioners[0] &&
        setSelectedPractitioner({
          id: practitioners[0].data.PractitionerId,
          name: practitioners[0].data.Name,
          color: colors.themebyId[0 % 6],
          businessHours: mapBusinessHours(practitioners[0].data.Schedules)
        })
    }

    setSplitCalendarComponentRefs(
      practitioners?.map(_ => {
        const ref = createRef()
        return ref
      })
    )
    setCalendarComponentRef(createRef())
  }, [selectedPractitioner, practitioners])

  async function loadAppointment (id, calRef) {
    const item = await appointments.data.find(a => a.data.AppointmentId === id)
      ?.data
    setSelectedPractitioner({
      id: item.Practitioner.PractitionerId,
      name: item.Practitioner.Name,
      color:
        colors.themebyId[
          list.data.findIndex(i => i.data.id === item.Practitioner.id) % 6
        ],
      businessHours: mapBusinessHours(item.Practitioner.Schedules)
    })

    storeSelectedDate(item.Start)
    let calendarApi = calRef.getApi()
    calendarApi.gotoDate(item.Start)
    setAppointmentId(item.AppointmentId)
    setSearchAppointment(false)
  }

  useEffect(() => {
    if (
      match?.params.id &&
      appointments?.data?.length > 0 &&
      calendarComponentRef.current
    ) {
      loadAppointment(match?.params.id, calendarComponentRef.current)
    }
  }, [
    match?.params?.id,
    appointments?.data?.length,
    calendarComponentRef.current
  ])

  async function editData (data, AppointmentId) {
    if (AppointmentId) {
      const response = await updateAppointment(ADMIN_NS, data, AppointmentId)
      if (response && response.code === 'SUCCESS') {
        const data = appointments.data.map(a => {
          if (a.data.AppointmentId === response.data.AppointmentId) {
            return {
              ...response.data,
              Meta: { ...response.data.Meta, updating: true }
            }
          }
          return a.data
        })
        dispatch({
          type: 'LOAD',
          context: 'appointments',
          data
        })
      }
      return response
    } else {
      const response = await createAppointment(ADMIN_NS, data, AppointmentId)
      if (response && response.code === 'SUCCESS') {
        const data = [
          ...appointments.data.map(a => a.data),
          {
            ...response.data,
            Meta: { ...response.data.Meta, updating: true }
          }
        ]
        dispatch({
          type: 'LOAD',
          context: 'appointments',
          data
        })
      }
      return response
    }
  }

  async function appointmentDataUpdate (rawData) {
    const { AppointmentId } = rawData.event.extendedProps
    const data = transformEventData({
      ...rawData.event.extendedProps,
      start: rawData.event.start,
      end: rawData.event.end,
      SendMail: rawData.event.extendedProps.SendMail || false,
      SendSms: rawData.event.extendedProps.SendSms || false
    })

    setError(null)
    await editData(data, AppointmentId)
  }

  async function eventDataUpdate (rawData) {
    const { EventId } = rawData.event.extendedProps
    const data = transformEventData({
      ...rawData.event.extendedProps,
      start: rawData.event.start,
      end: rawData.event.end,
      SendMail: rawData.event.extendedProps.SendMail || false,
      SendSms: rawData.event.extendedProps.SendSms || false
    })
    const response = await updateAppointmentEvent(ADMIN_NS, data, EventId)
    if (response?.code === 'SUCCESS')
      await updateLegacyRow(
        'appointments',
        appointments.data.map(app => {
          if (app.data.EventId === response.data.EventId) {
            return {
              ...app,
              ...response.data
            }
          }
          return app.data
        })
      )(dispatch)
  }

  async function handleEventChange (rawData) {
    const { AppointmentId, EventId } = rawData.event.extendedProps
    if (AppointmentId) await appointmentDataUpdate(rawData)
    if (EventId) await eventDataUpdate(rawData)
    rawData.event.remove()
  }

  function validateAppointment (rawData) {
    setError(null)
    if (!rawData.patientId) {
      setError('you must select a patient')
      return null
    }

    if (!rawData.practitionerId) {
      setError('you must select a practitioner')
      return null
    }

    return {
      start: rawData.start,
      end: rawData.end
    }
  }

  async function editorDataUpdate (rawData, close = true) {
    const times = validateAppointment(rawData)
    if (!times) return
    const { start, end } = times
    const data = transformEditorData({ ...rawData, start, end })
    setError(null)
    await editData(data, appointmentExtendedProps?.AppointmentId)
    close && setAppointmentPreview(null)
    close && setOpenPatient(false)
    close && setPrePop(null)
    close && setOverlayData(null)
    close && setDateOverlay(null)
    close && setAppointmentId(null)
    close && setAppointmentExtendedProps(null)
    close && setDisableAppointment(false)

    if (!splitScreen) {
      calendarComponentRef?.current?.getApi().gotoDate(start)
    } else {
      const cal =
        splitCalendarComponentRefs[practionerCal?.index || 0]?.current?.getApi()
      cal.gotoDate(start)
      practionerCal?.cal?.current?.getApi().gotoDate(start)
    }
    storeSelectedDate(start)
  }

  const transformEditorData = ({
    practitionerId,
    patientId,
    categoryId,
    treatmentId = null,
    start,
    end,
    meta,
    sendSms = false,
    sendMail = false,
    reminder,
    cancelation
  }) => {
    return {
      PractitionerId: {
        val: practitionerId
      },
      PatientId: {
        val: patientId
      },
      CategoryId: {
        val: categoryId
      },
      TreatmentId: {
        val: treatmentId
      },
      Start: { val: start },
      End: { val: end },
      Meta: {
        val: meta
      },
      SendSms: {
        val: sendSms
      },
      SendMail: {
        val: sendMail
      },
      Reminder: {
        val: reminder || null
      },
      Cancelation: {
        val: cancelation || null
      }
    }
  }

  const transformEventData = ({
    start,
    end,
    PractitionerId,
    PatientId,
    TreatmentId,
    SendSms,
    SendMail
  }) => {
    return {
      PractitionerId: {
        val: PractitionerId
      },
      PatientId: {
        val: PatientId
      },
      TreatmentId: {
        val: TreatmentId
      },
      Start: { val: start },
      End: { val: end },
      SendSms: {
        val: SendSms
      },
      SendMail: {
        val: SendMail
      }
    }
  }

  const openAppointmentByDate = (date, hours, PractitionerId, Meta = null) => {
    if (hours && !inBusinessHours(date, hours)) return
    setDisableAppointment(false)
    setAppointmentPreview(true)
    setOverlayData(null)
    setDateOverlay(null)

    setPrePop({
      Date: new Date(date),
      Start: new Date(date),
      End: new Date(date),
      PractitionerId,
      CategoryId: categories?.data[0]?.data.CategoryId,
      reminder: null,
      Meta,
      Communicate: {
        SendMail: {
          val: true
        },
        SendSms: {
          val: false
        }
      }
    })
  }

  const openRecallAppointmentByDate = (
    date,
    hours,
    PractitionerId,
    Meta = null
  ) => {
    if (hours && !inBusinessHours(date, hours)) return
    setDisableAppointment(false)
    setAppointmentPreview(true)
    setOverlayData(null)
    setDateOverlay(null)
    setPrePop({
      Meta: { ...Meta, Recall: 6 },
      Date: addMonths(startOfHour(new Date()), 6),
      Start: addMonths(startOfHour(new Date()), 6),
      End: addMonths(startOfHour(new Date()), 6),
      PractitionerId,
      CategoryId: categories?.data[0]?.data.CategoryId,
      reminder: null,

      Communicate: {
        SendMail: {
          val: true
        },
        SendSms: {
          val: false
        }
      }
    })
  }

  const openAppointmentBlockByDate = (date, hours, PractitionerId) => {
    if (hours && !inBusinessHours(date, hours)) return
    setDisableAppointment(false)
    setAppointmentBlockPreview(true)
    setOverlayData(null)
    setDateOverlay(null)
    setPrePop({
      Date: new Date(date),
      Start: new Date(date),
      End: new Date(date),
      PractitionerId,
      CategoryId: categories?.data[0]?.data.CategoryId,
      reminder: null,
      Communicate: {
        SendMail: {
          val: true
        },
        SendSms: {
          val: false
        }
      }
    })
  }

  const openAppointmentEventByDate = (date, hours, PractitionerId) => {
    if (hours && !inBusinessHours(date, hours)) return
    setDisableAppointment(false)
    setAppointmentEventPreview(true)
    setOverlayData(null)
    setDateOverlay(null)
    setPrePop({
      Date: new Date(date),
      Start: new Date(date),
      End: new Date(date),
      PractitionerId,
      CategoryId: categories?.data[0]?.data.CategoryId,
      reminder: null,
      Communicate: {
        SendMail: {
          val: true
        },
        SendSms: {
          val: false
        }
      }
    })
  }

  async function removeItem () {
    await removeAppointment(appointmentExtendedProps.AppointmentId)(dispatch)
    setPrePop(null)
    setAppointmentId(null)
    setAppointmentExtendedProps(null)
    setDisableAppointment(false)
    setOverlayData(null)
    setDateOverlay(null)
  }

  const appointmentData =
    appointmentExtendedProps?.AppointmentId &&
    appointments &&
    transformAppointment(
      appointments?.data?.find(
        l => l.data.AppointmentId === appointmentExtendedProps?.AppointmentId
      ).data
    )

  const eventData =
    appointmentExtendedProps?.EventId &&
    appointments &&
    transformAppointment(
      appointments?.data?.find(
        l => l.data.EventId === appointmentExtendedProps?.EventId
      ).data
    )

  const events = useMemo(() => {
    const calEvents = mapEvents(
      appointments,
      appointmentExtendedProps?.AppointmentId,
      schedules?.data
    )
    const schedEvents =
      mapScheduleEvents(schedules?.data?.filter(s => s.data.TypeId === 1)) || []
    const publicHolidays = mapPublicHolidays(practitioners)
    return [
      ...(calEvents || []),
      ...(schedEvents || []),
      ...(publicHolidays || [])
    ]
  }, [appointments, appointmentExtendedProps?.AppointmentId, schedules.data])

  if (!selectedDate)
    return (
      <DoubleBounceContainer>
        <DoubleBounce />
      </DoubleBounceContainer>
    )

  const handleDateClick = useCallback((e, practitionerId) => {
    e.jsEvent.stopPropagation()
    setDateOverlay(true)
    setDateOpenData({
      Date: e.date,
      PractitionerId: practitionerId
    })
  }, [])

  return (
    <StandardLayout
      menu={true}
      menuContent={
        <MenuContent
          month={startDate.format('MMMM')}
          year={startDate.format('Y')}
          goBack={() => {
            setStartDate(startDate.subtract(1, 'w'))
            setCurrentWeek(getCurrentWeek(startDate))
            setSlide(1)
            setTimeout(() => {
              setSlide(null)
            }, 500)
          }}
          goForward={() => {
            setStartDate(startDate.add(1, 'w'))
            setCurrentWeek(getCurrentWeek(startDate))
            setSlide(2)
            setTimeout(() => {
              setSlide(null)
            }, 500)
          }}
          goToday={() => {
            setStartDate(moment())
            setCurrentWeek(getCurrentWeek(moment()))
            setSlide(2)
            setTimeout(() => {
              setSlide(null)
            }, 500)
          }}
        />
      }
      meta={{ updating: false }}
      user={user}
    >
      {loading ? (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: '1' }}
        />
      ) : null}
      <WrapCal>
        {!splitScreen ? (
          <Body
            color={selectedPractitioner && selectedPractitioner.color}
            showSelect={showSelect}
          >
            <PrintTest
              open={printSchedule}
              setOpen={setPrintSchedule}
              title={selectedPractitioner.name}
              date={format(selectedDate || new Date(), 'EEEE, dd/MM/yyyy')}
              selectedDate={selectedDate}
              schedules={[
                {
                  id: selectedPractitioner.id,
                  name: selectedPractitioner.name,
                  events: events?.filter(
                    e =>
                      !e?.Cancelation &&
                      !e?.ScheduleId &&
                      e?.PractitionerId === selectedPractitioner.id &&
                      format(selectedDate, 'dd/MM/yyyy') ===
                        format(new Date(e.start), 'dd/MM/yyyy')
                  )
                }
              ]}
            />

            <div
              className='calendar'
              style={{
                display:
                  appointmentPreview ||
                  appointmentBlockPreview ||
                  appointmentEventPreview
                    ? 'none'
                    : 'block'
              }}
            >
              <MemoizedFullCalendar
                initialDate={selectedDate}
                scrollTime={scrollTime}
                slotMinTime={openingHours.slotMinTime}
                slotMaxTime={openingHours.slotMaxTime}
                ref={calendarComponentRef}
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
                initialView='timeGridDay'
                height='calc(100vh - 3rem)'
                timeZone={'local'}
                weekends={true}
                editable={true}
                selectable={true}
                slotEventOverlap={true}
                eventOverlap={(stillEvent, movingEvent) => {
                  return stillEvent.allDay && movingEvent.allDay
                }}
                allDaySlot={true}
                hiddenDays={schedules.data
                  ?.filter(s => s.data.TypeId === 4)
                  .map(s => s.data.Day)}
                nowIndicator={true}
                eventAllow={(dropLocation, draggedEvent) => {
                  return (
                    moment().diff(draggedEvent.extendedProps.Start) <= 0 &&
                    moment().diff(dropLocation.start) <= 0
                  )
                }}
                dayCellClassNames={cell => {
                  const calCell = schedules?.data?.find(
                    s => s.data.Day === cell.dow && s.data.TypeId === 3
                  )
                  const onlineCell = schedules?.data?.find(
                    s => s.data.Day === cell.dow && s.data.TypeId === 4
                  )

                  if (calCell) {
                    //return ["out-of-range-secondary"];
                  }

                  if (cell.isPast) {
                    return ['out-of-range']
                  }
                }}
                businessHours={mapBusinessHours(
                  practitioners.find(
                    p => p.data.PractitionerId === selectedPractitioner.id
                  ).data.Schedules
                )}
                eventConstraint='businessHours'
                selectConstraint='businessHours'
                customButtons={{
                  filterButton: {
                    text: (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          height: '100%'
                        }}
                      >
                        {(selectedPractitioner && selectedPractitioner.name) ||
                          'All Practitioners'}
                      </div>
                    ),
                    click: function () {
                      setShowSelect(!showSelect)
                    }
                  },
                  rangeButton: {
                    text: (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={null}
                          sx={{
                            marginLeft: '16px',
                            marginRight: '16px',
                            // Hide all children of the input field
                            '& .MuiInputBase-input': {
                              display: 'none'
                            },
                            // Hide other input-related elements (outline, label)
                            '& .MuiOutlinedInput-notchedOutline': {
                              display: 'none'
                            },
                            '& .MuiInputAdornment-root': {
                              position: 'absolute',
                              right: 0
                            },
                            // Additional styles to remove padding/margin
                            '& .MuiInputBase-root': {
                              padding: 0
                            }
                          }}
                          onChange={date => {
                            storeSelectedDate(date)
                            calendarComponentRef.current
                              ?.getApi()
                              .gotoDate(date)
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant='standard'
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      edge='end'
                                      onClick={params.inputProps.onClick}
                                    >
                                      <CalendarTodayIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ),
                    click: function () {
                      // No action needed here
                    }
                  },

                  customprev: {
                    text: isProcessing ? <DoubleBounce /> : <ChevronLeft />,
                    click: function (e) {
                      e.preventDefault()
                      e.stopPropagation()
                      if (isProcessing) return
                      setIsProcessing(true)

                      try {
                        const viewName = calendarComponentRef.current
                          ?.getApi()
                          ?.view?.type.toLowerCase()
                        let newDate
                        if (viewName === 'timegridweek') {
                          newDate = addDays(selectedDate, -7)
                        } else if (viewName === 'timegridday') {
                          newDate = addDays(selectedDate, -1)
                        } else if (viewName === 'daygridmonth') {
                          newDate = addMonths(selectedDate, -1)
                        } else {
                          console.log('Unexpected viewName:', viewName)
                          return
                        }

                        storeSelectedDate(newDate)
                        calendarComponentRef.current?.getApi().gotoDate(newDate)
                      } catch (error) {
                        console.log('Error in customprev:', error)
                      } finally {
                        setTimeout(() => {
                          setIsProcessing(false)
                        }, 500)
                      }
                    }
                  },
                  customnext: {
                    text: isProcessing ? <DoubleBounce /> : <ChevronRight />,
                    click: async function (e) {
                      e.preventDefault()
                      e.stopPropagation()
                      if (isProcessing) return
                      setIsProcessing(true)

                      try {
                        const viewName = calendarComponentRef.current
                          ?.getApi()
                          ?.view?.type.toLowerCase()
                        let newDate
                        if (viewName === 'timegridweek') {
                          newDate = addDays(selectedDate, 7)
                        } else if (viewName === 'timegridday') {
                          newDate = addDays(selectedDate, 1)
                        } else if (viewName === 'daygridmonth') {
                          newDate = addMonths(selectedDate, 1)
                        } else {
                          console.log('Unexpected viewName:', viewName)
                          return
                        }

                        storeSelectedDate(newDate)
                        calendarComponentRef.current?.getApi().gotoDate(newDate)
                      } catch (error) {
                        console.log('Error in customnext:', error)
                      } finally {
                        setTimeout(() => {
                          setIsProcessing(false)
                        }, 500)
                      }
                    }
                  },
                  customToday: {
                    text: 'Today',
                    click: async function (e) {
                      e.preventDefault()
                      e.stopPropagation()
                      if (isProcessing) return
                      setIsProcessing(true)

                      try {
                        storeSelectedDate(new Date())
                        calendarComponentRef.current?.getApi().today()
                      } catch (error) {
                        console.log('Error in customToday:', error)
                      } finally {
                        setTimeout(() => {
                          setIsProcessing(false)
                        }, 500)
                      }
                    }
                  },
                  search: {
                    text: <Search />,
                    click: function () {
                      setSearchAppointment(true)
                    }
                  },
                  splitScreen: {
                    text: <FullscreenExitIcon />,
                    click: function () {
                      storeSplitScreen(true)
                      setShowSelect(false)
                    }
                  },
                  print: {
                    text: <Print />,
                    click: function () {
                      setPrintSchedule(true)
                    }
                  },
                  add: {
                    text: <AddCircleOutline />,
                    click: function () {
                      openAppointmentByDate(
                        new Date(),
                        false,
                        selectedPractitioner.id
                      )
                    }
                  },
                  block: {
                    text: <Block />,
                    click: function () {
                      openAppointmentBlockByDate(
                        new Date(),
                        selectedPractitioner.businessHours,
                        selectedPractitioner.id
                      )
                    }
                  }
                }}
                headerToolbar={{
                  left: 'search,add,block,print,customprev,customnext,splitScreen,filterButton',
                  center: 'title',
                  right:
                    'customToday,rangeButton,timeGridWeek,timeGridDay,dayGridMonth'
                }}
                dateClick={e => handleDateClick(e, selectedPractitioner.id)}
                eventChange={handleEventChange}
                eventContent={info => (
                  <CustomEvent
                    key={info.event.id}
                    info={info}
                    setShowAccounts={item => {
                      setShowAccounts(true)
                      setAppointmentExtendedProps(item)
                    }}
                    setShowPatient={item => {
                      setShowPatient(true)
                      setAppointmentExtendedProps(item)
                    }}
                    setSmsScheduler={(type, item) => {
                      setSmsScheduler(type)
                      setAppointmentExtendedProps(item)
                    }}
                    setAppointmentPreview={item => {
                      setAppointmentExtendedProps(item)
                      setAppointmentPreview(true)
                    }}
                    setNewAppointment={() => {
                      openAppointmentByDate(
                        new Date(),
                        false,
                        selectedPractitioner.id
                      )
                    }}
                    setNewInvoice={() => {
                      openInvoiceByDate(
                        new Date(),
                        selectedPractitioner.id,
                        selectedPractitioner.name
                      )
                    }}
                    setAppointmentBlockPreview={item => {
                      setAppointmentExtendedProps(item)
                      setAppointmentBlockPreview(
                        practitioner.data.PractitionerId
                      )
                    }}
                    sendCancelData={(data, AppointmentId) => {
                      cancelAppointment(data, AppointmentId)(dispatch)
                    }}
                    sendUndoCancelData={(data, AppointmentId) => {
                      unCancelAppointment(data, AppointmentId)(dispatch)
                    }}
                    setAppointmentEventPreview={item => {
                      setAppointmentExtendedProps(item)
                      setAppointmentEventPreview(item.PractitionerId)
                    }}
                    updateAppointment={async (rawData, AppointmentId) => {
                      const data = transformEditorData(rawData)
                      const response = await updatePatientAppointment(
                        ADMIN_NS,
                        data,
                        AppointmentId
                      )
                      if (response?.code === 'SUCCESS')
                        await updateLegacyRow(
                          'appointments',
                          appointments.data.map(app => {
                            if (
                              app.data.AppointmentId ===
                              response.data.AppointmentId
                            ) {
                              return {
                                ...app,
                                ...response.data
                              }
                            }
                            return app.data
                          })
                        )(dispatch)
                      setOverlayData(null)
                      setDateOverlay(null)
                      setAppointmentId(null)
                      setAppointmentExtendedProps(null)
                    }}
                    setCreateInvoice={item => {
                      setAppointmentExtendedProps({
                        ...item,
                        Proceedures: [
                          {
                            id: uuidv4(),
                            PractitionerId: item?.Practitioner?.PractitionerId,
                            Price: item?.Treatment?.Price,
                            Treatment: item?.Treatment,
                            TreatmentId: item?.Treatment?.TreatmentId
                          }
                        ]
                      })
                      setAppointmentCharge(true)
                    }}
                    setCreateNewInvoice={item => {
                      setAppointmentExtendedProps(item)
                      setAppointmentCharge(true)
                    }}
                  />
                )}
                events={
                  selectedPractitioner
                    ? events?.filter(
                        e => e?.PractitionerId === selectedPractitioner.id
                      )
                    : events
                }
              />
            </div>
          </Body>
        ) : (
          <SplitScreen number={practitioners?.length}>
            <PrintTest
              open={printSplitSchedule}
              setOpen={setPrintSplitSchedule}
              date={format(selectedDate || new Date(), 'EEEE, dd/MM/yyyy')}
              selectedDate={selectedDate}
              schedules={practitioners?.map(practitioner => ({
                id: practitioner.data.id,
                name: practitioner.data.Name,
                events: events?.filter(
                  e =>
                    !e?.Cancelation &&
                    !e?.ScheduleId &&
                    e?.PractitionerId === practitioner.data.PractitionerId &&
                    format(selectedDate, 'dd/MM/yyyy') ===
                      format(new Date(e.start), 'dd/MM/yyyy')
                )
              }))}
            />
            {practitioners.map(
              (practitioner, k) =>
                splitCalendarComponentRefs[k] && (
                  <Body
                    key={practitioner.data.id}
                    color={
                      colors.themebyId[
                        allPractitioners.findIndex(
                          p => p.data.id === practitioner.data.id
                        ) % 6
                      ]
                    }
                    showSelect={showSelect}
                  >
                    <div
                      className='calendar split'
                      key={k}
                      style={{
                        display:
                          appointmentPreview ||
                          appointmentBlockPreview ||
                          appointmentEventPreview
                            ? 'none'
                            : 'block'
                      }}
                    >
                      <MemoizedFullCalendar
                        initialDate={selectedDate}
                        eventOrder='CancelationId'
                        scrollTime={scrollTime}
                        slotMinTime={openingHours.slotMinTime}
                        slotMaxTime={openingHours.slotMaxTime}
                        ref={splitCalendarComponentRefs[k]}
                        plugins={[
                          interactionPlugin,
                          dayGridPlugin,
                          timeGridPlugin
                        ]}
                        initialView='timeGridDay'
                        height='calc(100vh - 3rem)'
                        contentHeight='60'
                        timeZone={'local'}
                        weekends={true}
                        editable={true}
                        selectable={true}
                        slotEventOverlap={true}
                        eventOverlap={(stillEvent, movingEvent) => {
                          return stillEvent.allDay && movingEvent.allDay
                        }}
                        allDaySlot={true}
                        hiddenDays={schedules.data
                          ?.filter(s => s.data.TypeId === 4)
                          .map(s => s.data.Day)}
                        nowIndicator={true}
                        eventAllow={(dropLocation, draggedEvent) => {
                          return (
                            moment().diff(draggedEvent.extendedProps.Start) <=
                              0 && moment().diff(dropLocation.start) <= 0
                          )
                        }}
                        dayCellClassNames={cell => {
                          const calCell = schedules?.data?.find(
                            s => s.data.Day === cell.dow && s.data.TypeId === 3
                          )
                          const onlineCell = schedules?.data?.find(
                            s => s.data.Day === cell.dow && s.data.TypeId === 4
                          )

                          if (calCell) {
                            //return ["out-of-range-secondary"];
                          }

                          if (cell.isPast) {
                            return ['out-of-range']
                          }
                        }}
                        businessHours={mapBusinessHours(
                          practitioner.data.Schedules
                        )}
                        eventConstraint='businessHours'
                        selectConstraint='businessHours'
                        customButtons={{
                          search: {
                            text: <Search />,
                            click: function () {
                              setSearchAppointment(k + 1)
                              setSelectedPractitioner({
                                id: practitioners[k].data.PractitionerId,
                                name: practitioners[k].data.Name,
                                color: colors.themebyId[k % 6],
                                businessHours: mapBusinessHours(
                                  practitioners[k].data.Schedules
                                )
                              })
                              setPractionerCal({
                                practitioner,
                                index: k,
                                cal: splitCalendarComponentRefs[k]
                              })
                            }
                          },
                          customprev: {
                            text: isProcessing ? (
                              <DoubleBounce />
                            ) : (
                              <ChevronLeft />
                            ),
                            disabled: isProcessing,
                            click: async function (e) {
                              e.preventDefault()
                              e.stopPropagation()
                              // Check if already processing, return early if so
                              if (isProcessing) return

                              // Set the flag to indicate processing has started
                              setIsProcessing(true)

                              try {
                                const newDate = addDays(selectedDate, -1)
                                await storeSelectedDate(newDate)
                                splitCalendarComponentRefs.forEach(
                                  (
                                    cal // Use forEach for proper iteration
                                  ) => cal.current?.getApi().gotoDate(newDate)
                                )
                              } catch (error) {
                                console.error(
                                  'Error during click processing:',
                                  error
                                )
                              } finally {
                                setTimeout(() => {
                                  setIsProcessing(false)
                                }, 500)
                              }
                            }
                          },
                          customnext: {
                            text: isProcessing ? (
                              <DoubleBounce />
                            ) : (
                              <ChevronRight />
                            ),
                            click: async function (e) {
                              e.preventDefault()
                              e.stopPropagation()
                              // Check if already processing, return early if so
                              if (isProcessing) return
                              setIsProcessing(true)

                              try {
                                const newDate = addDays(selectedDate, 1)
                                await storeSelectedDate(newDate)
                                splitCalendarComponentRefs.forEach(
                                  (
                                    cal // Use forEach for proper iteration
                                  ) => cal.current?.getApi().gotoDate(newDate)
                                )
                              } catch (error) {
                                console.error(
                                  'Error during click processing:',
                                  error
                                )
                              } finally {
                                setTimeout(() => {
                                  setIsProcessing(false)
                                }, 500)
                              }
                            }
                          },
                          customToday: {
                            text: 'Today',
                            click: async function (e) {
                              e.preventDefault()
                              e.stopPropagation()
                              if (isProcessing) return
                              setIsProcessing(true)

                              try {
                                const now = new Date()
                                await storeSelectedDate(now)
                                splitCalendarComponentRefs.forEach(
                                  (
                                    cal // Use forEach for proper iteration
                                  ) => cal.current?.getApi().gotoDate(now)
                                )
                              } catch (error) {
                                console.error(
                                  'Error during click processing:',
                                  error
                                )
                              } finally {
                                setTimeout(() => {
                                  setIsProcessing(false)
                                }, 500)
                              }
                            }
                          },
                          rangeButton: {
                            text: (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  value={null}
                                  sx={{
                                    marginLeft: '16px',
                                    marginRight: '16px',
                                    // Hide all children of the input field
                                    '& .MuiInputBase-input': {
                                      display: 'none'
                                    },
                                    // Hide other input-related elements (outline, label)
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      display: 'none'
                                    },
                                    '& .MuiInputAdornment-root': {
                                      position: 'absolute',
                                      right: 0
                                    },
                                    // Additional styles to remove padding/margin
                                    '& .MuiInputBase-root': {
                                      padding: 0
                                    }
                                  }}
                                  onChange={date => {
                                    storeSelectedDate(date)
                                    splitCalendarComponentRefs.forEach(cal => {
                                      cal.current.getApi().gotoDate(date)
                                    })
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant='standard'
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <IconButton
                                              edge='end'
                                              onClick={
                                                params.inputProps.onClick
                                              }
                                            >
                                              <CalendarTodayIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            ),
                            click: function () {
                              // No action needed here
                            }
                          },

                          print: {
                            text: <Print />,
                            click: function () {
                              setPrintSplitSchedule(
                                practitioners[k].data.PractitionerId
                              )
                            }
                          },
                          add: {
                            text: <AddCircleOutline />,
                            click: function () {
                              setPractionerCal({
                                practitioner,
                                index: k,
                                cal: splitCalendarComponentRefs[k]
                              })
                              openAppointmentByDate(
                                new Date(),
                                false,
                                practitioner.data.PractitionerId
                              )
                            }
                          },
                          block: {
                            text: <Block />,
                            click: function () {
                              setPractionerCal({
                                practitioner,
                                index: k,
                                cal: splitCalendarComponentRefs[k]
                              })
                              openAppointmentBlockByDate(
                                new Date(),
                                practitioners[k].data.businessHours,
                                practitioners[k].data.PractitionerId
                              )
                            }
                          },
                          practitionerDropdown: {
                            text: (
                              <Select
                                value={practitioners[k].data.PractitionerId}
                                onChange={e => {
                                  setDisplayPractitioners(prevPractitioners => {
                                    const newPractitioners = [
                                      ...prevPractitioners
                                    ]
                                    newPractitioners[k] = {
                                      id: e.target.value,
                                      index: k
                                    } // Maintain the same index
                                    return newPractitioners
                                  })
                                }}
                                variant='standard'
                                disableUnderline
                                sx={{
                                  color: 'white',
                                  '& *': {
                                    color: 'white'
                                  }
                                }}
                              >
                                {allPractitioners.map((p, i) => (
                                  <MenuItem
                                    key={i}
                                    disabled={displayPractitioners.some(
                                      item => item.id === p.data.PractitionerId
                                    )}
                                    value={p.data.PractitionerId}
                                  >
                                    {p.data.Name}
                                  </MenuItem>
                                ))}
                              </Select>
                            ),
                            click: () => {
                              // Leave empty or handle any necessary click events
                            }
                          },
                          fullScreen: {
                            text: (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: '4px'
                                }}
                              >
                                <FullscreenIcon />
                              </div>
                            ),
                            click: function () {
                              storeSplitScreen(false)
                              setSelectedPractitioner({
                                id: practitioners[k].data.PractitionerId,
                                name: practitioners[k].data.Name,
                                color: colors.themebyId[k % 6],
                                businessHours: mapBusinessHours(
                                  practitioners[k].data.Schedules
                                )
                              })
                            }
                          }
                        }}
                        headerToolbar={{
                          left: 'search,add,block,print,customprev,customnext,fullScreen,practitionerDropdown',
                          center: 'title',
                          right: 'customToday,rangeButton'
                        }}
                        titleFormat={{
                          year: '2-digit',
                          month: 'short',
                          day: 'numeric'
                        }}
                        dateClick={e =>
                          handleDateClick(e, practitioner.data.PractitionerId)
                        }
                        eventContent={info => (
                          <CustomEvent
                            key={info.event.id}
                            info={info}
                            setShowAccounts={item => {
                              setShowAccounts(true)
                              setAppointmentExtendedProps(item)
                            }}
                            setShowPatient={item => {
                              setShowPatient(true)
                              setAppointmentExtendedProps(item)
                            }}
                            setSmsScheduler={(type, item) => {
                              setSmsScheduler(type)
                              setAppointmentExtendedProps(item)
                            }}
                            setAppointmentPreview={item => {
                              setAppointmentExtendedProps(item)
                              setPractionerCal({
                                practitioner,
                                index: k,
                                cal: splitCalendarComponentRefs[k]
                              })
                              setAppointmentPreview(true)
                            }}
                            setNewAppointment={() => {
                              openAppointmentByDate(
                                new Date(),
                                false,
                                selectedPractitioner.id
                              )
                            }}
                            setAppointmentBlockPreview={item => {
                              setAppointmentExtendedProps(item)
                              setAppointmentBlockPreview(
                                practitioner.data.PractitionerId
                              )
                            }}
                            sendCancelData={(data, AppointmentId) => {
                              cancelAppointment(data, AppointmentId)(dispatch)
                            }}
                            sendUndoCancelData={(data, AppointmentId) => {
                              unCancelAppointment(data, AppointmentId)(dispatch)
                            }}
                            setAppointmentEventPreview={item => {
                              setAppointmentExtendedProps(item)
                              setAppointmentEventPreview(item.PractitionerId)
                            }}
                            updateAppointment={async (
                              rawData,
                              AppointmentId
                            ) => {
                              const data = transformEditorData(rawData)
                              const response = await updatePatientAppointment(
                                ADMIN_NS,
                                data,
                                AppointmentId
                              )
                              if (response?.code === 'SUCCESS')
                                await updateLegacyRow(
                                  'appointments',
                                  appointments.data.map(app => {
                                    if (
                                      app.data.AppointmentId ===
                                      response.data.AppointmentId
                                    ) {
                                      return {
                                        ...app,
                                        ...response.data
                                      }
                                    }
                                    return app.data
                                  })
                                )(dispatch)
                              setOverlayData(null)
                              setDateOverlay(null)
                              setAppointmentId(null)
                              setAppointmentExtendedProps(null)
                            }}
                            setCreateInvoice={item => {
                              setAppointmentExtendedProps({
                                ...item,
                                Proceedures: [
                                  {
                                    id: uuidv4(),
                                    PractitionerId:
                                      item?.Practitioner?.PractitionerId,
                                    Price: item?.Treatment?.Price,
                                    Treatment: item?.Treatment,
                                    TreatmentId: item?.Treatment?.TreatmentId
                                  }
                                ]
                              })
                              setAppointmentCharge(true)
                            }}
                            setCreateNewInvoice={item => {
                              setAppointmentExtendedProps(item)
                              setAppointmentCharge(true)
                            }}
                            setNewInvoice={() => {
                              openInvoiceByDate(
                                new Date(),
                                selectedPractitioner.id,
                                selectedPractitioner.name
                              )
                            }}
                          />
                        )}
                        eventChange={handleEventChange}
                        events={events?.filter(
                          e =>
                            e?.PractitionerId ===
                            practitioner.data.PractitionerId
                        )}
                      />
                    </div>
                  </Body>
                )
            )}
          </SplitScreen>
        )}
        {searchAppointment && (
          <>
            <ModalBox
              show={searchAppointment}
              onClick={() => {
                setSearchAppointment(false)
              }}
            ></ModalBox>
            <AppointmentSearch
              open={searchAppointment}
              dim={{
                left:
                  searchAppointment > 0
                    ? `${Math.abs(
                        (100 / practitioners.length) * (searchAppointment - 1)
                      )}%`
                    : 0
              }}
              placeholder={"Search Patient's Appointments"}
              options={
                appointments
                  ? appointments.data
                      .map(p => p.data)
                      .filter(a =>
                        practionerCal
                          ? a.PractitionerId ===
                            practionerCal.practitioner.data.PractitionerId
                          : a
                      )
                      .sort((a, b) => {
                        if (a.Start < b.Start) return 1
                        if (a.Start > b.Start) return -1
                        return 0
                      })
                  : []
              }
              setSelectedUser={item => {
                setAppointmentExtendedProps(item)
                setSelectedPractitioner({
                  id: item.Practitioner.PractitionerId,
                  name: item.Practitioner.Name,
                  color:
                    colors.themebyId[
                      allPractitioners.findIndex(
                        i => i.data.id === practitioner.data.id
                      ) % 6
                    ],
                  businessHours: mapBusinessHours(
                    practionerCal.practitioner.data.Schedules
                  )
                })

                storeSelectedDate(new Date(item.Start))
                if (!splitScreen) {
                  calendarComponentRef.current?.getApi().gotoDate(item.Start)
                } else {
                  splitCalendarComponentRefs.forEach(pcal => {
                    if (!pcal?.current?.getApi()) return
                    pcal?.current?.getApi().gotoDate(item.Start)
                  })
                }

                setAppointmentId(item.AppointmentId)
                setSearchAppointment(false)
              }}
              loading={false}
            />
          </>
        )}
        {practitioners && showSelect && (
          <PractitionerList
            practitioners={practitioners}
            onSelect={data => {
              setSelectedPractitioner(data)
              setShowSelect(false)
            }}
          />
        )}
        <ConfirmModal
          showModal={deleteOpen}
          hideModal={() => setDeleteOpen(false)}
          continueClick={() => {
            cancelMethod()
            removeItem()
          }}
        />

        <DateClickPopover
          open={dateOverlay}
          close={() => setDateOverlay(null)}
          setAppointment={() => {
            openAppointmentByDate(
              dateOpenData.Date,
              dateOpenData.businessHours,
              dateOpenData.PractitionerId
            )
          }}
          setComment={() => {
            openAppointmentEventByDate(
              dateOpenData.Date,
              dateOpenData.businessHours,
              dateOpenData.PractitionerId
            )
          }}
          setRecall={() => {
            openRecallAppointmentByDate(
              dateOpenData.Date,
              dateOpenData.businessHours,
              dateOpenData.PractitionerId,
              { Recall: 6 }
            )
          }}
          setBlock={() => {
            openAppointmentBlockByDate(
              dateOpenData.Date,
              dateOpenData.businessHours,
              dateOpenData.PractitionerId
            )
          }}
          createEvent={async rawData => {
            const data = {
              PractitionerId: {
                val: dateOpenData.PractitionerId
              },
              Start: {
                val: format(dateOpenData.Date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
              },
              End: {
                val: format(
                  addHours(dateOpenData.Date, 1),
                  "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                )
              },
              Meta: {
                val: {
                  Comments: [
                    { text: rawData.Comment.val, user: user?.data?.Name }
                  ]
                }
              }
            }
            await createAppointmentEvent(ADMIN_NS, data)

            setOverlayData(null)
            setDateOverlay(null)
          }}
        />
 
        <BlockModal
          user={user}
          open={!!appointmentBlockPreview}
          prePop={prePop}
          close={() => {
            setOverlayData(null)
            setDateOverlay(null)
            setPrePop(null)
            setAppointmentId(null)
            setAppointmentExtendedProps(null)
            setDisableAppointment(false)
            setAppointmentBlockPreview(false)
          }}
          practitioners={practitioners}
          appointmentData={eventData}
        ></BlockModal>
        <CommentModal
          user={user}
          open={!!appointmentEventPreview}
          prePop={prePop}
          close={() => {
            setOverlayData(null)
            setDateOverlay(null)
            setPrePop(null)
            setAppointmentId(null)
            setAppointmentExtendedProps(null)
            setDisableAppointment(false)
            setAppointmentEventPreview(false)
          }}
          practitionerId={appointmentEventPreview}
          practitioners={practitioners}
          appointmentData={eventData}
        ></CommentModal>
        <AppointmentPreview
          user={user}
          close={() => {
            setOverlayData(null)
            setDateOverlay(null)
            setPrePop(null)
            setAppointmentId(null)
            setAppointmentExtendedProps(null)
            setDisableAppointment(false)
            setAppointmentPreview(false)
          }}
          show={!!appointmentPreview}
          error={error}
          complete={complete}
          appointmentData={appointmentData}
          prePop={prePop}
          openPatient={openPatient}
          disableAppointment={disableAppointment}
          setOpenPatient={setOpenPatient}
          storeSelectedDate={date => {
            if (!splitScreen) {
              calendarComponentRef.current?.getApi().gotoDate(date)
            } else {
              const cal =
                splitCalendarComponentRefs[practionerCal.index].current.getApi()
              cal.gotoDate(date)
              practionerCal.cal.current?.getApi().gotoDate(date)
            }
            storeSelectedDate(date)
          }}
          removeItem={removeItem}
          cancelMethod={() => {
            setOverlayData(null)
            setDateOverlay(null)
            setError(null)
            setPrePop(null)
            setAppointmentId(null)
            setAppointmentExtendedProps(null)
            setDisableAppointment(false)
            setAppointmentPreview(false)
          }}
          setComplete={setComplete}
          validateAppointment={validateAppointment}
          editorDataUpdate={(data, open) => editorDataUpdate(data, open)}
          updatePreviewEvent={data => {
            const practitioner = practitioners.find(
              p => p.data.PractitionerId === data.PractitionerId
            )
            if (!practitioner) return
            setSelectedPractitioner({
              id: practitioner.data.PractitionerId,
              name: practitioner.data.Name,
              color:
                colors.themebyId[
                  practitioners.findIndex(
                    i => i.data.id === practitioner.data.id
                  ) % 6
                ],
              businessHours: mapBusinessHours(practitioner.data.Schedules)
            })
          }}
        ></AppointmentPreview>

        {appointmentCharge && (
          <AppointmentCharge
            show={!!appointmentCharge}
            isAppointment={true}
            invoiceData={{
              ...appointmentExtendedProps,
              id: appointmentExtendedProps?.Patient?.User?.id
            }}
            selectedUser={appointmentExtendedProps?.Patient?.User}
            selectedPractice={appointmentExtendedProps?.Patient?.Practice}
            close={() => {
              setAppointmentCharge(false)
              setAppointmentExtendedProps(null)
            }}
            setList={setTreatmentList}
          ></AppointmentCharge>
        )}
        {appointmentExtendedProps?.Patient && showAccounts && (
          <Accounts
            selectedPractitionerId={appointmentExtendedProps?.PractitionerId}
            patient={{
              ...appointmentExtendedProps?.Patient,
              Name: appointmentExtendedProps?.Patient?.User.Name
            }}
            show={showAccounts}
            close={() => {
              setShowAccounts(false)
              setAppointmentExtendedProps(null)
            }}
          ></Accounts>
        )}

        {appointmentExtendedProps && showPatient && (
          <PatientDialog // this is the patient dialog
            user={user}
            show={showPatient}
            close={() => {
              setShowPatient(false)
              setAppointmentExtendedProps(null)
            }}
            appointmentExtendedProps={appointmentExtendedProps}
          />
        )}

        {appointmentExtendedProps && smsScheduler && (
          <SmsSchedulerModal
            open={!!smsScheduler}
            close={() => {
              setSmsScheduler(null)
              setAppointmentExtendedProps(null)
            }}
            type={smsScheduler}
            appointmentData={events.find(
              e => e.AppointmentId === appointmentExtendedProps.AppointmentId
            )} // this is the appointment data
          />
        )}

        <TreatmentListDrawer
          open={!!treatmentList}
          status={2}
          list={treatments.data}
          closeList={() => setTreatmentList(false)}
          addProceedure={async proceedures => {
            alert(2)
            const data = proceedures.map(treatment => ({
              AppointmentId: { val: appointmentData.AppointmentId },
              TreatmentId: { val: treatment.data.TreatmentId },
              PatientId: { val: appointmentData.PatientId },
              Data: {
                val: {
                  quantity: treatment.qty,
                  status: treatment.status
                }
              },
              Price: { val: treatment.data.Price * treatment.qty }
            }))

            const response = await addProceedure(
              ADMIN_NS,
              data,
              appointmentData.AppointmentId,
              appointmentData.PatientId
            )

            if (response?.code === 'SUCCESS')
              await updateLegacyRow(
                'appointments',
                appointments.data.map(a =>
                  a.AppointmentId === response.data.AppointmentId
                    ? {
                        ...a.data,
                        Proceedures: [
                          ...(a?.data.Proceedures || []),
                          ...response.data
                        ]
                      }
                    : a
                )
              )(dispatch)

            setTreatmentList(false)
            setOverlayData(null)
            setDateOverlay(null)
          }}
        />
      </WrapCal>
      {reminderSent && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20000,
            bottom: '1rem',
            right: '1rem'
          }}
        >
          <Alert
            onClose={() => setReminderSent(false)}
            elevation={6}
            variant='filled'
            severity='success'
          >
            Reminder message has been sent!
          </Alert>{' '}
        </div>
      )}
    </StandardLayout>
  )
}
