import React, { useContext } from 'react'
import {
  ListItem,
  List,
  ListItemText,
  Table,
  TableBody,
  TableContainer
} from '@mui/material'
import colors from 'library/styled-components/colors'
import { AppContext } from 'hooks/context'
import RenderChargeItem from './items/RenderChargeItem'
import RenderPaymentItem from './items/RenderPaymentItem'

const ListCharges = ({
  show,
  editing,
  form,
  invoice,
  handlePaymentsToggle,
  setForm
}) => {
  const { state } = useContext(AppContext)
  const practitioners = state.list
  return (
    <>
      <TableContainer>
        <Table
          dense='true'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <TableBody>
            {(!invoice || editing) &&
              [...(form.proceedures || []), ...(form.payments || [])]
                ?.filter(p => (show?.length ? show.includes(p.id) : true))
                ?.sort((a, b) => {
                  return new Date(a.updatedAt) - new Date(b.updatedAt)
                })
                ?.map(proc => {
                  if (proc.isPay) {
                    return (
                      <RenderPaymentItem
                        key={proc.id}
                        practitioners={practitioners}
                        proc={proc}
                        form={form}
                        handleToggle={handlePaymentsToggle}
                        setForm={setForm}
                      />
                    )
                  }
                  return (
                    <RenderChargeItem
                      key={proc.id}
                      practitioners={practitioners}
                      proc={proc}
                      form={form}
                      setForm={setForm}
                    />
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <List dense>
        {!editing &&
          !!invoice &&
          [...(invoice?.Charges || []), ...(invoice?.Payments || [])]
            ?.sort((a, b) => {
              return new Date(a.updatedAt) - new Date(b.updatedAt)
            })
            ?.map(invItem => {
              if (invItem?.PaymentId) {
                return (
                  <ListItem key={invItem.id} disabled={true}>
                    <ListItemText
                      primary={`Payment by ${invItem?.Method} - Practitioner ${
                        practitioners.data.find(
                          p => p.data.PractitionerId === invItem.PractitionerId
                        )?.data?.Name
                      } - ${new Date(invItem?.createdAt).toLocaleDateString(
                        'en-GB'
                      )}`}
                    />
                    <div style={{ display: 'flex', color: colors.green }}>
                      <ListItemText
                        primary={new Intl.NumberFormat('en-IE', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(invItem.Amount || 0)}
                      />
                    </div>
                  </ListItem>
                )
              }
              return (
                <ListItem divider key={invItem.id} disabled={true}>
                  <ListItemText
                    primary={invItem.Treatment?.Code}
                    secondary={`${invItem.Treatment?.Name} - Practitioner ${
                      practitioners.data.find(
                        p => p?.data?.PractitionerId === invItem?.PractitionerId
                      )?.data?.Name || ''
                    } - ${invItem?.Data?.compactSelection?.leftRight || ''}
                      ${invItem?.Data?.compactSelection?.upperLower || ''} ${
                      invItem?.Data?.compactSelection?.number || ''
                    }`}
                  />
                  <div style={{ display: 'flex', color: colors.red }}>
                    <ListItemText
                      primary={new Intl.NumberFormat('en-IE', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(invItem.Amount || 0)}
                      secondary={`QTY: ${invItem?.Data?.quantity || 1}`}
                    />
                  </div>
                </ListItem>
              )
            })}
      </List>
    </>
  )
}

export default ListCharges
