import React, { useState, useMemo, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import EventIcon from "@mui/icons-material/Event";
import colors from "library/styled-components/colors";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import TimelineBlockItem from "./TimelineBlockItem";
import { AppContext } from "hooks/context";
import {
  createPatientCancelation,
  updatePatientAppointment,
  unDoPatientCancelation,
} from "actions/appointments";
import { ADMIN_NS } from "config";
import { updateItem } from "library/resources";
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { InputContainer, Row } from "components/common";
import TimelineBlock from "components/calendar/components/TimelineBlock";

const Container = styled.div`
  border-radius: 3px;
  width: 100%;
  height: calc(100% - 4rem);
  overflow-y: auto;
  display: inline-block;
`;

const RecordContainer = styled.div`
  display: flex;
`;

const Timline = styled.div`
  /* The actual timeline (the vertical ruler) */
  position: relative;
  height: 100%;
  flex-grow: 1;
  margin-top: 1rem;
`;

const TimelineBlockItemWrapper = styled.div`
  background-color: ${(props) => (props.selected ? props.background : "#fff")};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.background};
  }
`;

const ListWrapper = styled.div`
  position: relative !important;
`;

const transformEditorData = ({
  practitionerId,
  patientId,
  treatmentId,
  start,
  end,
  meta,
  sendSms = false,
  sendMail = false,
}) => {
  return {
    PractitionerId: {
      val: practitionerId,
    },
    PatientId: {
      val: patientId,
    },
    TreatmentId: {
      val: treatmentId,
    },
    Start: { val: start },
    End: { val: end },
    Meta: {
      val: meta,
    },
    SendSms: {
      val: sendSms,
    },
    SendMail: {
      val: sendMail,
    },
  };
};

const ConsultationProgress = ({
  user,
  patient,
  setAppointment,
  setComplete,
  setShowPreview,
  setShowRecallPreview,
}) => {
  const {
    state: { treatments, list },
    dispatch,
  } = useContext(AppContext);
  const [cancel, setCancel] = useState(null);
  const [unCancel, setUnCancel] = useState(null);
  const [form, setForm] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const practitioners = list;
  const appointments = useMemo(() => {
    return patient?.Appointments;
  }, [patient]);

  const selectedAppointmentData = useMemo(() => {
    return appointments.find((a) => a.AppointmentId === selectedAppointment);
  }, [appointments, selectedAppointment]);

  useEffect(() => {
    setForm({
      ...form,
      meta: selectedAppointmentData?.Meta,
    });
  }, []);

  useEffect(() => {
    setSelectedAppointment(patient?.Appointments[0]?.AppointmentId);
  }, []);

  return (
    <Container>
      <RecordContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Timline>
            <Button
              variant="outlined"
              size="small"
              style={{
                background: colors.primColor,
                borderColor: colors.primColor,
                color: colors.white,
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
              startIcon={<EventIcon />}
              onClick={() => {
                setShowPreview(true);
              }}
            >
              Create Appointment
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{
                background: colors.warning,
                color: colors.white,
                borderColor: colors.warning,
                marginRight: "1rem",
              }}
              startIcon={<AllInclusiveIcon />}
              onClick={() => {
                setShowRecallPreview(true);
              }}
            >
              Create Recall
            </Button>

            {appointments &&
              appointments
                .sort(function (a, b) {
                  var c = new Date(a.Start);
                  var d = new Date(b.Start);
                  return d - c;
                })
                .map((a, i) => (
                  <ListWrapper key={i}>
                    {unCancel?.appointmentId === a.AppointmentId && (
                      <Dialog
                        open={unCancel?.appointmentId === a.AppointmentId}
                      >
                        <div
                          style={{
                            display: "grid",
                            margin: "1rem",
                          }}
                        >
                          <h3>Undo Cancelation</h3>
                          <Row>
                            <FormControl>
                              <Button
                                onClick={async (rawData) => {
                                  const data = {
                                    AppointmentId: a.AppointmentId,
                                    PatientId: patient.PatientId,
                                  };
                                  const response = await unDoPatientCancelation(
                                    ADMIN_NS,
                                    data
                                  );
                                  if (response?.code === "SUCCESS")
                                    await updateItem("patient", {
                                      ...patient,
                                      Appointments: patient.Appointments.map(
                                        (app) => {
                                          if (
                                            app.AppointmentId ===
                                            response.data.AppointmentId
                                          ) {
                                            return {
                                              ...app,
                                              ...response.data,
                                            };
                                          }
                                          return app;
                                        }
                                      ),
                                    })(dispatch);
                                  setUnCancel(null);
                                }}
                                variant="contained"
                                color={"primary"}
                              >
                                Undo Cancelation
                              </Button>
                            </FormControl>
                            <FormControl>
                              <Button
                                onClick={() => setUnCancel(null)}
                                variant="outlined"
                              >
                                Close
                              </Button>
                            </FormControl>
                          </Row>
                        </div>
                      </Dialog>
                    )}

                    {cancel?.appointmentId === a.AppointmentId && (
                      <Dialog open={cancel?.appointmentId === a.AppointmentId}>
                        <div
                          style={{
                            display: "grid",
                            margin: "1rem",
                          }}
                        >
                          <h3>Cancel Appointment</h3>
                          <FormControl variant="outlined">
                            <InputContainer>
                              <InputLabel>Reason</InputLabel>
                              <Select
                                style={{ background: "white", width: "100%" }}
                                value={form?.cancelation?.Reason || ""}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    cancelation: {
                                      ...form?.cancelation,
                                      Reason: e.target.value,
                                    },
                                  });
                                }}
                                label="Reason"
                              >
                                <MenuItem value={"FORGOT"}>{"Forgot"}</MenuItem>
                                <MenuItem value={"EMERGENCY"}>
                                  {"Emergency"}
                                </MenuItem>
                                <MenuItem value={"FAILED"}>
                                  {"Did not Attend"}
                                </MenuItem>
                                <MenuItem value={"SICK"}>{"Illness"}</MenuItem>
                                <MenuItem value={"WORK"}>{"Work"}</MenuItem>
                                <MenuItem value={"PERSONAL"}>
                                  {"Personal Reasons"}
                                </MenuItem>
                              </Select>
                            </InputContainer>
                          </FormControl>
                          <FormControl>
                            <InputContainer>
                              <TextField
                                variant="outlined"
                                label="Additional Info"
                                multiline
                                maxRows={4}
                                style={{ background: "white", width: "100%" }}
                                value={form?.cancelation?.Information || ""}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    cancelation: {
                                      ...form?.cancelation,
                                      Information: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </InputContainer>
                          </FormControl>
                          <Row>
                            <FormControl>
                              <Button
                                onClick={async (rawData) => {
                                  const data = {
                                    ...form?.cancelation,
                                    AppointmentId: a.AppointmentId,
                                    PatientId: patient.PatientId,
                                  };
                                  const response =
                                    await createPatientCancelation(
                                      ADMIN_NS,
                                      data
                                    );
                                  if (response?.code === "SUCCESS")
                                    await updateItem("patient", {
                                      ...patient,
                                      Appointments: patient.Appointments.map(
                                        (app) => {
                                          if (
                                            app.AppointmentId ===
                                            response.data.AppointmentId
                                          ) {
                                            return {
                                              ...app,
                                              ...response.data,
                                            };
                                          }
                                          return app;
                                        }
                                      ),
                                    })(dispatch);
                                  setCancel(null);
                                }}
                                variant="contained"
                                color={"primary"}
                              >
                                Cancel Appointment
                              </Button>
                            </FormControl>
                            <FormControl>
                              <Button
                                onClick={() => setCancel(null)}
                                variant="contained"
                              >
                                Close
                              </Button>
                            </FormControl>
                          </Row>
                        </div>
                      </Dialog>
                    )}
                    <TimelineBlockItemWrapper
                      selected={selectedAppointment === a.AppointmentId}
                      background={
                        a?.Meta?.Recall
                          ? colors.veryLightOrange
                          : colors.vertLightPrimary
                      }
                      onClick={() => {
                        setForm({
                          ...form,
                          meta: a.Meta,
                        });
                        setSelectedAppointment(a.AppointmentId);
                      }}
                    >
                      <TimelineBlockItem
                        key={a.id}
                        first={i === appointments.length - 1}
                        number={appointments.length - i}
                        appointment={a}
                        selected={selectedAppointment === a.AppointmentId}
                        patientName={patient.User.Name}
                        updateAppointment={async (rawData, close) => {
                          const data = transformEditorData(rawData);
                          const response = await updatePatientAppointment(
                            ADMIN_NS,
                            data,
                            a.AppointmentId
                          );
                          if (response?.code === "SUCCESS")
                            await updateItem("patient", {
                              ...patient,
                              Appointments: patient.Appointments.map((app) => {
                                if (
                                  app.AppointmentId ===
                                  response.data.AppointmentId
                                ) {
                                  return {
                                    ...app,
                                    ...response.data,
                                  };
                                }
                                return app;
                              }),
                            })(dispatch);
                        }}
                        setAppointmentData={(appointment) => {
                          setShowPreview(true);
                          setAppointment(appointment.AppointmentId);
                        }}
                        setCreateInvoice={() => {
                          setComplete(true);
                          setAppointment(a.AppointmentId);
                        }}
                        setCancel={(appointmentId) => {
                          setCancel({
                            appointmentId,
                          });
                          setComplete(null);
                        }}
                        setUnCancel={(appointmentId) => {
                          setUnCancel({
                            appointmentId,
                          });
                          setComplete(null);
                        }}
                      />
                    </TimelineBlockItemWrapper>
                  </ListWrapper>
                ))}
          </Timline>
          {selectedAppointment && selectedAppointmentData && (
            <div
              style={{
                overflowX: "auto",
                height: "100%",
                width: "40rem",
                background: selectedAppointmentData?.Meta?.Recall
                  ? colors.veryLightOrange
                  : colors.vertLightPrimary,
              }}
            >
              <TimelineBlock
                user={user}
                appointmentDataProps={selectedAppointmentData}
                treatments={treatments}
                practitioners={practitioners}
                form={form}
                setForm={setForm}
                updateAppointment={async (rawData) => {
                  const data = transformEditorData(rawData);
                  const response = await updatePatientAppointment(
                    ADMIN_NS,
                    data,
                    selectedAppointment
                  );
                  if (response?.code === "SUCCESS")
                    await updateItem("patient", {
                      ...patient,
                      Appointments: patient.Appointments.map((app) => {
                        if (app.AppointmentId === response.data.AppointmentId) {
                          return {
                            ...app,
                            ...response.data,
                          };
                        }
                        return app;
                      }),
                    })(dispatch);
                }}
                disableInput={false}
              />
            </div>
          )}
        </div>
      </RecordContainer>
    </Container>
  );
};

export default ConsultationProgress;
