import React, { useContext } from 'react'
import { ListItem, List, ListItemText } from '@mui/material'
import colors from 'library/styled-components/colors'
import { AppContext } from 'hooks/context'

const StatementCharges = ({ invoice }) => {
  const { state } = useContext(AppContext)
  const practitioners = state.list
  return (
    <>
      <List
        dense
        sx={{
          borderBottom: '1px dotted #ccc',
        }}
      >
        <ListItem>
          <ListItemText
            primary={`Invoice Number: ${invoice?.Code}`}
            primaryTypographyProps={{
              style: { color: colors.blue, fontWeight: 'bold' }
            }}
          />
          <ListItemText
            primary={`Date: ${new Date(invoice?.createdAt).toLocaleDateString(
              'en-GB'
            )}`}
            primaryTypographyProps={{
              align: 'right',
              style: { color: colors.blue, fontWeight: 'bold' }
            }}
          />
        </ListItem>

        {[...(invoice?.Charges || []), ...(invoice?.Payments || [])]
          ?.sort((a, b) => {
            return new Date(a.updatedAt) - new Date(b.updatedAt)
          })
          ?.map(invItem => {
            if (invItem?.PaymentId) {
              return (
                <ListItem key={invItem.id}>
                  <ListItemText
                    primary={`Payment by ${invItem?.Method} - Practitioner ${
                      practitioners.data.find(
                        p => p.data.PractitionerId === invItem.PractitionerId
                      )?.data?.Name
                    } - ${new Date(invItem?.createdAt).toLocaleDateString(
                      'en-GB'
                    )}`}
                  />
                  <div style={{ display: 'flex', color: colors.green }}>
                    <ListItemText
                      primary={new Intl.NumberFormat('en-IE', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(invItem.Amount || 0)}
                    />
                  </div>
                </ListItem>
              )
            }
            return (
              <ListItem divider key={invItem.id}>
                <ListItemText
                  primary={invItem.Treatment?.Code}
                  secondary={`${invItem.Treatment?.Name} - Practitioner ${
                    practitioners.data.find(
                      p => p?.data?.PractitionerId === invItem?.PractitionerId
                    )?.data?.Name || ''
                  } - ${invItem?.Data?.compactSelection?.leftRight || ''}
                      ${invItem?.Data?.compactSelection?.upperLower || ''} ${
                    invItem?.Data?.compactSelection?.number || ''
                  }`}
                />
                <div style={{ display: 'flex', color: colors.red }}>
                  <ListItemText
                    primary={new Intl.NumberFormat('en-IE', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(invItem.Amount || 0)}
                    secondary={`QTY: ${invItem?.Data?.quantity || 1}`}
                  />
                </div>
              </ListItem>
            )
          })}
      </List>
    </>
  )
}

export default StatementCharges
