import React from 'react'
import { useMemo, useEffect, useState } from 'react'
import CalendarComponent from './CalendarComponent'
import PrintTest from 'components/PrintTest'
import { format } from 'date-fns'
import { useContext } from 'react'
import { AppContext } from 'hooks/context'
import styled from 'styled-components'
import {
  mapEvents,
  mapScheduleEvents,
  mapPublicHolidays
} from 'library/calendar'
import {
  createAppointment,
  updateAppointment,
  updateAppointmentEvent,
  createAppointmentEvent,
  updatePatientAppointment
} from 'actions/appointments'
import { ADMIN_NS } from 'config'
import { updateLegacyRow, updateList } from 'library/resources'
import localforage from 'localforage'
import { LinearProgress } from '@mui/material'
import { fetchAppointmentsForDate } from 'library/PaginatedLoader'

const SplitScreen = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.number || 0},
    ${props => 100 / props.number}%
  );
`

export default function SplitCalendarComponent () {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [splitScreen, setSplitScreen] = useState(null)
  const { list, appointments, schedules } = state
  const [displayPractitioners, setDisplayPractitioners] = useState([])
  const [printSplitSchedule, setPrintSplitSchedule] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [appointmentExtendedProps, setAppointmentExtendedProps] = useState(null)

  const allPractitioners = useMemo(
    () => list?.data?.filter(p => p.data.Profile === 'active'),
    [list]
  )

  const practitioners = useMemo(
    () =>
      displayPractitioners
        .map(({ id, index }) => {
          const practitioner = allPractitioners?.find(
            p => p.data.PractitionerId === id && p.data.Profile === 'active'
          )
          return practitioner ? { ...practitioner, index } : null
        })
        .filter(p => p != null),
    [allPractitioners, displayPractitioners]
  )
  const events = useMemo(() => {
    const calEvents = mapEvents(
      appointments,
      appointmentExtendedProps?.AppointmentId,
      schedules?.data
    )

    const schedEvents =
      mapScheduleEvents(schedules?.data?.filter(s => s.data.TypeId === 1)) || []
    const publicHolidays = mapPublicHolidays(practitioners)
    return [
      ...(calEvents || []),
      ...(schedEvents || []),
      ...(publicHolidays || [])
    ]
  }, [appointments, appointmentExtendedProps?.AppointmentId, schedules.data])

  useEffect(() => {
    setDisplayPractitioners(
      list?.data
        ?.filter(p => p.data.Profile === 'active')
        .map((p, index) => ({
          id: p.data.PractitionerId,
          index
        }))
        .slice(0, 2)
    )
  }, [list])

  async function editAppointmentData (data, AppointmentId) {
    if (AppointmentId) {
      const response = await updateAppointment(ADMIN_NS, data, AppointmentId)
      if (response && response.code === 'SUCCESS') {
        const data = appointments.data.map(a => {
          if (a.data.AppointmentId === response.data.AppointmentId) {
            return {
              ...response.data,
              Meta: { ...response.data.Meta, updating: true }
            }
          }
          return a.data
        })
        dispatch({
          type: 'LOAD',
          context: 'appointments',
          data
        })
      }
      return response
    } else {
      const response = await createAppointment(ADMIN_NS, data, AppointmentId)
      if (response && response.code === 'SUCCESS') {
        const data = [
          ...appointments.data.map(a => a.data),
          {
            ...response.data,
            Meta: { ...response.data.Meta, updating: true }
          }
        ]
        dispatch({
          type: 'LOAD',
          context: 'appointments',
          data
        })
      }
      return response
    }
  }

  async function editEventData (data, EventId) {
    if (EventId) {
      const response = await updateAppointmentEvent(ADMIN_NS, data, EventId)
      if (response?.code === 'SUCCESS') {
        const data = appointments.data.map(a => {
          if (a.data.EventId === response.data.EventId) {
            return {
              ...response.data,
              Meta: { ...response.data.Meta, updating: true }
            }
          }
          return a.data
        })
        dispatch({
          type: 'LOAD',
          context: 'appointments',
          data
        })
      }
      return response
    } else {
      const response = await createAppointmentEvent(ADMIN_NS, data, EventId)
      if (response?.code === 'SUCCESS') {
        const data = [
          ...appointments.data.map(a => a.data),
          {
            ...response.data,
            Meta: { ...response.data.Meta, updating: true }
          }
        ]
        dispatch({
          type: 'LOAD',
          context: 'appointments',
          data
        })
      }
      return response
    }
  }

  const handleDateSelection = async (date, range = 'day') => {
    setSelectedDate(date)
    setLoading(true)

    const { data } = await fetchAppointmentsForDate(date, range)
    if (data) {
      await updateList('appointments', () => data)(dispatch)
    }

    setLoading(false)
  }

  function toggleSplitScreen (splitScreen) {
    localforage.setItem('splitScreen', splitScreen)
    setSplitScreen(splitScreen)
  }

  async function updateAppointmentStatus (data, AppointmentId) {
    const response = await updatePatientAppointment(
      ADMIN_NS,
      data,
      AppointmentId
    )
    if (response?.code === 'SUCCESS')
      await updateLegacyRow(
        'appointments',
        appointments.data.map(app => {
          if (app.data.AppointmentId === response.data.AppointmentId) {
            return {
              ...app,
              ...response.data
            }
          }
          return app.data
        })
      )(dispatch)
    return response
  }

  async function renderSetting () {
    const selectedDate = await localforage.getItem('selectedDate')
    const newDate = selectedDate || new Date()

    if (!selectedDate) return
    handleDateSelection(newDate)
  }

  useEffect(() => {
    renderSetting()
  }, [])

  return (
    <>
      {loading ? (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: '1' }}
        />
      ) : null}
      <PrintTest
        open={printSplitSchedule}
        setOpen={setPrintSplitSchedule}
        date={format(selectedDate || new Date(), 'EEEE, dd/MM/yyyy')}
        selectedDate={selectedDate}
        schedules={practitioners?.map(practitioner => ({
          id: practitioner.data.id,
          name: practitioner.data.Name,
          events: events?.filter(
            e =>
              !e?.Cancelation &&
              !e?.ScheduleId &&
              e?.PractitionerId === practitioner.data.PractitionerId &&
              format(selectedDate, 'dd/MM/yyyy') ===
                format(new Date(e.start), 'dd/MM/yyyy')
          )
        }))}
      />
      {!splitScreen ? (
        <SplitScreen number={practitioners?.length}>
          {practitioners.map((practitioner, k) => (
            <CalendarComponent
              key={k}
              practitionerIndex={k}
              dispatch={dispatch}
              editAppointmentData={editAppointmentData}
              editEventData={editEventData}
              practitioner={practitioner}
              allPractitioners={allPractitioners}
              events={events.filter(
                e => e.PractitionerId === practitioner.data.PractitionerId
              )}
              schedules={schedules}
              selectedDate={selectedDate}
              toggleSplitScreen={toggleSplitScreen}
              storeSelectedDate={handleDateSelection}
              setPrintSplitSchedule={setPrintSplitSchedule}
              setAppointmentExtendedProps={setAppointmentExtendedProps}
              updateAppointmentStatus={updateAppointmentStatus}
              setDisplayPractitioners={setDisplayPractitioners}
              displayPractitioners={displayPractitioners}
            />
          ))}
        </SplitScreen>
      ) : (
        <CalendarComponent
          practitionerIndex={0}
          dispatch={dispatch}
          splitScreen={splitScreen}
          editAppointmentData={editAppointmentData}
          editEventData={editEventData}
          practitioner={practitioners.find(
            p => p.data.PractitionerId === splitScreen
          )}
          allPractitioners={allPractitioners}
          events={events}
          schedules={schedules}
          selectedDate={selectedDate}
          toggleSplitScreen={toggleSplitScreen}
          storeSelectedDate={handleDateSelection}
          setPrintSplitSchedule={setPrintSplitSchedule}
          setAppointmentExtendedProps={setAppointmentExtendedProps}
          updateAppointmentStatus={updateAppointmentStatus}
          setDisplayPractitioners={setDisplayPractitioners}
          displayPractitioners={displayPractitioners}
        />
      )}
    </>
  )
}
